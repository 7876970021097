import { ManualOperationNeededError } from '@core/errors';
import * as Sentry from '@sentry/nextjs';

const wrapWithError = (unknownError: unknown): Error => {
  if (unknownError instanceof Error) return unknownError;

  if (typeof unknownError === 'string') {
    return new Error(unknownError);
  }

  // We are logging unknownError for debugging purposes
  // eslint-disable-next-line no-console
  console.error('unknownError :>> ', unknownError);
  return new Error('An unexpected error has occured');
};

export const reportErrorToSentry = (
  unknownError: unknown,
  {
    contexts,
  }: {
    contexts?: {
      key: string;
      context: Record<string, unknown>;
    }[];
  } = {}
): void => {
  const error = wrapWithError(unknownError);
  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('handling unknown error: ', { error, contexts });
  }

  if (error instanceof ManualOperationNeededError) {
    contexts?.push({
      key: 'manualOperationGuide',
      context: { notionLink: error.howToFixLink },
    });
  }

  if (contexts) {
    Sentry.withScope((scope) => {
      contexts.forEach(({ key, context }) => {
        scope.setContext(key, context);
      });
    });
  }

  Sentry.captureException(error);
};

export const logMessage = (
  message: string,
  { severity }: { severity?: Sentry.SeverityLevel } = {}
): void => {
  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('message: ', message);
  }

  Sentry.captureMessage(message, severity);
};

export const logBreadcrumbs = (breadcrumb: Sentry.Breadcrumb): void => {
  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('sentry breadcrumbs: ', breadcrumb);
  }
  Sentry.addBreadcrumb(breadcrumb);
};
