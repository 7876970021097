/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
};

export type Awss3UploadUrlAndKey = {
  __typename?: 'AWSS3UploadUrlAndKey';
  awsS3Key: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type AbandonRecruitmentTaskInput = {
  recruitmentTaskId: Scalars['String']['input'];
};

export type AcademicBackground = {
  __typename?: 'AcademicBackground';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AcceptInvitationInput = {
  /** The id of the invitation */
  id: Scalars['ID']['input'];
};

export type AcceptJobApplicationForFirstInterviewInput = {
  jobApplicationId: Scalars['String']['input'];
  messageForApplicant?: InputMaybe<Scalars['String']['input']>;
};

export type ActivitySector = {
  __typename?: 'ActivitySector';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AddPotentialJobCategoryInput = {
  name: Scalars['String']['input'];
};

export type AddProfilePotentialSkillInput = {
  potentialSkillName: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type AddProfileToCvBankInput = {
  profileId: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Country>;
  id: Scalars['ID']['output'];
  locality: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
};

export type AddressInput = {
  countryId: Scalars['String']['input'];
  locality: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
};

export type AgreeToTermsOfServiceInput = {
  version: Scalars['Float']['input'];
};

export enum AlertStatus {
  Deleted = 'DELETED',
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type AnonymizedExperience = {
  __typename?: 'AnonymizedExperience';
  employer: ExperienceEmployer;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  skills?: Maybe<Array<Skill>>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnonymizedProfile = {
  __typename?: 'AnonymizedProfile';
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  cvBankDescription?: Maybe<CvBankProfileDescription>;
  educations: Array<Education>;
  experiences: Array<AnonymizedExperience>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preferredRemoteWorkPolicy?: Maybe<RemoteWorkPolicy>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  salaryClaimAmount?: Maybe<Scalars['Int']['output']>;
  salaryClaimCurrencyCode?: Maybe<CurrencyIso4217Code>;
  urbanArea?: Maybe<UrbanArea>;
  validSkills: Array<Skill>;
};

export type AnonymizedProfileOffsetBasedConnection = {
  __typename?: 'AnonymizedProfileOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<AnonymizedProfile>;
  totalCount: Scalars['Int']['output'];
};

export type AnonymizedProfilesFilters = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['String']['input']>>;
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  schoolIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skills?: InputMaybe<SkillsWithQueryModeInput>;
  urbanArea?: InputMaybe<UrbanAreaWithRadiusInput>;
};

export type AttributeCanonicalProfileToRecruiterInput = {
  linkedInVanityName: Scalars['String']['input'];
  recruiterId: Scalars['String']['input'];
};

export type BankDetails = {
  __typename?: 'BankDetails';
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type BankDetailsInput = {
  iban?: InputMaybe<Scalars['String']['input']>;
};

export type BookVideoBriefMeetingInput = {
  endDate: Scalars['DateTime']['input'];
  jobOfferId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
};

export enum BusinessType {
  Employer = 'EMPLOYER',
  SearchFirm = 'SEARCH_FIRM',
}

export type CvBankAlert = {
  __typename?: 'CVBankAlert';
  cvBankSearch: CvBankSearch;
  id: Scalars['ID']['output'];
  status: AlertStatus;
};

export type CvBankProfile = {
  __typename?: 'CVBankProfile';
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  educations: Array<Education>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  email?: Maybe<Scalars['String']['output']>;
  /** The profile's experiences list, in an descending order. Will always return an empty array if the viewer ran out of cv bank credit and the profile has not been discovered yet. */
  experiences: Array<Experience>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobCategories: Array<JobCategory>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  salaryClaimAmount?: Maybe<Scalars['Int']['output']>;
  salaryClaimCurrencyCode?: Maybe<CurrencyIso4217Code>;
  urbanArea?: Maybe<UrbanArea>;
};

export type CvBankProfileDescription = {
  __typename?: 'CVBankProfileDescription';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CvBankProfileDiscoverabilityPeriod = {
  __typename?: 'CVBankProfileDiscoverabilityPeriod';
  addedAt: Scalars['DateTime']['output'];
  removedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CvBankProfileOffsetBasedConnection = {
  __typename?: 'CVBankProfileOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<CvBankProfile>;
  totalCount: Scalars['Int']['output'];
};

export type CvBankProfilesFilters = {
  isAlreadyViewed?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenToWork?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, jobCategoryIds filter should correspond to a list of deprecatedJobCategoryIds */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  urbanAreaId?: InputMaybe<Scalars['String']['input']>;
};

export type CvBankSearch = {
  __typename?: 'CVBankSearch';
  id: Scalars['ID']['output'];
  isOpenToWork?: Maybe<Scalars['Boolean']['output']>;
  jobCategories: Array<JobCategory>;
  minNumberOfYearsOfExperience?: Maybe<Scalars['Float']['output']>;
  skills: Array<Skill>;
  urbanArea?: Maybe<UrbanArea>;
};

export type CvBankViewCountViewerPair = {
  __typename?: 'CVBankViewCountViewerPair';
  firstViewAt: Scalars['DateTime']['output'];
  lastViewAt: Scalars['DateTime']['output'];
  viewCount: Scalars['Int']['output'];
  viewer: User;
};

export type CvBankViewCountViewerPairOffsetBasedConnection = {
  __typename?: 'CVBankViewCountViewerPairOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<CvBankViewCountViewerPair>;
  totalCount: Scalars['Int']['output'];
};

export type CarbonCopyCouple = {
  __typename?: 'CarbonCopyCouple';
  carbonCopyUserId: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  topic: Scalars['String']['output'];
};

export enum CarbonCopyTopic {
  JobApplication = 'JOB_APPLICATION',
}

export type CareerData = {
  __typename?: 'CareerData';
  academicBackgrounds: Array<AcademicBackground>;
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  deprecatedJobCategories: Array<JobCategory>;
  /**
   * For now, this field returns the deprecatedJobCategories
   * @deprecated use deprecatedJobCategories and newJobCategories instead
   */
  jobCategories: Array<JobCategory>;
  /** This field returns the new jobCategories */
  newJobCategories: Array<JobCategory>;
  skills: Array<Skill>;
};

export type ChatChannel = {
  __typename?: 'ChatChannel';
  id: Scalars['ID']['output'];
  lastMessage?: Maybe<Message>;
  users: Array<User>;
};

export type ChatChannelOffsetBasedConnection = {
  __typename?: 'ChatChannelOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<ChatChannel>;
  totalCount: Scalars['Int']['output'];
};

export type ChatChannelsFilters = {
  searchedText?: InputMaybe<Scalars['String']['input']>;
};

export type CheckIfSearchFirmNameIsAvailableInput = {
  name: Scalars['String']['input'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  url: Scalars['String']['output'];
};

export type ClassificationJob = {
  __typename?: 'ClassificationJob';
  jobId: Scalars['ID']['output'];
  startedAt: Scalars['DateTime']['output'];
};

export type ClassifyAndCreateJobOfferInput = {
  /** For now, this needs to be used in combination with "fromText" parameters since the user can modify the original text provided by the jobOfferLead */
  fromJobOfferLead?: InputMaybe<Scalars['ID']['input']>;
  fromText: Scalars['String']['input'];
  rawTitle: Scalars['String']['input'];
};

export type CompanyLogoUploadUrlAndFileNameInput = {
  companyName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
};

export type CompleteJobOfferCreationFormInput = {
  jobOfferId: Scalars['String']['input'];
};

export type ComputeProfileJobApplicationRecommendationsInput = {
  profileId: Scalars['String']['input'];
};

export type ComputeProfileJobOfferPotentialMatchesInput = {
  profileId: Scalars['String']['input'];
};

export type Contract = {
  __typename?: 'Contract';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /**
   * The list of statuses of the contract.
   * The most recent status is the current status of the contract.
   *
   */
  statuses?: Maybe<Array<ContractStatus>>;
};

export type ContractStatus = {
  __typename?: 'ContractStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: ContractStatusName;
};

export enum ContractStatusName {
  PendingSignature = 'PENDING_SIGNATURE',
  Signed = 'SIGNED',
}

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CreateHireGaranteeCheckoutSessionInput = {
  garanteeDuration: HireGaranteeDuration;
  hiredApplicantFirstName: Scalars['String']['input'];
  hiredApplicantJobStartDate: Scalars['DateTime']['input'];
  hiredApplicantLastName: Scalars['String']['input'];
  /** This is the monthly price as mentioned on the front-end. It will be double-checked by the back-end */
  monthlyPriceInCents: Scalars['Int']['input'];
};

export type CreateInvitationInput = {
  email: Scalars['String']['input'];
};

export type CreateMyEmployerInput = {
  name: Scalars['String']['input'];
};

export type CreateProfileInput = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['String']['input']>>;
  approximateFirstJobStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeAwsS3Key?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeFilename?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName: Scalars['String']['input'];
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryIds. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName: Scalars['String']['input'];
  linkedInProfileUrl?: InputMaybe<Scalars['String']['input']>;
  /** For now, use this newJobCategoryIds input to create the profile's new jobCategories. Delete this field as soon as the input jobCategoryIds creates the new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  origin?: InputMaybe<ProfileCreationOrigin>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  potentialSkillNames?: InputMaybe<Array<Scalars['String']['input']>>;
  profilePictureFilename?: InputMaybe<Scalars['String']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateSearchFirmInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  legalForm?: InputMaybe<LegalForm>;
  name: Scalars['String']['input'];
  numberOfRecruiters?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateStripeCustomerCheckoutSessionInput = {
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  priceId: Scalars['ID']['input'];
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  firstName: Scalars['String']['input'];
  invitationId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  mainBusinessType: BusinessType;
  phoneNumber: Scalars['String']['input'];
};

export type CreateUserLeadInput = {
  mainBusinessType: BusinessType;
};

export enum CurrencyIso4217Code {
  Cad = 'CAD',
  Chf = 'CHF',
  Eur = 'EUR',
  Gbp = 'GBP',
  Mad = 'MAD',
  Tnd = 'TND',
  Usd = 'USD',
}

export type CurriculumVitaeAwss3UploadUrlAndKeyInput = {
  fileType: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type DeclareApplicantHiredByMyCompanyInput = {
  grossAnnualFixedRemunerationInCents?: InputMaybe<Scalars['Int']['input']>;
  grossAnnualVariableRemunerationInCents?: InputMaybe<Scalars['Int']['input']>;
  jobApplicationId: Scalars['ID']['input'];
  jobStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteCvBankAlertInput = {
  alertId: Scalars['ID']['input'];
};

export type DeprecatedJobCategoriesFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobCategoryStatus>>;
};

export type EditProfilePersonalNoteInput = {
  body: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type Education = {
  __typename?: 'Education';
  degreeTitle?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  school: School;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Employer = {
  __typename?: 'Employer';
  address?: Maybe<Address>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nonPoachingAgreementsDescription?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
};

export type EmployerJobOfferLeadsFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type EmployerJobOffersFilters = {
  isTerminated?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EntitiesQueryMode {
  Exhaustive = 'EXHAUSTIVE',
  NonExhaustive = 'NON_EXHAUSTIVE',
}

export type Experience = {
  __typename?: 'Experience';
  description?: Maybe<Scalars['String']['output']>;
  employer: ExperienceEmployer;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  skills?: Maybe<Array<Skill>>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** We decided not to use the same Employer entity, reasons are explained here https://www.notion.so/How-to-store-information-about-employer-in-Profile-experiences-de0ad43a0b60450782e94daedf4949eb?pvs=4 */
export type ExperienceEmployer = {
  __typename?: 'ExperienceEmployer';
  linkedInCompanyId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ExpressInterestInProfileInput = {
  jobOfferId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type ExpressInterestInProfilesInput = {
  jobOfferId: Scalars['String']['input'];
  profileIds: Array<Scalars['String']['input']>;
};

export type ExtensionConfig = {
  __typename?: 'ExtensionConfig';
  /** a JSON config containing selectors to extract profile informations on a LinkedIn page. See more on muzzo-extension */
  profileSelectors: Scalars['String']['output'];
};

export type ExtractJobOfferSkillsInput = {
  jobOfferId: Scalars['String']['input'];
};

export type ExtractJobOffersSkillsFilters = {
  ongoingOnly: Scalars['Boolean']['input'];
  withJobApplicationAcceptedForInterview?: InputMaybe<
    Scalars['Boolean']['input']
  >;
};

export type GenerateSearchFirmContractInput = {
  searchFirmLegalRepresentativeContact: SearchFirmLegalRepresentativeContactInput;
};

export type GetCareerDataFromLinkedInInput = {
  educations?: InputMaybe<Array<LinkedInEducationInput>>;
  experiences?: InputMaybe<Array<LinkedInExperienceInput>>;
  headline?: InputMaybe<Scalars['String']['input']>;
};

export type GetEmployersInput = {
  searchText: Scalars['String']['input'];
};

export type GetJobApplicationRecommendationInput = {
  jobApplicationRecommendationId: Scalars['String']['input'];
};

export type GetJobOfferNewsFeedInput = {
  jobOfferId: Scalars['String']['input'];
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type GetLastProfileJobApplicationRecommendationsComputationInput = {
  profileId: Scalars['String']['input'];
};

export type GetMessagesInput = {
  chatChannelId: Scalars['String']['input'];
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type GetMyChatChannelsInput = {
  filters?: InputMaybe<ChatChannelsFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type GetProfileAcademicBackgroundsInput = {
  profileId: Scalars['String']['input'];
};

export type GetProfileInput = {
  profileId: Scalars['String']['input'];
};

export type GetProfileJobApplicationRecommendationRankingInput = {
  profileId: Scalars['String']['input'];
};

export type GetProfileJobApplicationRecommendationsComputationInput = {
  computationId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type GetProfileJobOfferPotentialMatchesInput = {
  profileId: Scalars['String']['input'];
};

export type GetSchoolsInput = {
  searchedText: Scalars['String']['input'];
};

export type GetSkillsInput = {
  search: Scalars['String']['input'];
};

export type GetValidSkillsInput = {
  search: Scalars['String']['input'];
};

export type HasAlreadyAppliedToJobOfferInput = {
  jobOfferId: Scalars['String']['input'];
  profileIdentificationData: ProfileIdentificationDataInput;
};

export enum HireGaranteeDuration {
  SixMonths = 'SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
}

export type HiringManagerLikesAnonymizedProfile = {
  __typename?: 'HiringManagerLikesAnonymizedProfile';
  anonymizedProfile: AnonymizedProfile;
  createdAt: Scalars['DateTime']['output'];
  hiringManager: User;
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
};

export type InternalManager = {
  __typename?: 'InternalManager';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  myOneToOneChatChannelId?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  businessType: BusinessType;
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  email: Scalars['String']['output'];
  employer?: Maybe<Employer>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  searchFirm?: Maybe<SearchFirm>;
  status: InvitationStatus;
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
}

export type InvitationsFilters = {
  onlyPending?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobApplication = {
  __typename?: 'JobApplication';
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  profile: Profile;
  recruitmentTask: RecruitmentTask;
  statuses?: Maybe<Array<JobApplicationStatus>>;
};

export type JobApplicationOffsetBasedConnection = {
  __typename?: 'JobApplicationOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<JobApplication>;
  totalCount: Scalars['Int']['output'];
};

export type JobApplicationRecommendation = {
  __typename?: 'JobApplicationRecommendation';
  createdAt: Scalars['DateTime']['output'];
  hasApplied: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  jobOfferMatchingCriteria?: Maybe<Array<JobOfferMatchingCriterion>>;
  profile: Profile;
  recruiterFlatFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruiterSuccessFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruitmentTask?: Maybe<RecruitmentTask>;
  score?: Maybe<Scalars['Int']['output']>;
  status: JobApplicationRecommendationStatus;
};

export enum JobApplicationRecommendationStatus {
  Current = 'current',
  Obsolete = 'obsolete',
}

export type JobApplicationStatus = {
  __typename?: 'JobApplicationStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jobApplication: JobApplication;
  message?: Maybe<Message>;
  name: JobApplicationStatusName;
};

export enum JobApplicationStatusName {
  AcceptedForFirstInterview = 'ACCEPTED_FOR_FIRST_INTERVIEW',
  ExpiredBecauseJobOfferHasBeenClosed = 'EXPIRED_BECAUSE_JOB_OFFER_HAS_BEEN_CLOSED',
  Hired = 'HIRED',
  Rejected = 'REJECTED',
  SuggestedByRecruiter = 'SUGGESTED_BY_RECRUITER',
}

export type JobApplicationsFilters = {
  jobOfferId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobApplicationStatusName>>;
};

export type JobCategoriesFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobCategoryStatus>>;
};

export type JobCategory = {
  __typename?: 'JobCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status?: Maybe<JobCategoryStatus>;
};

export enum JobCategoryStatus {
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Valid = 'VALID',
}

export type JobOffer = {
  __typename?: 'JobOffer';
  academicBackgrounds?: Maybe<Array<AcademicBackground>>;
  briefingVideoUrl?: Maybe<Scalars['String']['output']>;
  careerOutlook?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<User>;
  currencyCode?: Maybe<CurrencyIso4217Code>;
  deprecatedJobCategory?: Maybe<JobCategory>;
  description: Scalars['String']['output'];
  employer?: Maybe<Employer>;
  flatFee?: Maybe<Scalars['Int']['output']>;
  hasHealthInsurancePlan?: Maybe<Scalars['Boolean']['output']>;
  hasIncentiveBonus?: Maybe<Scalars['Boolean']['output']>;
  hasLuncheonVouchers?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  internalManager?: Maybe<InternalManager>;
  isTeleworkingAuthorized?: Maybe<Scalars['Boolean']['output']>;
  isTerminated: Scalars['Boolean']['output'];
  /**
   * Fow now, this field corresponds to the jobOffer deprecatedJobCategory
   * @deprecated Use deprecatedJobCategory or newJobCategory instead. Update the website, wait a few days, then update this field to return the new jobCategory
   */
  jobCategory?: Maybe<JobCategory>;
  latestSuccessFee?: Maybe<SuccessFee>;
  likedAnonymizedProfiles: Array<HiringManagerLikesAnonymizedProfile>;
  location?: Maybe<Scalars['String']['output']>;
  mandatoryProfileAttributes?: Maybe<Scalars['String']['output']>;
  maxNumberOfYearsOfExperience?: Maybe<Scalars['Int']['output']>;
  maximumRemuneration?: Maybe<Scalars['Int']['output']>;
  maximumVariableRemuneration?: Maybe<Scalars['Int']['output']>;
  minNumberOfYearsOfExperience?: Maybe<Scalars['Int']['output']>;
  minimumRemuneration?: Maybe<Scalars['Int']['output']>;
  motivationalMessageToRecruiters?: Maybe<Scalars['String']['output']>;
  /** Fow now, use this field to get the jobOffer new jobCategory. Delete this field as soon as the field jobCategory returns the new jobCategory */
  newJobCategory?: Maybe<JobCategory>;
  /** @deprecated use numberOfCurrentlyAcceptedForFirstInterviewJobApplications instead */
  numberOfAcceptedForFirstInterviewJobApplications: Scalars['Int']['output'];
  numberOfCurrentlyAcceptedForFirstInterviewJobApplications: Scalars['Int']['output'];
  numberOfHiredJobApplications: Scalars['Int']['output'];
  numberOfJobApplications: Scalars['Int']['output'];
  numberOfNewJobApplications: Scalars['Int']['output'];
  numberOfOngoingRecruitmentTasks: Scalars['Int']['output'];
  numberOfPositionsToFill: Scalars['Int']['output'];
  numberOfRecruitmentTaskRecommendations: Scalars['Int']['output'];
  perksDescription?: Maybe<Scalars['String']['output']>;
  /** When set to null, there's no priority */
  priorityIndicator?: Maybe<JobOfferPriorityIndicator>;
  profileDescription?: Maybe<Scalars['String']['output']>;
  /** For admin staffing only. Get the potential last recruitment task recommendation of this job offer for a given recruiter */
  recruiterLatestRecruitmentTaskRecommendation?: Maybe<RecruitmentTaskRecommendation>;
  /** For admin staffing only. Get the potential last recruitment task of this job offer for a given recruiter */
  recruiterRecruitmentTask?: Maybe<RecruitmentTask>;
  recruitmentAdvice?: Maybe<Scalars['String']['output']>;
  recruitmentProcess?: Maybe<Scalars['String']['output']>;
  remoteWorkPolicy?: Maybe<RemoteWorkPolicy>;
  replacedJobApplication?: Maybe<JobApplication>;
  skills: Array<Skill>;
  status: JobOfferStatus;
  technicalSkillsDescription?: Maybe<Scalars['String']['output']>;
  /** Defined when a job offer is terminated, it's a string for now */
  terminationReason?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalNumberOfAcceptedForFirstInterviewJobApplications: Scalars['Int']['output'];
  typicalProfileUrl?: Maybe<Scalars['String']['output']>;
  videoAskUrl?: Maybe<Scalars['String']['output']>;
  /** Defined when a hiring manager self booked a video brief meeting */
  videoBriefMeeting?: Maybe<VideoBriefMeeting>;
};

export type JobOfferRecruiterLatestRecruitmentTaskRecommendationArgs = {
  recruiterId: Scalars['String']['input'];
};

export type JobOfferRecruiterRecruitmentTaskArgs = {
  recruiterId: Scalars['String']['input'];
};

export type JobOfferLead = {
  __typename?: 'JobOfferLead';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobBoard: Scalars['String']['output'];
  jobBoardUrl: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  publicatedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type JobOfferLeadOffsetBasedConnection = {
  __typename?: 'JobOfferLeadOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<JobOfferLead>;
  totalCount: Scalars['Int']['output'];
};

export type JobOfferMatchingCriterion = {
  __typename?: 'JobOfferMatchingCriterion';
  isMatching: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type JobOfferOffsetBasedConnection = {
  __typename?: 'JobOfferOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<JobOffer>;
  totalCount: Scalars['Int']['output'];
};

export enum JobOfferPriorityIndicator {
  NoOngoingJobApplication = 'NO_ONGOING_JOB_APPLICATION',
}

export type JobOfferStaffingCopyStrategySelectedRecruitersInput = {
  jobOfferId: Scalars['ID']['input'];
  jobOfferToCopyStaffingFromId: Scalars['ID']['input'];
};

export type JobOfferStaffingExploratoryStrategySelectedRecruitersInput = {
  jobOfferId: Scalars['ID']['input'];
};

export type JobOfferStaffingRecruiterReport = {
  __typename?: 'JobOfferStaffingRecruiterReport';
  error?: Maybe<Scalars['String']['output']>;
  recruiterEmail: Scalars['String']['output'];
  recruiterId: Scalars['String']['output'];
};

export enum JobOfferStaffingRecruiterTier {
  CopiedFromAnotherJobOffer = 'COPIED_FROM_ANOTHER_JOB_OFFER',
  JobCategoryBest = 'JOB_CATEGORY_BEST',
  JobCategoryExplorer = 'JOB_CATEGORY_EXPLORER',
  JobCategoryNewcomer = 'JOB_CATEGORY_NEWCOMER',
  RelevantProfileOwner = 'RELEVANT_PROFILE_OWNER',
}

export type JobOfferStaffingStandardStrategySelectedRecruitersInput = {
  jobOfferId: Scalars['ID']['input'];
};

export enum JobOfferStatus {
  CreationFormCompleted = 'CREATION_FORM_COMPLETED',
  Draft = 'DRAFT',
  HuntStarted = 'HUNT_STARTED',
  StaffingStarted = 'STAFFING_STARTED',
  VideoBriefPublished = 'VIDEO_BRIEF_PUBLISHED',
}

export type JobOffersFilters = {
  isTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  searchedText?: InputMaybe<Scalars['String']['input']>;
};

export enum LegalForm {
  IndividualEntrepreneur = 'INDIVIDUAL_ENTREPRENEUR',
}

export type LinkedInEducationInput = {
  degree?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  school: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LinkedInExperienceInput = {
  companyName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  jobTitle: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MarkInvitationAsOpenedInput = {
  /** The email of the invitee */
  email: Scalars['String']['input'];
  /** The id of the invitation */
  id: Scalars['ID']['input'];
};

export type MarkMessagesAsReadInput = {
  lastSeenMessageId: Scalars['String']['input'];
};

export type MarkProfileAsNotOpenToWorkInput = {
  profileId: Scalars['ID']['input'];
};

export type MarkProfileAsOpenToWorkInput = {
  profileId: Scalars['ID']['input'];
};

export type Message = {
  __typename?: 'Message';
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  files: Array<StoredFile>;
  id: Scalars['ID']['output'];
  jobApplicationStatus?: Maybe<JobApplicationStatus>;
  myReadStatus: MessageReadStatus;
};

export type MessageFileInput = {
  /** The unique file name as it is stored in the dsitributed storage */
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  originalFileName: Scalars['String']['input'];
};

export type MessageFileUploadUrlAndFileNameInput = {
  fileType: Scalars['String']['input'];
  originalFileName?: InputMaybe<Scalars['String']['input']>;
};

export type MessageOffsetBasedConnection = {
  __typename?: 'MessageOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<Message>;
  totalCount: Scalars['Int']['output'];
};

export enum MessageReadStatus {
  NotRead = 'NOT_READ',
  Read = 'READ',
}

export type Mutation = {
  __typename?: 'Mutation';
  abandonRecruitmentTask: RecruitmentTask;
  acceptInvitation: User;
  acceptJobApplicationForFirstInterview: JobApplication;
  acceptRecruitmentTaskRecommendation: RecruitmentTaskRecommendation;
  addPotentialJobCategory: JobCategory;
  addPotentialNewJobCategory: JobCategory;
  addProfilePotentialSkill: Profile;
  addProfileToCVBank: Profile;
  agreeToTermsOfService: User;
  attributeCanonicalProfileToRecruiter: Scalars['Boolean']['output'];
  bookVideoBriefMeeting: VideoBriefMeeting;
  /** Tries to classify a job offer from title and text. It creates the job offer even if the classification does not succeed */
  classifyAndCreateJobOffer: ClassificationJob;
  completeJobOfferCreationForm: JobOffer;
  computeProfileJobApplicationRecommendations: ProfileJobApplicationRecommendationsComputation;
  computeProfileJobOfferMatchingAlgorithmAccuracy: ProfileJobOfferMatchingAlgorithm;
  computeProfileJobOfferPotentialMatches: Scalars['String']['output'];
  createCollectPaymentDetailsCheckoutSession: CheckoutSession;
  createHireGaranteeCheckoutSession: CheckoutSession;
  createInvitation: Invitation;
  createMyEmployer: User;
  createProfile: Profile;
  createSearchFirm: SearchFirm;
  createStripeCustomerCheckoutSession: StripeCustomerCheckoutSession;
  createStripeCustomerPortalSession: StripeCustomerPortalSession;
  createUser: User;
  createUserLead: UserLead;
  declareApplicantHiredByMyCompany: JobApplication;
  deleteCVBankAlert: CvBankAlert;
  editProfilePersonalNote: Profile;
  /** Use expressInterestInProfiles for batched version of this mutation */
  expressInterestInProfile: JobOffer;
  expressInterestInProfiles: JobOffer;
  extractJobOfferSkills: JobOffer;
  extractJobOffersSkills: Scalars['Boolean']['output'];
  generateSearchFirmContract: Contract;
  markInvitationAsOpened: PublicInvitation;
  markMessagesAsRead: Message;
  markProfileAsNotOpenToWork: Profile;
  markProfileAsOpenToWork: Profile;
  openChatChannel: ChatChannel;
  postJobOfferNews: News;
  postMessage: Message;
  rateRecruitmentTask: RecruitmentTaskRating;
  rejectJobApplication: JobApplication;
  rejectRecruitmentTaskRecommendation: RecruitmentTaskRecommendation;
  removeProfileFromCVBank: Profile;
  reportJobApplicationAsRejected: JobApplication;
  requestFirstAppointment: Scalars['Boolean']['output'];
  requestRecruitmentProcessOutsourcingQuotation: Scalars['Boolean']['output'];
  sendEmailManually: Scalars['Boolean']['output'];
  sendOneToOneMessageToJobOfferRecruiters: Scalars['Boolean']['output'];
  setUpCVBankAlert: CvBankAlert;
  /** Staff a job offer with several recruiters. It returns the id of the recruiters that were already staffed on the given jobOffer */
  staffJobOffer: Array<JobOfferStaffingRecruiterReport>;
  /** Staff a recruiter on several job offers. It returns the title of the job offers that were already staffed for the given recruiter */
  staffRecruiter: Array<RecruiterStaffingJobOfferReport>;
  submitJobApplication: JobApplication;
  takeRecruitmentTaskViaJobApplicationRecommendation: RecruitmentTask;
  terminateJobOffer: JobOffer;
  updateCVBankProfileDescription: Profile;
  /** @deprecated This mutation was renamed updateRecruiterCarbonCopyCouples */
  updateCarbonCopyCouples: Scalars['Boolean']['output'];
  updateEmployer: Employer;
  updateEmployerLogo: Employer;
  updateJobOffer: JobOffer;
  updateMyEmployerLogo: Employer;
  updateMyProfilePicture: User;
  updateProfile: Profile;
  updateProfileAcademicBackgrounds: Profile;
  updateProfileJobCategories: Profile;
  updateProfileSkills: Profile;
  updateRecruiterActivitySectorSpecializations: Scalars['Boolean']['output'];
  updateRecruiterCarbonCopyCouples: Scalars['Boolean']['output'];
  updateRecruiterDeprecatedJobCategoryAssociations: Scalars['Boolean']['output'];
  /**
   * For now, this mutation update the recruiter's deprecatedJobCategories.
   * @deprecated Use updateRecruiterDeprecatedJobCategoryAssociations instead. Update the website, wait a few days, then update this mutation to update the new jobCategories
   */
  updateRecruiterJobCategoryAssociations: Scalars['Boolean']['output'];
  /** For now, use this mutation to update the recruiter's new jobCategories. Delete this field as soon as the mutation updateRecruiterJobCategoryAssociations updates the new jobCategories */
  updateRecruiterNewJobCategoryAssociations: Scalars['Boolean']['output'];
  updateSearchFirm: SearchFirm;
  updateUser: User;
  upsertCanonicalProfile: Scalars['Boolean']['output'];
  upsertJobOfferLeads: Array<JobOfferLead>;
  viewCVBankProfile: ViewCvBankProfileResponse;
  visitLinkedInProfile: Scalars['Boolean']['output'];
};

export type MutationAbandonRecruitmentTaskArgs = {
  input: AbandonRecruitmentTaskInput;
};

export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};

export type MutationAcceptJobApplicationForFirstInterviewArgs = {
  input: AcceptJobApplicationForFirstInterviewInput;
};

export type MutationAcceptRecruitmentTaskRecommendationArgs = {
  recommendationId: Scalars['String']['input'];
};

export type MutationAddPotentialJobCategoryArgs = {
  input: AddPotentialJobCategoryInput;
};

export type MutationAddPotentialNewJobCategoryArgs = {
  input: AddPotentialJobCategoryInput;
};

export type MutationAddProfilePotentialSkillArgs = {
  input: AddProfilePotentialSkillInput;
};

export type MutationAddProfileToCvBankArgs = {
  input: AddProfileToCvBankInput;
};

export type MutationAgreeToTermsOfServiceArgs = {
  input: AgreeToTermsOfServiceInput;
};

export type MutationAttributeCanonicalProfileToRecruiterArgs = {
  input: AttributeCanonicalProfileToRecruiterInput;
};

export type MutationBookVideoBriefMeetingArgs = {
  input: BookVideoBriefMeetingInput;
};

export type MutationClassifyAndCreateJobOfferArgs = {
  input: ClassifyAndCreateJobOfferInput;
};

export type MutationCompleteJobOfferCreationFormArgs = {
  input: CompleteJobOfferCreationFormInput;
};

export type MutationComputeProfileJobApplicationRecommendationsArgs = {
  input: ComputeProfileJobApplicationRecommendationsInput;
};

export type MutationComputeProfileJobOfferPotentialMatchesArgs = {
  input: ComputeProfileJobOfferPotentialMatchesInput;
};

export type MutationCreateHireGaranteeCheckoutSessionArgs = {
  input: CreateHireGaranteeCheckoutSessionInput;
};

export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};

export type MutationCreateMyEmployerArgs = {
  input: CreateMyEmployerInput;
};

export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};

export type MutationCreateSearchFirmArgs = {
  input: CreateSearchFirmInput;
};

export type MutationCreateStripeCustomerCheckoutSessionArgs = {
  input: CreateStripeCustomerCheckoutSessionInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserLeadArgs = {
  input: CreateUserLeadInput;
};

export type MutationDeclareApplicantHiredByMyCompanyArgs = {
  input?: InputMaybe<DeclareApplicantHiredByMyCompanyInput>;
};

export type MutationDeleteCvBankAlertArgs = {
  input: DeleteCvBankAlertInput;
};

export type MutationEditProfilePersonalNoteArgs = {
  input: EditProfilePersonalNoteInput;
};

export type MutationExpressInterestInProfileArgs = {
  input: ExpressInterestInProfileInput;
};

export type MutationExpressInterestInProfilesArgs = {
  input: ExpressInterestInProfilesInput;
};

export type MutationExtractJobOfferSkillsArgs = {
  input: ExtractJobOfferSkillsInput;
};

export type MutationExtractJobOffersSkillsArgs = {
  filters?: InputMaybe<ExtractJobOffersSkillsFilters>;
};

export type MutationGenerateSearchFirmContractArgs = {
  input: GenerateSearchFirmContractInput;
};

export type MutationMarkInvitationAsOpenedArgs = {
  input: MarkInvitationAsOpenedInput;
};

export type MutationMarkMessagesAsReadArgs = {
  input: MarkMessagesAsReadInput;
};

export type MutationMarkProfileAsNotOpenToWorkArgs = {
  input: MarkProfileAsNotOpenToWorkInput;
};

export type MutationMarkProfileAsOpenToWorkArgs = {
  input: MarkProfileAsOpenToWorkInput;
};

export type MutationOpenChatChannelArgs = {
  input: OpenChatChannelInput;
};

export type MutationPostJobOfferNewsArgs = {
  input: PostJobOfferNewsInput;
};

export type MutationPostMessageArgs = {
  input: PostMessageInput;
};

export type MutationRateRecruitmentTaskArgs = {
  input: RateRecruitmentTaskInput;
};

export type MutationRejectJobApplicationArgs = {
  input: RejectJobApplicationInput;
};

export type MutationRejectRecruitmentTaskRecommendationArgs = {
  input: RejectRecruitementTaskRecommendationInput;
};

export type MutationRemoveProfileFromCvBankArgs = {
  input: RemoveProfileFromCvBankInput;
};

export type MutationReportJobApplicationAsRejectedArgs = {
  input: ReportJobApplicationAsRejectedInput;
};

export type MutationRequestFirstAppointmentArgs = {
  input: RequestFirstAppointmentInput;
};

export type MutationRequestRecruitmentProcessOutsourcingQuotationArgs = {
  input: RequestRecruitmentProcessOutsourcingQuotationInput;
};

export type MutationSendOneToOneMessageToJobOfferRecruitersArgs = {
  input: SendOneToOneMessageToJobOfferRecruitersInput;
};

export type MutationSetUpCvBankAlertArgs = {
  input: SetUpCvBankAlertInput;
};

export type MutationStaffJobOfferArgs = {
  input: StaffJobOfferInput;
};

export type MutationStaffRecruiterArgs = {
  input: StaffRecruiterInput;
};

export type MutationSubmitJobApplicationArgs = {
  input: SubmitJobApplicationInput;
};

export type MutationTakeRecruitmentTaskViaJobApplicationRecommendationArgs = {
  input: TakeRecruitmentTaskViaJobApplicationRecommendationInput;
};

export type MutationTerminateJobOfferArgs = {
  input: TerminateJobOfferInput;
};

export type MutationUpdateCvBankProfileDescriptionArgs = {
  input: UpdateCvBankProfileDescriptionInput;
};

export type MutationUpdateCarbonCopyCouplesArgs = {
  input: UpdateCarbonCopyCouplesInput;
};

export type MutationUpdateEmployerArgs = {
  input: UpdateEmployerInput;
};

export type MutationUpdateEmployerLogoArgs = {
  input: UpdateEmployerLogoInput;
};

export type MutationUpdateJobOfferArgs = {
  input: UpdateJobOfferInput;
};

export type MutationUpdateMyEmployerLogoArgs = {
  input: UpdateMyEmployerLogoInput;
};

export type MutationUpdateMyProfilePictureArgs = {
  input: UpdateMyProfilePictureInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateProfileAcademicBackgroundsArgs = {
  input: UpdateProfileAcademicBackgroundsInput;
};

export type MutationUpdateProfileJobCategoriesArgs = {
  input: UpdateProfileJobCategoriesInput;
};

export type MutationUpdateProfileSkillsArgs = {
  input: UpdateProfileSkillsInput;
};

export type MutationUpdateRecruiterActivitySectorSpecializationsArgs = {
  input: UpdateRecruiterActivitySectorSpecializationsInput;
};

export type MutationUpdateRecruiterCarbonCopyCouplesArgs = {
  input: UpdateCarbonCopyCouplesInput;
};

export type MutationUpdateRecruiterDeprecatedJobCategoryAssociationsArgs = {
  recruiterDeprecatedJobCategoryAssociationInputs: Array<RecruiterDeprecatedJobCategoryAssociationInput>;
};

export type MutationUpdateRecruiterJobCategoryAssociationsArgs = {
  recruiterJobCategoryAssociationInputs: Array<RecruiterJobCategoryAssociationInput>;
};

export type MutationUpdateRecruiterNewJobCategoryAssociationsArgs = {
  recruiterJobCategoryAssociationInputs: Array<RecruiterJobCategoryAssociationInput>;
};

export type MutationUpdateSearchFirmArgs = {
  input: UpdateSearchFirmInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpsertCanonicalProfileArgs = {
  input: UpsertCanonicalProfileInput;
};

export type MutationUpsertJobOfferLeadsArgs = {
  input: Array<UpsertJobOfferLeadsInput>;
};

export type MutationViewCvBankProfileArgs = {
  input: ViewCvBankProfileInput;
};

export type MutationVisitLinkedInProfileArgs = {
  input: VisitLinkedInProfileInput;
};

export type MyUser = User | UserLead;

export type NewJobCategoriesFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobCategoryStatus>>;
};

export type News = {
  __typename?: 'News';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  id: Scalars['ID']['output'];
};

export type NewsOffsetBasedConnection = {
  __typename?: 'NewsOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<News>;
  totalCount: Scalars['Int']['output'];
};

export type OffsetBasedPaginationParams = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type OpenChatChannelInput = {
  interlocutorIds: Array<Scalars['String']['input']>;
};

export type PaginatedJobOfferSuggestedAnonymizedProfilesInput = {
  jobOfferId: Scalars['String']['input'];
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type PaginatedJobOffersInput = {
  filters?: InputMaybe<JobOffersFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type PaginatedRecruitersInput = {
  filters?: InputMaybe<RecruitersFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export enum PaymentServiceProviderName {
  Stripe = 'Stripe',
}

export type Permission = {
  __typename?: 'Permission';
  name: PermissionName;
};

export enum PermissionName {
  AddProfileToCvBank = 'ADD_PROFILE_TO_CV_BANK',
  CanUseMatching = 'CAN_USE_MATCHING',
  ReadHiringManagerPhoneNumber = 'READ_HIRING_MANAGER_PHONE_NUMBER',
}

export type PostJobOfferNewsInput = {
  body: Scalars['String']['input'];
  jobOfferId: Scalars['String']['input'];
};

export type PostMessageInput = {
  /** If <body> is not provided, then the <files> field is required or an error will be thrown */
  body?: InputMaybe<Scalars['String']['input']>;
  chatChannelId: Scalars['String']['input'];
  /** If <files> is not provided, then the <body> field is required or an error will be thrown */
  files?: InputMaybe<Array<MessageFileInput>>;
};

export type Profile = {
  __typename?: 'Profile';
  academicBackgrounds: Array<AcademicBackground>;
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<User>;
  currentEmployer?: Maybe<Employer>;
  curriculumVitaeUrl?: Maybe<Scalars['String']['output']>;
  cvBankDescription?: Maybe<CvBankProfileDescription>;
  cvBankDiscoverabilityPeriods: Array<CvBankProfileDiscoverabilityPeriod>;
  educations: Array<Education>;
  email?: Maybe<Scalars['String']['output']>;
  experiences: Array<Experience>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDiscoverableInCVBank: Scalars['Boolean']['output'];
  /** @deprecated For now, this field returns deprecatedJobCategories. Use newJobCategories field instead */
  jobCategories: Array<JobCategory>;
  lastJobApplicationRecommendationsComputation?: Maybe<ProfileJobApplicationRecommendationsComputation>;
  lastName: Scalars['String']['output'];
  linkedInProfileUrl?: Maybe<Scalars['String']['output']>;
  myNote?: Maybe<ProfileNote>;
  newJobCategories: Array<JobCategory>;
  openToWorkUntil?: Maybe<Scalars['DateTime']['output']>;
  origin: ProfileCreationOrigin;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferredRemoteWorkPolicy?: Maybe<RemoteWorkPolicy>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  salaryClaimAmount?: Maybe<Scalars['Int']['output']>;
  salaryClaimCurrencyCode?: Maybe<CurrencyIso4217Code>;
  skills: Array<Skill>;
  updatedAt: Scalars['DateTime']['output'];
  urbanArea?: Maybe<UrbanArea>;
  validSkills: Array<Skill>;
};

export type ProfileAcademicBackground = {
  __typename?: 'ProfileAcademicBackground';
  academicBackground: AcademicBackground;
  id: Scalars['ID']['output'];
  profile: Profile;
};

export type ProfileCvBankAddibility = {
  __typename?: 'ProfileCVBankAddibility';
  canBeAdded: Scalars['Boolean']['output'];
  reason?: Maybe<ProfileCvBankNonAddibilityReason>;
};

export enum ProfileCvBankNonAddibilityReason {
  AlreadyIn = 'ALREADY_IN',
  NotEnoughInformation = 'NOT_ENOUGH_INFORMATION',
  OwnershipAlreadyTaken = 'OWNERSHIP_ALREADY_TAKEN',
}

export type ProfileCvBankViewCountViewerPairsInput = {
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
  profileId: Scalars['String']['input'];
};

export enum ProfileCreationOrigin {
  CopiedFromCanonicalProfile = 'COPIED_FROM_CANONICAL_PROFILE',
  LinkedinWebExtension = 'LINKEDIN_WEB_EXTENSION',
  Website = 'WEBSITE',
}

export type ProfileEducationInput = {
  degree?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * The ID associated to the school.
   *     Eg: For this URL "https://www.linkedin.com/company/2481168/", LinkedIn will redirect us to "https://www.linkedin.com/company/netatmo/"
   *     what we want to store is "2481168" (it's the simplest data that can be fetched on the front-end to identify a company)
   *
   */
  linkedInSchoolId?: InputMaybe<Scalars['String']['input']>;
  school: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProfileExperienceInput = {
  companyName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  jobTitle: Scalars['String']['input'];
  /**
   * The ID associated to the company.
   *     Eg: For this URL "https://www.linkedin.com/company/2481168/", LinkedIn will redirect us to "https://www.linkedin.com/company/netatmo/"
   *     what we want to store is "2481168" (it's the simplest data that can be fetched on the front-end to identify a company)
   *
   */
  linkedInCompanyId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProfileIdentificationDataInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileJobApplicationRecommendationsComputation = {
  __typename?: 'ProfileJobApplicationRecommendationsComputation';
  id: Scalars['ID']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: ProfileJobApplicationRecommendationsComputationStatus;
};

export enum ProfileJobApplicationRecommendationsComputationStatus {
  Failed = 'failed',
  Ongoing = 'ongoing',
  Successful = 'successful',
}

export type ProfileJobOfferMatchingAlgorithm = {
  __typename?: 'ProfileJobOfferMatchingAlgorithm';
  createdAt: Scalars['DateTime']['output'];
  datasetSize?: Maybe<Scalars['Int']['output']>;
  falseNegatives?: Maybe<Array<Scalars['String']['output']>>;
  falsePositives?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
};

export type ProfileJobOfferPotentialMatch = {
  __typename?: 'ProfileJobOfferPotentialMatch';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isScoreAccurate?: Maybe<Scalars['Boolean']['output']>;
  jobOffer: JobOffer;
  profile: Profile;
  score: Scalars['Int']['output'];
  shouldMatch?: Maybe<Scalars['Boolean']['output']>;
};

export type ProfileNote = {
  __typename?: 'ProfileNote';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProfileOffsetBasedConnection = {
  __typename?: 'ProfileOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<Profile>;
  totalCount: Scalars['Int']['output'];
};

export type ProfilePictureUploadUrlAndFileNameInput = {
  fileType: Scalars['String']['input'];
};

export type ProfileProfilePictureUploadUrlAndFileNameInput = {
  fileType: Scalars['String']['input'];
};

export type PublicInvitation = {
  __typename?: 'PublicInvitation';
  businessType: BusinessType;
  id: Scalars['ID']['output'];
  organizationName: Scalars['String']['output'];
  status: InvitationStatus;
};

export type PublicInvitationInput = {
  /** The email of the invitee */
  email: Scalars['String']['input'];
  /** The id of the invitation */
  id: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  academicBackgrounds: Array<AcademicBackground>;
  activitySectors: Array<ActivitySector>;
  anonymizedProfile: AnonymizedProfile;
  chatChannel: ChatChannel;
  checkIfSearchFirmNameIsAvailable: Scalars['Boolean']['output'];
  companyLogoUploadUrlAndFileName: Awss3UploadUrlAndKey;
  countries: Array<Country>;
  coworkers: Array<User>;
  curriculumVitaeAWSS3UploadUrlAndKey: Awss3UploadUrlAndKey;
  cvBankProfiles: CvBankProfileOffsetBasedConnection;
  /** @deprecated Use jobCategories instead */
  deprecatedJobCategories: Array<JobCategory>;
  employerJobOfferLead: JobOfferLead;
  /** @deprecated Prefer the employerPaginatedJobOffers for performance */
  employerJobOffers: Array<JobOffer>;
  employerPaginatedJobOfferLeads: JobOfferLeadOffsetBasedConnection;
  employerPaginatedJobOffers: JobOfferOffsetBasedConnection;
  employers: Array<Employer>;
  extensionConfig: ExtensionConfig;
  hasAlreadyAppliedToJobOffer: Scalars['Boolean']['output'];
  /** Get all invitations of the current user's organization */
  invitations: Array<Invitation>;
  jobApplication: JobApplication;
  jobApplicationRecommendation: JobApplicationRecommendation;
  jobCategories: Array<JobCategory>;
  jobOffer: JobOffer;
  jobOfferApplications: Array<JobApplication>;
  jobOfferNewsFeed: NewsOffsetBasedConnection;
  jobOfferRecruitmentTaskRecommendations: Array<RecruitmentTaskRecommendation>;
  jobOfferRecruitmentTasks: Array<RecruitmentTask>;
  /** Get a list of explorer recruiters that have had only a few interactions with Muzzo so far */
  jobOfferStaffingCopyStrategySelectedRecruiters: Array<User>;
  /** Get a list of explorer recruiters that have had only a few interactions with Muzzo so far */
  jobOfferStaffingExploratoryStrategySelectedRecruiters: Array<User>;
  /** Get a list of recruiters from all tiers for this job offer */
  jobOfferStaffingStandardStrategySelectedRecruiters: Array<User>;
  /** @deprecated use profile.lastJobApplicationRecommendationsComputation instead */
  lastProfileJobApplicationRecommendationsComputation?: Maybe<ProfileJobApplicationRecommendationsComputation>;
  liveness: Scalars['String']['output'];
  /** @deprecated This query will be deleted after the onboarding EPIC: use myUser query instead */
  me?: Maybe<User>;
  messageFileUploadUrlAndFileName: Awss3UploadUrlAndKey;
  messages: MessageOffsetBasedConnection;
  myCVBankAlerts: Array<CvBankAlert>;
  myChatChannels: ChatChannelOffsetBasedConnection;
  myEmployerMembers: Array<User>;
  myUser?: Maybe<MyUser>;
  /** @deprecated Use jobCategories instead */
  newJobCategories: Array<JobCategory>;
  paginatedAnonymizedProfiles: AnonymizedProfileOffsetBasedConnection;
  paginatedJobOfferSuggestedAnonymizedProfiles: AnonymizedProfileOffsetBasedConnection;
  paginatedJobOffers: JobOfferOffsetBasedConnection;
  paginatedRecruiterProfiles: ProfileOffsetBasedConnection;
  paginatedRecruiters: RecruiterOffsetBasedConnection;
  profile: Profile;
  /** @deprecated Use Profile.academicBackgrounds instead */
  profileAcademicBackgrounds: Array<ProfileAcademicBackground>;
  profileCVBankAddibility: ProfileCvBankAddibility;
  profileCVBankViewCountViewerPairs: CvBankViewCountViewerPairOffsetBasedConnection;
  profileCareerDataFromLinkedIn: CareerData;
  profileJobApplicationRecommendationRanking: Array<JobApplicationRecommendation>;
  profileJobApplicationRecommendationsComputation: ProfileJobApplicationRecommendationsComputation;
  profileJobOfferPotentialMatches: Array<ProfileJobOfferPotentialMatch>;
  profilePictureUploadUrlAndFileName: Awss3UploadUrlAndKey;
  profileProfilePictureUploadUrlAndFileName: Awss3UploadUrlAndKey;
  /** Get a public invitation for account creation purpose. */
  publicInvitation: PublicInvitation;
  recruiter: User;
  recruiterJobApplications: JobApplicationOffsetBasedConnection;
  /** @deprecated Use "paginatedRecruiterProfiles" instead */
  recruiterProfiles: Array<Profile>;
  recruiterRecruitmentTaskByJobOfferId: RecruitmentTask;
  recruiterRecruitmentTaskRecommendations: Array<RecruitmentTaskRecommendation>;
  recruiterRecruitmentTaskRecommendationsCount: Scalars['Int']['output'];
  recruiterRecruitmentTasks: RecruitmentTaskOffsetBasedConnection;
  recruitersStaffedOnJobOffer: Array<User>;
  recruitmentTask: RecruitmentTask;
  recruitmentTaskRecommendation: RecruitmentTaskRecommendation;
  recruitmentTasksOfRecruiterForMyEmployer: Array<RecruitmentTask>;
  schools: Array<School>;
  skills: Array<Skill>;
  supportChatUserIdentifier: Scalars['String']['output'];
  termsOfService?: Maybe<TermsOfService>;
  unreadMessagesCount: Scalars['Int']['output'];
  urbanAreas: Array<UrbanArea>;
  validSkills: Array<Skill>;
  videoBriefAwsS3UploadUrlAndKey: Awss3UploadUrlAndKey;
};

export type QueryAnonymizedProfileArgs = {
  input: GetProfileInput;
};

export type QueryChatChannelArgs = {
  id: Scalars['String']['input'];
};

export type QueryCheckIfSearchFirmNameIsAvailableArgs = {
  input: CheckIfSearchFirmNameIsAvailableInput;
};

export type QueryCompanyLogoUploadUrlAndFileNameArgs = {
  input: CompanyLogoUploadUrlAndFileNameInput;
};

export type QueryCurriculumVitaeAwss3UploadUrlAndKeyArgs = {
  input: CurriculumVitaeAwss3UploadUrlAndKeyInput;
};

export type QueryCvBankProfilesArgs = {
  filters?: InputMaybe<CvBankProfilesFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryDeprecatedJobCategoriesArgs = {
  filters?: InputMaybe<DeprecatedJobCategoriesFilters>;
};

export type QueryEmployerJobOfferLeadArgs = {
  jobOfferLeadId: Scalars['String']['input'];
};

export type QueryEmployerPaginatedJobOfferLeadsArgs = {
  filters?: InputMaybe<EmployerJobOfferLeadsFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryEmployerPaginatedJobOffersArgs = {
  filters?: InputMaybe<EmployerJobOffersFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryEmployersArgs = {
  input: GetEmployersInput;
};

export type QueryHasAlreadyAppliedToJobOfferArgs = {
  input: HasAlreadyAppliedToJobOfferInput;
};

export type QueryInvitationsArgs = {
  filters?: InputMaybe<InvitationsFilters>;
};

export type QueryJobApplicationArgs = {
  id: Scalars['String']['input'];
};

export type QueryJobApplicationRecommendationArgs = {
  input: GetJobApplicationRecommendationInput;
};

export type QueryJobCategoriesArgs = {
  filters?: InputMaybe<JobCategoriesFilters>;
};

export type QueryJobOfferArgs = {
  id: Scalars['String']['input'];
};

export type QueryJobOfferApplicationsArgs = {
  filters?: InputMaybe<JobApplicationsFilters>;
  jobOfferId: Scalars['String']['input'];
};

export type QueryJobOfferNewsFeedArgs = {
  input: GetJobOfferNewsFeedInput;
};

export type QueryJobOfferRecruitmentTaskRecommendationsArgs = {
  filters: RecruitmentTaskRecommendationsFilters;
  jobOfferId: Scalars['String']['input'];
};

export type QueryJobOfferRecruitmentTasksArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type QueryJobOfferStaffingCopyStrategySelectedRecruitersArgs = {
  input: JobOfferStaffingCopyStrategySelectedRecruitersInput;
};

export type QueryJobOfferStaffingExploratoryStrategySelectedRecruitersArgs = {
  input: JobOfferStaffingExploratoryStrategySelectedRecruitersInput;
};

export type QueryJobOfferStaffingStandardStrategySelectedRecruitersArgs = {
  input: JobOfferStaffingStandardStrategySelectedRecruitersInput;
};

export type QueryLastProfileJobApplicationRecommendationsComputationArgs = {
  input: GetLastProfileJobApplicationRecommendationsComputationInput;
};

export type QueryMessageFileUploadUrlAndFileNameArgs = {
  input: MessageFileUploadUrlAndFileNameInput;
};

export type QueryMessagesArgs = {
  input: GetMessagesInput;
};

export type QueryMyChatChannelsArgs = {
  input: GetMyChatChannelsInput;
};

export type QueryNewJobCategoriesArgs = {
  filters?: InputMaybe<NewJobCategoriesFilters>;
};

export type QueryPaginatedAnonymizedProfilesArgs = {
  filters?: InputMaybe<AnonymizedProfilesFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryPaginatedJobOfferSuggestedAnonymizedProfilesArgs = {
  input: PaginatedJobOfferSuggestedAnonymizedProfilesInput;
};

export type QueryPaginatedJobOffersArgs = {
  input: PaginatedJobOffersInput;
};

export type QueryPaginatedRecruiterProfilesArgs = {
  filters?: InputMaybe<RecruiterProfilesFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
  sort?: InputMaybe<RecruiterProfilesSort>;
};

export type QueryPaginatedRecruitersArgs = {
  input: PaginatedRecruitersInput;
};

export type QueryProfileArgs = {
  input: GetProfileInput;
};

export type QueryProfileAcademicBackgroundsArgs = {
  input: GetProfileAcademicBackgroundsInput;
};

export type QueryProfileCvBankAddibilityArgs = {
  profileId: Scalars['String']['input'];
};

export type QueryProfileCvBankViewCountViewerPairsArgs = {
  input: ProfileCvBankViewCountViewerPairsInput;
};

export type QueryProfileCareerDataFromLinkedInArgs = {
  input: GetCareerDataFromLinkedInInput;
};

export type QueryProfileJobApplicationRecommendationRankingArgs = {
  input: GetProfileJobApplicationRecommendationRankingInput;
};

export type QueryProfileJobApplicationRecommendationsComputationArgs = {
  input: GetProfileJobApplicationRecommendationsComputationInput;
};

export type QueryProfileJobOfferPotentialMatchesArgs = {
  input: GetProfileJobOfferPotentialMatchesInput;
};

export type QueryProfilePictureUploadUrlAndFileNameArgs = {
  input: ProfilePictureUploadUrlAndFileNameInput;
};

export type QueryProfileProfilePictureUploadUrlAndFileNameArgs = {
  input: ProfileProfilePictureUploadUrlAndFileNameInput;
};

export type QueryPublicInvitationArgs = {
  input: PublicInvitationInput;
};

export type QueryRecruiterArgs = {
  id: Scalars['String']['input'];
};

export type QueryRecruiterJobApplicationsArgs = {
  filters?: InputMaybe<JobApplicationsFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryRecruiterProfilesArgs = {
  filters?: InputMaybe<RecruiterProfilesFilters>;
};

export type QueryRecruiterRecruitmentTaskByJobOfferIdArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type QueryRecruiterRecruitmentTaskRecommendationsArgs = {
  filters: RecruitmentTaskRecommendationsFilters;
};

export type QueryRecruiterRecruitmentTaskRecommendationsCountArgs = {
  filters: RecruitmentTaskRecommendationsFilters;
};

export type QueryRecruiterRecruitmentTasksArgs = {
  filters?: InputMaybe<RecruiterRecruitmentTasksFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryRecruitersStaffedOnJobOfferArgs = {
  input: RecruitersStaffedOnJobOfferInput;
};

export type QueryRecruitmentTaskArgs = {
  id: Scalars['String']['input'];
};

export type QueryRecruitmentTaskRecommendationArgs = {
  id: Scalars['String']['input'];
};

export type QueryRecruitmentTasksOfRecruiterForMyEmployerArgs = {
  input: RecruitmentTasksOfRecruiterForMyEmployerInput;
};

export type QuerySchoolsArgs = {
  input: GetSchoolsInput;
};

export type QuerySkillsArgs = {
  input: GetSkillsInput;
};

export type QueryTermsOfServiceArgs = {
  input: TermsOfServiceInput;
};

export type QueryValidSkillsArgs = {
  input: GetValidSkillsInput;
};

export type QueryVideoBriefAwsS3UploadUrlAndKeyArgs = {
  input: VideoBriefAwss3UploadUrlAndKeyInput;
};

export type RateRecruitmentTaskInput = {
  ratingValue: Scalars['Float']['input'];
  recruitmentTaskId: Scalars['String']['input'];
};

export type RecruiterActivityMetrics = {
  __typename?: 'RecruiterActivityMetrics';
  jobApplicationMetrics: RecruiterJobApplicationMetrics;
};

export type RecruiterActivityMetricsJobApplicationMetricsArgs = {
  input: RecruiterJobApplicationMetricsInput;
};

export type RecruiterDeprecatedJobCategoryAssociationInput = {
  deprecatedJobCategoryId: Scalars['String']['input'];
};

export type RecruiterJobApplicationMetrics = {
  __typename?: 'RecruiterJobApplicationMetrics';
  hireConversionRateInBasisPoints: Scalars['Int']['output'];
  interviewConversionRateInBasisPoints: Scalars['Int']['output'];
  numberOfJobApplications: Scalars['Int']['output'];
};

export type RecruiterJobApplicationMetricsInput = {
  /** The date of the observation. Helpful for instance to differ the studied period from the last job application. If not provided, it will be set to today */
  observeAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Metrics will be computed out of job applications submitted after this date. If not provided, the date will be set to 30 days ago */
  submittedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  /** Metrics will be computed out of job applications submitted before this date. If not provided, the date will be set to today */
  submittedBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RecruiterJobCategoryAssociationInput = {
  /** For now, jobCategoryId input can correspond to either a deprecatedJobCategoryId or a jobCategoryId. */
  jobCategoryId: Scalars['String']['input'];
};

export type RecruiterOffsetBasedConnection = {
  __typename?: 'RecruiterOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<User>;
  totalCount: Scalars['Int']['output'];
};

export type RecruiterProfilesFilters = {
  andFilterSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isDiscoverableInCVBank?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenToWork?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryId. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  /** For now, use this newJobCategoryIds input to filter by newJobCategories. Delete this field as soon as the input jobCategoryIds filters by new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orFilterSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchedText?: InputMaybe<Scalars['String']['input']>;
  urbanAreaId?: InputMaybe<Scalars['String']['input']>;
};

export type RecruiterProfilesSort = {
  /** This was originally added to see that sortBy createdAt is the default, and make this behaviour explicit on the API */
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type RecruiterRecruitmentTasksFilters = {
  isTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, this jobCategoryId input should correspond to a deprecatedJobCategoryId. */
  jobCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** For now, use this newJobCategoryId to filter by new jobCategoryId. Delete this field as soon as the input jobCategoryId corresponds to a new jobCategoryId */
  newJobCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** Provide the priority indicators you want to filter on. When nothing is passed, nothing will be filtered out */
  priorityIndicators?: InputMaybe<Array<JobOfferPriorityIndicator>>;
  recruitmentTaskStatus?: InputMaybe<RecruitmentTaskStatus>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type RecruiterStaffingJobOfferReport = {
  __typename?: 'RecruiterStaffingJobOfferReport';
  error?: Maybe<Scalars['String']['output']>;
  jobOfferId: Scalars['String']['output'];
  jobOfferTitle: Scalars['String']['output'];
};

export type RecruitersFilters = {
  searchedText?: InputMaybe<Scalars['String']['input']>;
};

export type RecruitersStaffedOnJobOfferInput = {
  jobOfferId: Scalars['String']['input'];
};

export type RecruitmentTask = {
  __typename?: 'RecruitmentTask';
  id: Scalars['ID']['output'];
  jobApplications: Array<JobApplication>;
  jobOffer: JobOffer;
  rating?: Maybe<RecruitmentTaskRating>;
  recruiter: User;
  recruiterFlatFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruiterSuccessFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  status: RecruitmentTaskStatus;
};

export type RecruitmentTaskOffsetBasedConnection = {
  __typename?: 'RecruitmentTaskOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<RecruitmentTask>;
  totalCount: Scalars['Int']['output'];
};

export type RecruitmentTaskRating = {
  __typename?: 'RecruitmentTaskRating';
  id: Scalars['ID']['output'];
  value: Scalars['Float']['output'];
};

export type RecruitmentTaskRecommendation = {
  __typename?: 'RecruitmentTaskRecommendation';
  createdAt: Scalars['DateTime']['output'];
  expirationDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  recruiter: User;
  recruiterFlatFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruiterSuccessFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status: RecruitmentTaskRecommendationStatus;
};

export enum RecruitmentTaskRecommendationStatus {
  Accepted = 'accepted',
  AcceptedViaJobApplicationRecommendation = 'accepted_via_job_application_recommendation',
  Expired = 'expired',
  Pending = 'pending',
  Rejected = 'rejected',
  WithdrawnByCustomerSuccessManager = 'withdrawn_by_customer_success_manager',
}

export type RecruitmentTaskRecommendationsFilters = {
  status?: InputMaybe<RecruitmentTaskRecommendationStatus>;
};

export enum RecruitmentTaskStatus {
  AbandonedByRecruiter = 'ABANDONED_BY_RECRUITER',
  EndedByInternalStaff = 'ENDED_BY_INTERNAL_STAFF',
  Ongoing = 'ONGOING',
}

export type RecruitmentTasksOfRecruiterForMyEmployerInput = {
  recruiterId: Scalars['String']['input'];
};

export type RejectJobApplicationInput = {
  jobApplicationId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export type RejectRecruitementTaskRecommendationInput = {
  reason: Scalars['String']['input'];
  recommendationId: Scalars['String']['input'];
};

export enum RemoteWorkPolicy {
  AuthorizedFullRemote = 'AUTHORIZED_FULL_REMOTE',
  FullOnsite = 'FULL_ONSITE',
  MandatoryFullRemote = 'MANDATORY_FULL_REMOTE',
  PartialRemote = 'PARTIAL_REMOTE',
}

export type RemoveProfileFromCvBankInput = {
  profileId: Scalars['String']['input'];
};

export type ReportJobApplicationAsRejectedInput = {
  jobApplicationId: Scalars['String']['input'];
};

export type RequestFirstAppointmentInput = {
  email: Scalars['String']['input'];
};

export type RequestRecruitmentProcessOutsourcingQuotationInput = {
  maximumDailyFeeInCents: Scalars['Int']['input'];
  minimumNumberOfOfficeDaysPerWeek: Scalars['Int']['input'];
  otherDetails?: InputMaybe<Scalars['String']['input']>;
  recruiterSpecializationJobCategoryIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  taskDescription: Scalars['String']['input'];
  taskDurationInMonths: Scalars['Int']['input'];
};

export type Role = {
  __typename?: 'Role';
  name: RoleName;
};

export enum RoleName {
  CvBankSupplier = 'CV_BANK_SUPPLIER',
  ProfileMatchingUser = 'PROFILE_MATCHING_USER',
  SuperRecruiter = 'SUPER_RECRUITER',
}

export type School = {
  __typename?: 'School';
  id: Scalars['ID']['output'];
  linkedInSchoolId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type SearchFirm = {
  __typename?: 'SearchFirm';
  address?: Maybe<Address>;
  bankDetails?: Maybe<BankDetails>;
  /** A list of contracts associated to the search firm. */
  contracts?: Maybe<Array<Contract>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legalForm?: Maybe<LegalForm>;
  name: Scalars['String']['output'];
  numberOfRecruiters?: Maybe<Scalars['Int']['output']>;
  referenceClients: Array<Employer>;
  siret?: Maybe<Scalars['String']['output']>;
  subscriptions: Array<Subscription>;
};

export type SearchFirmLegalRepresentativeContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type SendOneToOneMessageToJobOfferRecruitersInput = {
  body: Scalars['String']['input'];
  jobOfferId: Scalars['String']['input'];
};

export type SetUpCvBankAlertInput = {
  searchCriteria: CvBankProfilesFilters;
  searchUrl: Scalars['String']['input'];
};

export type Skill = {
  __typename?: 'Skill';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: SkillStatus;
};

export enum SkillStatus {
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Valid = 'VALID',
}

export type SkillsWithQueryModeInput = {
  ids: Array<Scalars['ID']['input']>;
  mode?: InputMaybe<EntitiesQueryMode>;
};

export type StaffJobOfferInput = {
  jobOfferId: Scalars['ID']['input'];
  recruiterIds: Array<Scalars['String']['input']>;
};

export type StaffRecruiterInput = {
  jobOffersIds: Array<Scalars['ID']['input']>;
  recruiterId: Scalars['String']['input'];
};

export type StoredFile = {
  __typename?: 'StoredFile';
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  originalFileName?: Maybe<Scalars['String']['output']>;
};

export type StripeCustomerCheckoutSession = {
  __typename?: 'StripeCustomerCheckoutSession';
  url: Scalars['String']['output'];
};

export type StripeCustomerPortalSession = {
  __typename?: 'StripeCustomerPortalSession';
  url: Scalars['String']['output'];
};

export type SubmitJobApplicationInput = {
  jobOfferId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['String']['output'];
  paymentServiceProviderName: PaymentServiceProviderName;
  status: SubscriptionStatus;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  Unpaid = 'UNPAID',
}

export type SuccessFee = {
  __typename?: 'SuccessFee';
  basisPoints: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type TakeRecruitmentTaskViaJobApplicationRecommendationInput = {
  jobApplicationRecommendationId: Scalars['String']['input'];
};

export type TerminateJobOfferInput = {
  jobOfferId: Scalars['String']['input'];
  messageForRecruiters?: InputMaybe<Scalars['String']['input']>;
  terminationReason: Scalars['String']['input'];
};

export type TermsOfService = {
  __typename?: 'TermsOfService';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
};

export type TermsOfServiceInput = {
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCvBankProfileDescriptionInput = {
  descriptionText?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['String']['input'];
};

export type UpdateCarbonCopyCouplesInput = {
  carbonCopyUserIds: Array<Scalars['String']['input']>;
  topic: CarbonCopyTopic;
};

export type UpdateEmployerInput = {
  address?: InputMaybe<AddressInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['ID']['input']>;
  nonPoachingAgreementsDescription?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployerLogoInput = {
  employerId: Scalars['String']['input'];
  logoFileName: Scalars['String']['input'];
};

export type UpdateJobOfferInput = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  careerOutlook?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<CurrencyIso4217Code>;
  deprecatedJobCategoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hasHealthInsurancePlan?: InputMaybe<Scalars['Boolean']['input']>;
  hasIncentiveBonus?: InputMaybe<Scalars['Boolean']['input']>;
  hasLuncheonVouchers?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isTeleworkingAuthorized?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, this jobCategoryId input should correspond to a deprecatedJobCategoryId. */
  jobCategoryId?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  mandatoryProfileAttributes?: InputMaybe<Scalars['String']['input']>;
  maxNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  maximumRemuneration?: InputMaybe<Scalars['Int']['input']>;
  maximumVariableRemuneration?: InputMaybe<Scalars['Int']['input']>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  minimumRemuneration?: InputMaybe<Scalars['Int']['input']>;
  motivationalMessageToRecruiters?: InputMaybe<Scalars['String']['input']>;
  /** Fow now, use this field to update the jobOffer new jobCategoryId. Delete this field as soon as the field jobCategoryId corresponds to the new jobCategory */
  newJobCategoryId?: InputMaybe<Scalars['ID']['input']>;
  numberOfPositionsToFill?: InputMaybe<Scalars['Int']['input']>;
  perksDescription?: InputMaybe<Scalars['String']['input']>;
  profileDescription?: InputMaybe<Scalars['String']['input']>;
  recruitmentProcess?: InputMaybe<Scalars['String']['input']>;
  remoteWorkPolicy?: InputMaybe<RemoteWorkPolicy>;
  successFeeBasisPoints?: InputMaybe<Scalars['Int']['input']>;
  technicalSkillsDescription?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typicalProfileUrl?: InputMaybe<Scalars['String']['input']>;
  videoBriefAwsS3Key?: InputMaybe<Scalars['String']['input']>;
  videoBriefFilename?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMyEmployerLogoInput = {
  logoFileName: Scalars['String']['input'];
};

export type UpdateMyProfilePictureInput = {
  profilePictureFileName: Scalars['String']['input'];
};

export type UpdateProfileAcademicBackgroundsInput = {
  academicBackgroundIds: Array<Scalars['String']['input']>;
  profileId: Scalars['String']['input'];
};

export type UpdateProfileInput = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['String']['input']>>;
  approximateFirstJobStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeAwsS3Key?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeFilename?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryIds. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInProfileUrl?: InputMaybe<Scalars['String']['input']>;
  /** For now, use this newJobCategoryIds input to update the profile's new jobCategories. Delete this field as soon as the input jobCategoryIds updates the new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  potentialSkillNames?: InputMaybe<Array<Scalars['String']['input']>>;
  preferredRemoteWorkPolicy?: InputMaybe<Scalars['String']['input']>;
  profilePictureFilename?: InputMaybe<Scalars['String']['input']>;
  salaryClaimAmount?: InputMaybe<Scalars['Int']['input']>;
  salaryClaimCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  urbanAreaId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfileJobCategoriesInput = {
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryIds. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** For now, use this newJobCategoryIds input to update the profile's new jobCategories. Delete this field as soon as the input jobCategoryIds updates the new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId: Scalars['String']['input'];
};

export type UpdateProfileSkillsInput = {
  profileId: Scalars['String']['input'];
  skillIds: Array<Scalars['String']['input']>;
};

export type UpdateRecruiterActivitySectorSpecializationsInput = {
  activitySectorIds: Array<Scalars['String']['input']>;
};

export type UpdateSearchFirmInput = {
  address?: InputMaybe<AddressInput>;
  bankDetails?: InputMaybe<BankDetailsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  numberOfRecruiters?: InputMaybe<Scalars['Int']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  isInterestedInRecruitmentProcessOutsourcing?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  lastName?: InputMaybe<Scalars['String']['input']>;
  liAt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertCanonicalProfileInput = {
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  lastEnrichmentAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInVanityName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profilePictureFilename?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertJobOfferLeadsInput = {
  description: Scalars['String']['input'];
  domainName: Scalars['String']['input'];
  jobBoard: Scalars['String']['input'];
  jobBoardUrl: Scalars['String']['input'];
  location: Scalars['String']['input'];
  publicatedAt: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type UrbanArea = {
  __typename?: 'UrbanArea';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UrbanAreaWithRadiusInput = {
  id: Scalars['ID']['input'];
  /** The radius of the searched zone around the urban area expressed in meters. */
  radius?: InputMaybe<Scalars['Float']['input']>;
};

export type User = {
  __typename?: 'User';
  activityMetric: UserActivityMetric;
  carbonCopyCouplesCreatedByCurrentUser: Array<CarbonCopyCouple>;
  createdAt: Scalars['DateTime']['output'];
  cvBankCreditCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  employer?: Maybe<Employer>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAuthorizedToUseMarketplace: Scalars['Boolean']['output'];
  isInterestedInRecruitmentProcessOutsourcing?: Maybe<
    Scalars['Boolean']['output']
  >;
  /** For admin staffing only. Get the potential last recruitment task recommendation of this recruiter for a given job offer */
  jobOfferLatestRecruitmentTaskRecommendation?: Maybe<RecruitmentTaskRecommendation>;
  /** For admin staffing only. Get the potential last recruitment task of this recruiter for a given job offer */
  jobOfferRecruitmentTask?: Maybe<RecruitmentTask>;
  jobOfferStaffingRecruiterTier?: Maybe<JobOfferStaffingRecruiterTier>;
  lastName: Scalars['String']['output'];
  mainBusinessType: BusinessType;
  myOneToOneChatChannelId?: Maybe<Scalars['String']['output']>;
  permissions: Array<Permission>;
  phoneNumber: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  recruiterActivitySectors: Array<ActivitySector>;
  recruiterDeprecatedJobCategories?: Maybe<Array<JobCategory>>;
  /**
   * For now, this field returns deprecatedJobCategories.
   * @deprecated Use recruiterDeprecatedJobCategories instead. Update the website, wait a few days, then update this field to return the new jobCategories
   */
  recruiterJobCategories?: Maybe<Array<JobCategory>>;
  /** For now, use this field to get the new jobCategories. Delete this field as soon as the field recruiterJobCategories returns the new jobCategories */
  recruiterNewJobCategories?: Maybe<Array<JobCategory>>;
  roles: Array<Role>;
  searchFirm?: Maybe<SearchFirm>;
  /** For admin staffing only. Get the potential end of partnership date for this recruiter */
  softEndOfPartnershipDate?: Maybe<Scalars['DateTime']['output']>;
  /** For admin staffing only. Get the potential end of partnership reason for this recruiter */
  softEndOfPartnershipReason?: Maybe<Scalars['String']['output']>;
  /** The last terms of services version the user agreed to. If null, the user never agreed to any terms of services. */
  termsOfServiceVersion?: Maybe<Scalars['Float']['output']>;
};

export type UserJobOfferLatestRecruitmentTaskRecommendationArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type UserJobOfferRecruitmentTaskArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type UserActivityMetric = {
  __typename?: 'UserActivityMetric';
  /** The response rate of the user as basis points. For example, 50 means 0.5% */
  jobApplicationsResponseRate?: Maybe<Scalars['Int']['output']>;
  /** The average response time of the user in hours */
  jobApplicationsResponseTime?: Maybe<Scalars['Int']['output']>;
  /** The recruiter activity metrics. Only available for recruiters */
  recruiterActivityMetrics?: Maybe<RecruiterActivityMetrics>;
};

export type UserLead = {
  __typename?: 'UserLead';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mainBusinessType: BusinessType;
};

export type VideoBriefAwss3UploadUrlAndKeyInput = {
  fileType: Scalars['String']['input'];
  jobOfferId: Scalars['String']['input'];
};

export type VideoBriefMeeting = {
  __typename?: 'VideoBriefMeeting';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  jobOfferId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ViewCvBankProfileInput = {
  profileId: Scalars['String']['input'];
};

export type ViewCvBankProfileResponse = {
  __typename?: 'ViewCVBankProfileResponse';
  profile: Profile;
  remainingCredits: Scalars['Int']['output'];
};

export type VisitLinkedInProfileInput = {
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  linkedInVanityName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UseAcademicBackgroundOptions_AcademicBackgroundsQueryVariables =
  Exact<{ [key: string]: never }>;

export type UseAcademicBackgroundOptions_AcademicBackgroundsQuery = {
  __typename?: 'Query';
  academicBackgrounds: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }>;
};

export type JobOfferLinksPopover_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  status: JobOfferStatus;
  numberOfOngoingRecruitmentTasks: number;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
};

export type JobOfferCurrentStaffingDrawer_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  status: JobOfferStatus;
  numberOfOngoingRecruitmentTasks: number;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
};

export type JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendationFragment =
  {
    __typename?: 'RecruitmentTaskRecommendation';
    status: RecruitmentTaskRecommendationStatus;
    id: string;
    recruiter: {
      __typename: 'User';
      id: string;
      email: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    };
  };

export type JobOfferRecruitmentTaskRecommendationsQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
  filters: RecruitmentTaskRecommendationsFilters;
}>;

export type JobOfferRecruitmentTaskRecommendationsQuery = {
  __typename?: 'Query';
  jobOfferRecruitmentTaskRecommendations: Array<{
    __typename?: 'RecruitmentTaskRecommendation';
    status: RecruitmentTaskRecommendationStatus;
    id: string;
    recruiter: {
      __typename: 'User';
      id: string;
      email: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    };
  }>;
};

export type JobOfferRecruitmentTaskRecommendationsList_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  status: JobOfferStatus;
};

export type JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragment =
  {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    status: RecruitmentTaskRecommendationStatus;
    recruiter: {
      __typename: 'User';
      id: string;
      email: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    };
  };

export type JobOfferRecruitmentTasksList_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  status: JobOfferStatus;
  numberOfOngoingRecruitmentTasks: number;
};

export type JobOfferRecruitmentTasksList_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  status: RecruitmentTaskStatus;
  id: string;
  recruiter: {
    __typename: 'User';
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  };
  jobApplications: Array<{ __typename?: 'JobApplication'; id: string }>;
};

export type JobOfferRecruitmentTasksQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type JobOfferRecruitmentTasksQuery = {
  __typename?: 'Query';
  jobOfferRecruitmentTasks: Array<{
    __typename?: 'RecruitmentTask';
    status: RecruitmentTaskStatus;
    id: string;
    recruiter: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    };
    jobApplications: Array<{ __typename?: 'JobApplication'; id: string }>;
  }>;
};

export type JobOfferRecruitmentTasksTable_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  recruiter: {
    __typename: 'User';
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  };
  jobApplications: Array<{ __typename?: 'JobApplication'; id: string }>;
};

export type JobOfferSelectionTable_PaginatedJobOffersQueryQueryVariables =
  Exact<{
    input: PaginatedJobOffersInput;
  }>;

export type JobOfferSelectionTable_PaginatedJobOffersQueryQuery = {
  __typename?: 'Query';
  paginatedJobOffers: {
    __typename?: 'JobOfferOffsetBasedConnection';
    hasNextPage: boolean;
    totalCount: number;
    nodes: Array<{
      __typename?: 'JobOffer';
      id: string;
      title: string;
      status: JobOfferStatus;
      isTerminated: boolean;
      numberOfOngoingRecruitmentTasks: number;
      jobCategory?: { __typename?: 'JobCategory'; name: string } | null;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    }>;
  };
};

export type RecruitersCopySelectionFromJobOfferTable_PaginatedJobOffersQueryQueryVariables =
  Exact<{
    input: PaginatedJobOffersInput;
  }>;

export type RecruitersCopySelectionFromJobOfferTable_PaginatedJobOffersQueryQuery =
  {
    __typename?: 'Query';
    paginatedJobOffers: {
      __typename?: 'JobOfferOffsetBasedConnection';
      hasNextPage: boolean;
      totalCount: number;
      nodes: Array<{
        __typename?: 'JobOffer';
        id: string;
        title: string;
        status: JobOfferStatus;
        isTerminated: boolean;
        numberOfOngoingRecruitmentTasks: number;
        jobCategory?: { __typename?: 'JobCategory'; name: string } | null;
        employer?: { __typename?: 'Employer'; name: string; id: string } | null;
        replacedJobApplication?: {
          __typename?: 'JobApplication';
          id: string;
        } | null;
      }>;
    };
  };

export type StaffJobOfferMutationVariables = Exact<{
  input: StaffJobOfferInput;
}>;

export type StaffJobOfferMutation = {
  __typename?: 'Mutation';
  staffJobOffer: Array<{
    __typename?: 'JobOfferStaffingRecruiterReport';
    recruiterId: string;
    recruiterEmail: string;
    error?: string | null;
  }>;
};

export type UseJobOfferAutoStaffing_UserFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  jobOfferStaffingRecruiterTier?: JobOfferStaffingRecruiterTier | null;
  roles: Array<{ __typename?: 'Role'; name: RoleName }>;
};

export type UseJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInputQueryVariables =
  Exact<{
    input: JobOfferStaffingStandardStrategySelectedRecruitersInput;
  }>;

export type UseJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInputQuery =
  {
    __typename?: 'Query';
    jobOfferStaffingStandardStrategySelectedRecruiters: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      jobOfferStaffingRecruiterTier?: JobOfferStaffingRecruiterTier | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    }>;
  };

export type UseJobOfferAutoStaffingExploratory_JobOfferStaffingExploratoryStrategySelectedRecruitersQueryVariables =
  Exact<{
    input: JobOfferStaffingExploratoryStrategySelectedRecruitersInput;
  }>;

export type UseJobOfferAutoStaffingExploratory_JobOfferStaffingExploratoryStrategySelectedRecruitersQuery =
  {
    __typename?: 'Query';
    jobOfferStaffingExploratoryStrategySelectedRecruiters: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      jobOfferStaffingRecruiterTier?: JobOfferStaffingRecruiterTier | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    }>;
  };

export type UseJobOfferAutoStaffingCopy_JobOfferStaffingCopyStrategySelectedRecruitersQueryVariables =
  Exact<{
    input: JobOfferStaffingCopyStrategySelectedRecruitersInput;
  }>;

export type UseJobOfferAutoStaffingCopy_JobOfferStaffingCopyStrategySelectedRecruitersQuery =
  {
    __typename?: 'Query';
    jobOfferStaffingCopyStrategySelectedRecruiters: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      jobOfferStaffingRecruiterTier?: JobOfferStaffingRecruiterTier | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    }>;
  };

export type UseJobOfferSelectionTableColumns_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  status: JobOfferStatus;
  isTerminated: boolean;
  numberOfOngoingRecruitmentTasks: number;
  jobCategory?: { __typename?: 'JobCategory'; name: string } | null;
  employer?: { __typename?: 'Employer'; name: string; id: string } | null;
  replacedJobApplication?: { __typename?: 'JobApplication'; id: string } | null;
};

export type StaffRecruiterMutationVariables = Exact<{
  input: StaffRecruiterInput;
}>;

export type StaffRecruiterMutation = {
  __typename?: 'Mutation';
  staffRecruiter: Array<{
    __typename?: 'RecruiterStaffingJobOfferReport';
    jobOfferId: string;
    jobOfferTitle: string;
    error?: string | null;
  }>;
};

export type StaffingFromRecruiterJobOffersTable_PaginatedJobOffersQueryQueryVariables =
  Exact<{
    input: PaginatedJobOffersInput;
    recruiterId: Scalars['String']['input'];
  }>;

export type StaffingFromRecruiterJobOffersTable_PaginatedJobOffersQueryQuery = {
  __typename?: 'Query';
  paginatedJobOffers: {
    __typename?: 'JobOfferOffsetBasedConnection';
    hasNextPage: boolean;
    totalCount: number;
    nodes: Array<{
      __typename?: 'JobOffer';
      id: string;
      title: string;
      createdAt: any;
      status: JobOfferStatus;
      isTerminated: boolean;
      numberOfOngoingRecruitmentTasks: number;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      recruiterLatestRecruitmentTaskRecommendation?: {
        __typename?: 'RecruitmentTaskRecommendation';
        id: string;
        createdAt: any;
        status: RecruitmentTaskRecommendationStatus;
      } | null;
      recruiterRecruitmentTask?: {
        __typename?: 'RecruitmentTask';
        id: string;
        status: RecruitmentTaskStatus;
      } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    }>;
  };
};

export type UseStaffingFromRecruiterJobOffersTableColumns_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  createdAt: any;
  status: JobOfferStatus;
  isTerminated: boolean;
  numberOfOngoingRecruitmentTasks: number;
  employer?: { __typename?: 'Employer'; name: string; id: string } | null;
  recruiterLatestRecruitmentTaskRecommendation?: {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    createdAt: any;
    status: RecruitmentTaskRecommendationStatus;
  } | null;
  recruiterRecruitmentTask?: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
  } | null;
  replacedJobApplication?: { __typename?: 'JobApplication'; id: string } | null;
};

export type EmployerJobOffersQueryVariables = Exact<{
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
  filters?: InputMaybe<EmployerJobOffersFilters>;
}>;

export type EmployerJobOffersQuery = {
  __typename?: 'Query';
  employerPaginatedJobOffers: {
    __typename?: 'JobOfferOffsetBasedConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'JobOffer';
      id: string;
      status: JobOfferStatus;
      creator?: { __typename?: 'User'; id: string } | null;
    }>;
  };
};

export type MyRecruiterActivityInformationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MyRecruiterActivityInformationQuery = {
  __typename?: 'Query';
  myUser?:
    | {
        __typename: 'User';
        recruiterActivitySectors: Array<{
          __typename?: 'ActivitySector';
          id: string;
        }>;
        recruiterDeprecatedJobCategories?: Array<{
          __typename?: 'JobCategory';
          id: string;
        }> | null;
        recruiterNewJobCategories?: Array<{
          __typename?: 'JobCategory';
          id: string;
        }> | null;
      }
    | { __typename: 'UserLead' }
    | null;
};

export type UseChatChannel_MessageFragment = {
  __typename?: 'Message';
  id: string;
  body?: string | null;
  createdAt: any;
  myReadStatus: MessageReadStatus;
  jobApplicationStatus?: {
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
    jobApplication: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
      };
      jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
    };
  } | null;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  };
  files: Array<{
    __typename?: 'StoredFile';
    id: string;
    fileUrl: string;
    originalFileName?: string | null;
    mimeType?: string | null;
  }>;
};

export type UseChatChannel_MessageOffsetBasedConnectionFragment = {
  __typename?: 'MessageOffsetBasedConnection';
  hasNextPage: boolean;
  totalCount: number;
  nodes: Array<{
    __typename?: 'Message';
    id: string;
    body?: string | null;
    createdAt: any;
    myReadStatus: MessageReadStatus;
    jobApplicationStatus?: {
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      jobApplication: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
        };
        jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
      };
    } | null;
    creator: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
    };
    files: Array<{
      __typename?: 'StoredFile';
      id: string;
      fileUrl: string;
      originalFileName?: string | null;
      mimeType?: string | null;
    }>;
  }>;
};

export type UseChatChannel_MessagesQueryVariables = Exact<{
  input: GetMessagesInput;
}>;

export type UseChatChannel_MessagesQuery = {
  __typename?: 'Query';
  messages: {
    __typename?: 'MessageOffsetBasedConnection';
    hasNextPage: boolean;
    totalCount: number;
    nodes: Array<{
      __typename?: 'Message';
      id: string;
      body?: string | null;
      createdAt: any;
      myReadStatus: MessageReadStatus;
      jobApplicationStatus?: {
        __typename?: 'JobApplicationStatus';
        id: string;
        name: JobApplicationStatusName;
        createdAt: any;
        jobApplication: {
          __typename?: 'JobApplication';
          id: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            firstName: string;
            lastName: string;
          };
          jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
        };
      } | null;
      creator: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
      };
      files: Array<{
        __typename?: 'StoredFile';
        id: string;
        fileUrl: string;
        originalFileName?: string | null;
        mimeType?: string | null;
      }>;
    }>;
  };
};

export type UsePostMessage_MessageFragment = {
  __typename?: 'Message';
  id: string;
  body?: string | null;
  createdAt: any;
  myReadStatus: MessageReadStatus;
  jobApplicationStatus?: {
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
    jobApplication: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
      };
      jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
    };
  } | null;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  };
  files: Array<{
    __typename?: 'StoredFile';
    id: string;
    fileUrl: string;
    originalFileName?: string | null;
    mimeType?: string | null;
  }>;
};

export type UsePostMessage_MessageOffsetBasedConnectionFragment = {
  __typename?: 'MessageOffsetBasedConnection';
  hasNextPage: boolean;
  totalCount: number;
  nodes: Array<{
    __typename?: 'Message';
    id: string;
    body?: string | null;
    createdAt: any;
    myReadStatus: MessageReadStatus;
    jobApplicationStatus?: {
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      jobApplication: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
        };
        jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
      };
    } | null;
    creator: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
    };
    files: Array<{
      __typename?: 'StoredFile';
      id: string;
      fileUrl: string;
      originalFileName?: string | null;
      mimeType?: string | null;
    }>;
  }>;
};

export type UsePostMessage_ChatChannelFragment = {
  __typename?: 'ChatChannel';
  lastMessage?: {
    __typename?: 'Message';
    id: string;
    body?: string | null;
    createdAt: any;
    myReadStatus: MessageReadStatus;
    jobApplicationStatus?: {
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      jobApplication: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
        };
        jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
      };
    } | null;
    creator: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
    };
    files: Array<{
      __typename?: 'StoredFile';
      id: string;
      fileUrl: string;
      originalFileName?: string | null;
      mimeType?: string | null;
    }>;
  } | null;
};

export type UsePostMessage_MessagesQueryVariables = Exact<{
  input: GetMessagesInput;
}>;

export type UsePostMessage_MessagesQuery = {
  __typename?: 'Query';
  messages: {
    __typename?: 'MessageOffsetBasedConnection';
    hasNextPage: boolean;
    totalCount: number;
    nodes: Array<{
      __typename?: 'Message';
      id: string;
      body?: string | null;
      createdAt: any;
      myReadStatus: MessageReadStatus;
      jobApplicationStatus?: {
        __typename?: 'JobApplicationStatus';
        id: string;
        name: JobApplicationStatusName;
        createdAt: any;
        jobApplication: {
          __typename?: 'JobApplication';
          id: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            firstName: string;
            lastName: string;
          };
          jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
        };
      } | null;
      creator: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
      };
      files: Array<{
        __typename?: 'StoredFile';
        id: string;
        fileUrl: string;
        originalFileName?: string | null;
        mimeType?: string | null;
      }>;
    }>;
  };
};

export type UsePostMessage_PostMessageMutationVariables = Exact<{
  input: PostMessageInput;
}>;

export type UsePostMessage_PostMessageMutation = {
  __typename?: 'Mutation';
  postMessage: {
    __typename?: 'Message';
    id: string;
    body?: string | null;
    createdAt: any;
    myReadStatus: MessageReadStatus;
    jobApplicationStatus?: {
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      jobApplication: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
        };
        jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
      };
    } | null;
    creator: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
    };
    files: Array<{
      __typename?: 'StoredFile';
      id: string;
      fileUrl: string;
      originalFileName?: string | null;
      mimeType?: string | null;
    }>;
  };
};

export type UseScrollToLastMessage_MessageFragment = {
  __typename?: 'Message';
  id: string;
};

export type UseUploadMessageFile_MessageFileUploadUrlAndFileNameQueryVariables =
  Exact<{
    input: MessageFileUploadUrlAndFileNameInput;
  }>;

export type UseUploadMessageFile_MessageFileUploadUrlAndFileNameQuery = {
  __typename?: 'Query';
  messageFileUploadUrlAndFileName: {
    __typename?: 'AWSS3UploadUrlAndKey';
    uploadUrl: string;
    awsS3Key: string;
  };
};

export type ChatChannelHeaderInterlocutor_UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  profilePictureUrl?: string | null;
  mainBusinessType: BusinessType;
  employer?: { __typename?: 'Employer'; name: string } | null;
  searchFirm?: { __typename?: 'SearchFirm'; name: string } | null;
};

export type ChatChannelPreview_UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  mainBusinessType: BusinessType;
  phoneNumber: string;
  email: string;
  profilePictureUrl?: string | null;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  searchFirm?: { __typename?: 'SearchFirm'; id: string; name: string } | null;
};

export type ChatChannelPreview_JobApplicationStatusFragment = {
  __typename?: 'JobApplicationStatus';
  id: string;
  name: JobApplicationStatusName;
  createdAt: any;
  jobApplication: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
    };
    jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
  };
};

export type ChatChannelPreview_MessageFragment = {
  __typename: 'Message';
  id: string;
  body?: string | null;
  createdAt: any;
  myReadStatus: MessageReadStatus;
  creator: { __typename?: 'User'; id: string };
  jobApplicationStatus?: {
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
    jobApplication: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
      };
      jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
    };
  } | null;
};

export type ChatChannelPreview_ChatChannelFragment = {
  __typename?: 'ChatChannel';
  id: string;
  users: Array<{
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    mainBusinessType: BusinessType;
    phoneNumber: string;
    email: string;
    profilePictureUrl?: string | null;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
    searchFirm?: { __typename?: 'SearchFirm'; id: string; name: string } | null;
  }>;
  lastMessage?: {
    __typename: 'Message';
    id: string;
    body?: string | null;
    createdAt: any;
    myReadStatus: MessageReadStatus;
    creator: { __typename?: 'User'; id: string };
    jobApplicationStatus?: {
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      jobApplication: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
        };
        jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
      };
    } | null;
  } | null;
};

export type UseChatChannelPreview_MessageFragment = {
  __typename?: 'Message';
  id: string;
  myReadStatus: MessageReadStatus;
};

export type UseChatChannelPreview_MarkMessagesAsReadMutationVariables = Exact<{
  input: MarkMessagesAsReadInput;
}>;

export type UseChatChannelPreview_MarkMessagesAsReadMutation = {
  __typename?: 'Mutation';
  markMessagesAsRead: {
    __typename?: 'Message';
    id: string;
    myReadStatus: MessageReadStatus;
  };
};

export type ChatMessageBubble_JobApplicationStatusFragment = {
  __typename?: 'JobApplicationStatus';
  id: string;
  name: JobApplicationStatusName;
  createdAt: any;
  jobApplication: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
    };
    jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
  };
};

export type ChatMessageBubble_StoredFileFragment = {
  __typename?: 'StoredFile';
  id: string;
  fileUrl: string;
  originalFileName?: string | null;
  mimeType?: string | null;
};

export type ChatMessageBubble_MessageFragment = {
  __typename?: 'Message';
  id: string;
  body?: string | null;
  createdAt: any;
  myReadStatus: MessageReadStatus;
  jobApplicationStatus?: {
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
    jobApplication: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
      };
      jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
    };
  } | null;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  };
  files: Array<{
    __typename?: 'StoredFile';
    id: string;
    fileUrl: string;
    originalFileName?: string | null;
    mimeType?: string | null;
  }>;
};

export type ChatMessageBubble_UserFragment = {
  __typename?: 'User';
  id: string;
};

export type UseMessageToJobOfferRecruitersModal_SendOneToOneMessageToJobOfferRecruitersMutationVariables =
  Exact<{
    input: SendOneToOneMessageToJobOfferRecruitersInput;
  }>;

export type UseMessageToJobOfferRecruitersModal_SendOneToOneMessageToJobOfferRecruitersMutation =
  { __typename?: 'Mutation'; sendOneToOneMessageToJobOfferRecruiters: boolean };

export type UseMyChatChannels_ChatChannelFragment = {
  __typename?: 'ChatChannel';
  id: string;
  users: Array<{
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    mainBusinessType: BusinessType;
    phoneNumber: string;
    email: string;
    profilePictureUrl?: string | null;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
    searchFirm?: { __typename?: 'SearchFirm'; id: string; name: string } | null;
  }>;
  lastMessage?: {
    __typename: 'Message';
    id: string;
    body?: string | null;
    createdAt: any;
    myReadStatus: MessageReadStatus;
    creator: { __typename?: 'User'; id: string };
    jobApplicationStatus?: {
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      jobApplication: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
        };
        jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
      };
    } | null;
  } | null;
};

export type UseMyChatChannels_MyChatChannelsQueryVariables = Exact<{
  input: GetMyChatChannelsInput;
}>;

export type UseMyChatChannels_MyChatChannelsQuery = {
  __typename?: 'Query';
  myChatChannels: {
    __typename?: 'ChatChannelOffsetBasedConnection';
    hasNextPage: boolean;
    nodes: Array<{
      __typename?: 'ChatChannel';
      id: string;
      users: Array<{
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        mainBusinessType: BusinessType;
        phoneNumber: string;
        email: string;
        profilePictureUrl?: string | null;
        employer?: { __typename?: 'Employer'; id: string; name: string } | null;
        searchFirm?: {
          __typename?: 'SearchFirm';
          id: string;
          name: string;
        } | null;
      }>;
      lastMessage?: {
        __typename: 'Message';
        id: string;
        body?: string | null;
        createdAt: any;
        myReadStatus: MessageReadStatus;
        creator: { __typename?: 'User'; id: string };
        jobApplicationStatus?: {
          __typename?: 'JobApplicationStatus';
          id: string;
          name: JobApplicationStatusName;
          createdAt: any;
          jobApplication: {
            __typename?: 'JobApplication';
            id: string;
            profile: {
              __typename?: 'Profile';
              id: string;
              firstName: string;
              lastName: string;
            };
            jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
          };
        } | null;
      } | null;
    }>;
  };
};

export type NewJobApplicationStatusCallout_2_JobApplicationStatusFragment = {
  __typename?: 'JobApplicationStatus';
  id: string;
  name: JobApplicationStatusName;
  jobApplication: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
    };
    jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
  };
};

export type UseChatChannelInterlocutors_ChatChannelQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UseChatChannelInterlocutors_ChatChannelQuery = {
  __typename?: 'Query';
  chatChannel: {
    __typename?: 'ChatChannel';
    users: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      email: string;
      profilePictureUrl?: string | null;
      mainBusinessType: BusinessType;
      employer?: { __typename?: 'Employer'; name: string } | null;
      searchFirm?: { __typename?: 'SearchFirm'; name: string } | null;
    }>;
  };
};

export type UseRedirectToChatChannel_InternalManagerFragment = {
  __typename?: 'InternalManager';
  myOneToOneChatChannelId?: string | null;
};

export type UseRedirectToChatChannel_UserFragment = {
  __typename?: 'User';
  myOneToOneChatChannelId?: string | null;
};

export type UseRedirectToChatChannel_OpenChatChannelMutationVariables = Exact<{
  input: OpenChatChannelInput;
}>;

export type UseRedirectToChatChannel_OpenChatChannelMutation = {
  __typename?: 'Mutation';
  openChatChannel: { __typename?: 'ChatChannel'; id: string };
};

export type UseUnreadChatMessagesCount_UnreadMessagesCountQueryVariables =
  Exact<{ [key: string]: never }>;

export type UseUnreadChatMessagesCount_UnreadMessagesCountQuery = {
  __typename?: 'Query';
  unreadMessagesCount: number;
};

export type ApolloQueryMockFactory_JobOfferQueryQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type ApolloQueryMockFactory_JobOfferQueryQuery = {
  __typename?: 'Query';
  jobOffer: { __typename?: 'JobOffer'; id: string };
};

export type CompanyLogoUploadUrlAndFileNameQueryVariables = Exact<{
  input: CompanyLogoUploadUrlAndFileNameInput;
}>;

export type CompanyLogoUploadUrlAndFileNameQuery = {
  __typename?: 'Query';
  companyLogoUploadUrlAndFileName: {
    __typename?: 'AWSS3UploadUrlAndKey';
    uploadUrl: string;
    awsS3Key: string;
  };
};

export type UpdateMyEmployerLogoMutationVariables = Exact<{
  input: UpdateMyEmployerLogoInput;
}>;

export type UpdateMyEmployerLogoMutation = {
  __typename?: 'Mutation';
  updateMyEmployerLogo: {
    __typename?: 'Employer';
    id: string;
    logoUrl?: string | null;
  };
};

export type HiringManagerLayout_UserFragment = {
  __typename?: 'User';
  id: string;
  isAuthorizedToUseMarketplace: boolean;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
};

export type JobApplicationWithStatusesFragment = {
  __typename?: 'JobApplication';
  id: string;
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    createdAt: any;
    name: JobApplicationStatusName;
    message?: {
      __typename?: 'Message';
      id: string;
      body?: string | null;
      createdAt: any;
      creator: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
      };
    } | null;
  }> | null;
};

export type JobApplicationLastUpdateAlert_JobApplicationFragment = {
  __typename?: 'JobApplication';
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
  }> | null;
};

export type EmployerJobApplicationProfileDrawer_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    curriculumVitaeUrl?: string | null;
    linkedInProfileUrl?: string | null;
    phoneNumber?: string | null;
    email?: string | null;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    salaryClaimAmount?: number | null;
    salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
    approximateFirstJobStartDate?: any | null;
    preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
    creator?: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      createdAt: any;
      phoneNumber: string;
      email: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    } | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      degreeTitle?: string | null;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
    experiences: Array<{
      __typename?: 'Experience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
      skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
    }>;
  };
  recruitmentTask: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
    message?: {
      __typename?: 'Message';
      body?: string | null;
      creator: { __typename?: 'User'; firstName: string; lastName: string };
    } | null;
  }> | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    contractType?: string | null;
    isTerminated: boolean;
    numberOfOngoingRecruitmentTasks: number;
    status: JobOfferStatus;
    employer?: {
      __typename?: 'Employer';
      id: string;
      name: string;
      logoUrl?: string | null;
    } | null;
    creator?: { __typename?: 'User'; id: string; email: string } | null;
    replacedJobApplication?: {
      __typename?: 'JobApplication';
      id: string;
    } | null;
  };
};

export type EmployerJobApplicationProfileDrawerFooter_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
  }> | null;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    numberOfOngoingRecruitmentTasks: number;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    creator?: { __typename?: 'User'; id: string; email: string } | null;
  };
};

export type JobApplicationProfileDrawerHeader_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    curriculumVitaeUrl?: string | null;
    linkedInProfileUrl?: string | null;
    phoneNumber?: string | null;
    email?: string | null;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
  }> | null;
};

export type JobApplicationProfileDrawerHiringManagerSection_JobApplicationFragment =
  {
    __typename?: 'JobApplication';
    id: string;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      creator?: {
        __typename: 'User';
        id: string;
        email: string;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
      } | null;
    };
    recruitmentTask: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
  };

export type JobApplicationProfileDrawerHistorySection_JobApplicationFragment = {
  __typename?: 'JobApplication';
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
    message?: {
      __typename?: 'Message';
      body?: string | null;
      creator: { __typename?: 'User'; firstName: string; lastName: string };
    } | null;
  }> | null;
};

export type JobApplicationProfileDrawerJobOfferSection_JobApplicationFragment =
  {
    __typename?: 'JobApplication';
    id: string;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      contractType?: string | null;
      isTerminated: boolean;
      status: JobOfferStatus;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
      } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    };
    recruitmentTask: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
  };

export type RecruiterJobApplicationProfileDrawer_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    curriculumVitaeUrl?: string | null;
    linkedInProfileUrl?: string | null;
    phoneNumber?: string | null;
    email?: string | null;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    salaryClaimAmount?: number | null;
    salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
    approximateFirstJobStartDate?: any | null;
    preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
    creator?: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
    } | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    academicBackgrounds: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }>;
    validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
    newJobCategories: Array<{
      __typename?: 'JobCategory';
      id: string;
      name: string;
    }>;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      degreeTitle?: string | null;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
    experiences: Array<{
      __typename?: 'Experience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
      skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
    }>;
    myNote?: { __typename?: 'ProfileNote'; body: string } | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
    message?: {
      __typename?: 'Message';
      body?: string | null;
      creator: { __typename?: 'User'; firstName: string; lastName: string };
    } | null;
  }> | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    contractType?: string | null;
    isTerminated: boolean;
    numberOfOngoingRecruitmentTasks: number;
    status: JobOfferStatus;
    creator?: {
      __typename: 'User';
      id: string;
      email: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
    } | null;
    employer?: {
      __typename?: 'Employer';
      id: string;
      name: string;
      logoUrl?: string | null;
    } | null;
    replacedJobApplication?: {
      __typename?: 'JobApplication';
      id: string;
    } | null;
  };
  recruitmentTask: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
  };
};

export type RecruiterJobApplicationProfileDrawerFooter_JobApplicationFragment =
  {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      creator?: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
      } | null;
    };
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      numberOfOngoingRecruitmentTasks: number;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      creator?: { __typename?: 'User'; id: string; email: string } | null;
    };
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
    }> | null;
  };

export type UseEmployerJobApplicationProfileDrawer_JobApplicationQueryVariables =
  Exact<{
    id: Scalars['String']['input'];
  }>;

export type UseEmployerJobApplicationProfileDrawer_JobApplicationQuery = {
  __typename?: 'Query';
  jobApplication: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      curriculumVitaeUrl?: string | null;
      linkedInProfileUrl?: string | null;
      phoneNumber?: string | null;
      email?: string | null;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      salaryClaimAmount?: number | null;
      salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
      approximateFirstJobStartDate?: any | null;
      preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
      creator?: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
        createdAt: any;
        phoneNumber: string;
        email: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
      validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
      educations: Array<{
        __typename?: 'Education';
        id: string;
        degreeTitle?: string | null;
        startedAt?: any | null;
        endedAt?: any | null;
        school: { __typename?: 'School'; id: string; name: string };
      }>;
      experiences: Array<{
        __typename?: 'Experience';
        id: string;
        jobTitle: string;
        startedAt?: any | null;
        endedAt?: any | null;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
        }> | null;
      }>;
    };
    recruitmentTask: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      message?: {
        __typename?: 'Message';
        body?: string | null;
        creator: { __typename?: 'User'; firstName: string; lastName: string };
      } | null;
    }> | null;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      contractType?: string | null;
      isTerminated: boolean;
      numberOfOngoingRecruitmentTasks: number;
      status: JobOfferStatus;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
      } | null;
      creator?: { __typename?: 'User'; id: string; email: string } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    };
  };
};

export type UseRecruiterJobApplicationProfileDrawer_JobApplicationQueryVariables =
  Exact<{
    id: Scalars['String']['input'];
  }>;

export type UseRecruiterJobApplicationProfileDrawer_JobApplicationQuery = {
  __typename?: 'Query';
  jobApplication: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      curriculumVitaeUrl?: string | null;
      linkedInProfileUrl?: string | null;
      phoneNumber?: string | null;
      email?: string | null;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      salaryClaimAmount?: number | null;
      salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
      approximateFirstJobStartDate?: any | null;
      preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
      creator?: {
        __typename?: 'User';
        firstName: string;
        lastName: string;
      } | null;
      urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
      academicBackgrounds: Array<{
        __typename?: 'AcademicBackground';
        id: string;
        name: string;
      }>;
      validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
      newJobCategories: Array<{
        __typename?: 'JobCategory';
        id: string;
        name: string;
      }>;
      educations: Array<{
        __typename?: 'Education';
        id: string;
        degreeTitle?: string | null;
        startedAt?: any | null;
        endedAt?: any | null;
        school: { __typename?: 'School'; id: string; name: string };
      }>;
      experiences: Array<{
        __typename?: 'Experience';
        id: string;
        jobTitle: string;
        startedAt?: any | null;
        endedAt?: any | null;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
        }> | null;
      }>;
      myNote?: { __typename?: 'ProfileNote'; body: string } | null;
    };
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      message?: {
        __typename?: 'Message';
        body?: string | null;
        creator: { __typename?: 'User'; firstName: string; lastName: string };
      } | null;
    }> | null;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      contractType?: string | null;
      isTerminated: boolean;
      numberOfOngoingRecruitmentTasks: number;
      status: JobOfferStatus;
      creator?: {
        __typename: 'User';
        id: string;
        email: string;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
      } | null;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
      } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    };
    recruitmentTask: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
  };
};

export type UseJobApplicationSubmissionForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  linkedInProfileUrl?: string | null;
  curriculumVitaeUrl?: string | null;
  approximateFirstJobStartDate?: any | null;
  salaryClaimAmount?: number | null;
  preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
  openToWorkUntil?: any | null;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
  currentEmployer?: {
    __typename?: 'Employer';
    id: string;
    name: string;
  } | null;
};

export type UseJobApplicationSubmissionForm_UpdateProfileMutationMutationVariables =
  Exact<{
    input: UpdateProfileInput;
  }>;

export type UseJobApplicationSubmissionForm_UpdateProfileMutationMutation = {
  __typename?: 'Mutation';
  updateProfile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber?: string | null;
    linkedInProfileUrl?: string | null;
    curriculumVitaeUrl?: string | null;
    approximateFirstJobStartDate?: any | null;
    salaryClaimAmount?: number | null;
    preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
    openToWorkUntil?: any | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    currentEmployer?: {
      __typename?: 'Employer';
      id: string;
      name: string;
    } | null;
  };
};

export type GetJobApplicationLastUpdateAlert_JobApplicationStatusFragment = {
  __typename?: 'JobApplicationStatus';
  id: string;
  name: JobApplicationStatusName;
  createdAt: any;
};

export type SortByStatus_JobApplicationFragment = {
  __typename?: 'JobApplication';
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    name: JobApplicationStatusName;
    createdAt: any;
  }> | null;
};

export type UseJobApplicationRecommendation_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  linkedInProfileUrl?: string | null;
  curriculumVitaeUrl?: string | null;
  profilePictureUrl?: string | null;
  createdAt: any;
};

export type UseJobApplicationRecommendation_EmployerFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
  logoUrl?: string | null;
  description?: string | null;
  nonPoachingAgreementsDescription?: string | null;
};

export type UseJobApplicationRecommendation_UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  myOneToOneChatChannelId?: string | null;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  activityMetric: {
    __typename?: 'UserActivityMetric';
    jobApplicationsResponseTime?: number | null;
    jobApplicationsResponseRate?: number | null;
  };
};

export type UseJobApplicationRecommendation_InternalManagerFragment = {
  __typename?: 'InternalManager';
  id: string;
  firstName: string;
  lastName: string;
  myOneToOneChatChannelId?: string | null;
};

export type UseJobApplicationRecommendation_SuccessFeeFragment = {
  __typename?: 'SuccessFee';
  id: string;
  basisPoints: number;
};

export type UseJobApplicationRecommendation_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  isTerminated: boolean;
  description: string;
  recruitmentProcess?: string | null;
  careerOutlook?: string | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  perksDescription?: string | null;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
  location?: string | null;
  contractType?: string | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  numberOfPositionsToFill: number;
  minNumberOfYearsOfExperience?: number | null;
  maxNumberOfYearsOfExperience?: number | null;
  mandatoryProfileAttributes?: string | null;
  profileDescription?: string | null;
  technicalSkillsDescription?: string | null;
  typicalProfileUrl?: string | null;
  recruitmentAdvice?: string | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  latestSuccessFee?: {
    __typename?: 'SuccessFee';
    id: string;
    basisPoints: number;
  } | null;
  creator?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    myOneToOneChatChannelId?: string | null;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
    activityMetric: {
      __typename?: 'UserActivityMetric';
      jobApplicationsResponseTime?: number | null;
      jobApplicationsResponseRate?: number | null;
    };
  } | null;
  internalManager?: {
    __typename?: 'InternalManager';
    id: string;
    firstName: string;
    lastName: string;
    myOneToOneChatChannelId?: string | null;
  } | null;
  employer?: {
    __typename?: 'Employer';
    id: string;
    name: string;
    logoUrl?: string | null;
    description?: string | null;
    nonPoachingAgreementsDescription?: string | null;
  } | null;
};

export type UseJobApplicationRecommendation_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
};

export type UseJobApplicationRecommendation_JobApplicationRecommendationFragment =
  {
    __typename?: 'JobApplicationRecommendation';
    id: string;
    createdAt: any;
    hasApplied: boolean;
    score?: number | null;
    status: JobApplicationRecommendationStatus;
    recruiterSuccessFeeInBasisPoints?: number | null;
    recruiterFlatFeeInBasisPoints?: number | null;
    jobOfferMatchingCriteria?: Array<{
      __typename?: 'JobOfferMatchingCriterion';
      name: string;
      isMatching: boolean;
    }> | null;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      isTerminated: boolean;
      description: string;
      recruitmentProcess?: string | null;
      careerOutlook?: string | null;
      hasIncentiveBonus?: boolean | null;
      hasLuncheonVouchers?: boolean | null;
      hasHealthInsurancePlan?: boolean | null;
      perksDescription?: string | null;
      briefingVideoUrl?: string | null;
      videoAskUrl?: string | null;
      location?: string | null;
      contractType?: string | null;
      minimumRemuneration?: number | null;
      maximumRemuneration?: number | null;
      maximumVariableRemuneration?: number | null;
      currencyCode?: CurrencyIso4217Code | null;
      numberOfPositionsToFill: number;
      minNumberOfYearsOfExperience?: number | null;
      maxNumberOfYearsOfExperience?: number | null;
      mandatoryProfileAttributes?: string | null;
      profileDescription?: string | null;
      technicalSkillsDescription?: string | null;
      typicalProfileUrl?: string | null;
      recruitmentAdvice?: string | null;
      remoteWorkPolicy?: RemoteWorkPolicy | null;
      status: JobOfferStatus;
      motivationalMessageToRecruiters?: string | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
          profilePictureUrl?: string | null;
          linkedInProfileUrl?: string | null;
          curriculumVitaeUrl?: string | null;
        };
      } | null;
      latestSuccessFee?: {
        __typename?: 'SuccessFee';
        id: string;
        basisPoints: number;
      } | null;
      creator?: {
        __typename: 'User';
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        id: string;
        phoneNumber: string;
        email: string;
        myOneToOneChatChannelId?: string | null;
        employer?: { __typename?: 'Employer'; name: string; id: string } | null;
        activityMetric: {
          __typename?: 'UserActivityMetric';
          jobApplicationsResponseTime?: number | null;
          jobApplicationsResponseRate?: number | null;
        };
      } | null;
      internalManager?: {
        __typename: 'InternalManager';
        id: string;
        firstName: string;
        lastName: string;
        myOneToOneChatChannelId?: string | null;
        phoneNumber: string;
        email: string;
        profilePictureUrl?: string | null;
      } | null;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
        description?: string | null;
        nonPoachingAgreementsDescription?: string | null;
      } | null;
      academicBackgrounds?: Array<{
        __typename?: 'AcademicBackground';
        id: string;
        name: string;
      }> | null;
    };
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      email?: string | null;
      phoneNumber?: string | null;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
      profilePictureUrl?: string | null;
      createdAt: any;
    };
    recruitmentTask?: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    } | null;
  };

export type UseJobApplicationRecommendation_JobApplicationRecommendationQueryQueryVariables =
  Exact<{
    input: GetJobApplicationRecommendationInput;
  }>;

export type UseJobApplicationRecommendation_JobApplicationRecommendationQueryQuery =
  {
    __typename?: 'Query';
    jobApplicationRecommendation: {
      __typename?: 'JobApplicationRecommendation';
      id: string;
      createdAt: any;
      hasApplied: boolean;
      score?: number | null;
      status: JobApplicationRecommendationStatus;
      recruiterSuccessFeeInBasisPoints?: number | null;
      recruiterFlatFeeInBasisPoints?: number | null;
      jobOfferMatchingCriteria?: Array<{
        __typename?: 'JobOfferMatchingCriterion';
        name: string;
        isMatching: boolean;
      }> | null;
      jobOffer: {
        __typename?: 'JobOffer';
        id: string;
        title: string;
        isTerminated: boolean;
        description: string;
        recruitmentProcess?: string | null;
        careerOutlook?: string | null;
        hasIncentiveBonus?: boolean | null;
        hasLuncheonVouchers?: boolean | null;
        hasHealthInsurancePlan?: boolean | null;
        perksDescription?: string | null;
        briefingVideoUrl?: string | null;
        videoAskUrl?: string | null;
        location?: string | null;
        contractType?: string | null;
        minimumRemuneration?: number | null;
        maximumRemuneration?: number | null;
        maximumVariableRemuneration?: number | null;
        currencyCode?: CurrencyIso4217Code | null;
        numberOfPositionsToFill: number;
        minNumberOfYearsOfExperience?: number | null;
        maxNumberOfYearsOfExperience?: number | null;
        mandatoryProfileAttributes?: string | null;
        profileDescription?: string | null;
        technicalSkillsDescription?: string | null;
        typicalProfileUrl?: string | null;
        recruitmentAdvice?: string | null;
        remoteWorkPolicy?: RemoteWorkPolicy | null;
        status: JobOfferStatus;
        motivationalMessageToRecruiters?: string | null;
        replacedJobApplication?: {
          __typename?: 'JobApplication';
          id: string;
          profile: {
            __typename?: 'Profile';
            id: string;
            firstName: string;
            lastName: string;
            profilePictureUrl?: string | null;
            linkedInProfileUrl?: string | null;
            curriculumVitaeUrl?: string | null;
          };
        } | null;
        latestSuccessFee?: {
          __typename?: 'SuccessFee';
          id: string;
          basisPoints: number;
        } | null;
        creator?: {
          __typename: 'User';
          firstName: string;
          lastName: string;
          profilePictureUrl?: string | null;
          id: string;
          phoneNumber: string;
          email: string;
          myOneToOneChatChannelId?: string | null;
          employer?: {
            __typename?: 'Employer';
            name: string;
            id: string;
          } | null;
          activityMetric: {
            __typename?: 'UserActivityMetric';
            jobApplicationsResponseTime?: number | null;
            jobApplicationsResponseRate?: number | null;
          };
        } | null;
        internalManager?: {
          __typename: 'InternalManager';
          id: string;
          firstName: string;
          lastName: string;
          myOneToOneChatChannelId?: string | null;
          phoneNumber: string;
          email: string;
          profilePictureUrl?: string | null;
        } | null;
        employer?: {
          __typename?: 'Employer';
          id: string;
          name: string;
          logoUrl?: string | null;
          description?: string | null;
          nonPoachingAgreementsDescription?: string | null;
        } | null;
        academicBackgrounds?: Array<{
          __typename?: 'AcademicBackground';
          id: string;
          name: string;
        }> | null;
      };
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
        email?: string | null;
        phoneNumber?: string | null;
        linkedInProfileUrl?: string | null;
        curriculumVitaeUrl?: string | null;
        profilePictureUrl?: string | null;
        createdAt: any;
      };
      recruitmentTask?: {
        __typename?: 'RecruitmentTask';
        id: string;
        status: RecruitmentTaskStatus;
      } | null;
    };
  };

export type UseJobApplicationRecommendationAcceptationModal_TakeRecruitmentTaskViaJobApplicationRecommendationMutationMutationVariables =
  Exact<{
    input: TakeRecruitmentTaskViaJobApplicationRecommendationInput;
  }>;

export type UseJobApplicationRecommendationAcceptationModal_TakeRecruitmentTaskViaJobApplicationRecommendationMutationMutation =
  {
    __typename?: 'Mutation';
    takeRecruitmentTaskViaJobApplicationRecommendation: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
  };

export type EmployerJobOfferJobApplicationEditableStatusTag_JobApplicationFragment =
  {
    __typename?: 'JobApplication';
    id: string;
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
    }> | null;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      creator?: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    };
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      numberOfOngoingRecruitmentTasks: number;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      creator?: { __typename?: 'User'; id: string; email: string } | null;
    };
  };

export type AfterHiringJobOfferTerminationModal_JobApplicationFragment = {
  __typename?: 'JobApplication';
  jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
};

export type UseAfterHiringJobOfferTerminationModal_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
};

export type TerminateJobOfferMutationVariables = Exact<{
  input: TerminateJobOfferInput;
}>;

export type TerminateJobOfferMutation = {
  __typename?: 'Mutation';
  terminateJobOffer: {
    __typename?: 'JobOffer';
    id: string;
    isTerminated: boolean;
  };
};

export type AskForFirstInterviewWithTemplateModal_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  };
};

export type UseAskForFirstInterviewWithTemplateModal_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  };
};

export type AskForFirstInterviewWithoutTemplateModal_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: { __typename?: 'Profile'; firstName: string; lastName: string };
};

export type HiringConfirmationModal_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
};

export type DeclareApplicantHiredByMyCompanyMutationVariables = Exact<{
  input: DeclareApplicantHiredByMyCompanyInput;
}>;

export type DeclareApplicantHiredByMyCompanyMutation = {
  __typename?: 'Mutation';
  declareApplicantHiredByMyCompany: {
    __typename?: 'JobApplication';
    id: string;
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      createdAt: any;
      name: JobApplicationStatusName;
      message?: {
        __typename?: 'Message';
        id: string;
        body?: string | null;
        createdAt: any;
        creator: {
          __typename?: 'User';
          id: string;
          firstName: string;
          lastName: string;
        };
      } | null;
    }> | null;
  };
};

export type JobApplicationRejectionModal_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    firstName: string;
    lastName: string;
    creator?: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
    } | null;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    numberOfOngoingRecruitmentTasks: number;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    creator?: { __typename?: 'User'; id: string; email: string } | null;
  };
};

export type UseJobApplicationRejectionForm_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
    creator?: { __typename?: 'User'; id: string; email: string } | null;
  };
};

export type RejectJobApplicationMutationVariables = Exact<{
  input: RejectJobApplicationInput;
}>;

export type RejectJobApplicationMutation = {
  __typename?: 'Mutation';
  rejectJobApplication: {
    __typename?: 'JobApplication';
    id: string;
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      message?: {
        __typename?: 'Message';
        id: string;
        body?: string | null;
        createdAt: any;
        creator: {
          __typename?: 'User';
          id: string;
          firstName: string;
          lastName: string;
        };
      } | null;
    }> | null;
  };
};

export type UseJobApplicationRejectionForm_PostJobOfferNewsMutationVariables =
  Exact<{
    input: PostJobOfferNewsInput;
  }>;

export type UseJobApplicationRejectionForm_PostJobOfferNewsMutation = {
  __typename?: 'Mutation';
  postJobOfferNews: { __typename?: 'News'; id: string; body: string };
};

export type StartApplicantRecruitmentProcessModal_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  };
};

export type JobApplicationStatusFragment = {
  __typename?: 'JobApplicationStatus';
  id: string;
  createdAt: any;
  name: JobApplicationStatusName;
  message?: {
    __typename?: 'Message';
    id: string;
    body?: string | null;
    createdAt: any;
    creator: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    };
  } | null;
};

export type JobApplicationStatusMessageFragment = {
  __typename?: 'Message';
  id: string;
  body?: string | null;
  createdAt: any;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type JobCategories_UseAutoCompleteJobCategoryOptionsQueryVariables =
  Exact<{
    filters?: InputMaybe<JobCategoriesFilters>;
  }>;

export type JobCategories_UseAutoCompleteJobCategoryOptionsQuery = {
  __typename?: 'Query';
  jobCategories: Array<{
    __typename?: 'JobCategory';
    id: string;
    name: string;
  }>;
};

export type JobCategories_UseJobCategoryOptionsQueryVariables = Exact<{
  filters?: InputMaybe<JobCategoriesFilters>;
}>;

export type JobCategories_UseJobCategoryOptionsQuery = {
  __typename?: 'Query';
  jobCategories: Array<{
    __typename?: 'JobCategory';
    id: string;
    name: string;
  }>;
};

export type JobOfferBody_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  description: string;
  profileDescription?: string | null;
  mandatoryProfileAttributes?: string | null;
  technicalSkillsDescription?: string | null;
  typicalProfileUrl?: string | null;
  careerOutlook?: string | null;
  recruitmentProcess?: string | null;
  perksDescription?: string | null;
  recruitmentAdvice?: string | null;
  motivationalMessageToRecruiters?: string | null;
  location?: string | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  numberOfPositionsToFill: number;
  minNumberOfYearsOfExperience?: number | null;
  maxNumberOfYearsOfExperience?: number | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  employer?: {
    __typename?: 'Employer';
    id: string;
    description?: string | null;
    nonPoachingAgreementsDescription?: string | null;
  } | null;
  creator?: {
    __typename?: 'User';
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  } | null;
  replacedJobApplication?: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
    };
  } | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
};

export type JobOfferBodyMotivationalSection_JobOfferFragment = {
  __typename?: 'JobOffer';
  motivationalMessageToRecruiters?: string | null;
  creator?: {
    __typename?: 'User';
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  } | null;
};

export type JobOfferBodyPerksSection_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
};

export type JobOfferBodyReplacementSection_JobOfferFragment = {
  __typename?: 'JobOffer';
  replacedJobApplication?: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
    };
  } | null;
};

export type JobOfferBodySummarySection_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  location?: string | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  numberOfPositionsToFill: number;
  minNumberOfYearsOfExperience?: number | null;
  maxNumberOfYearsOfExperience?: number | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
};

export type JobOfferBodyVideoBriefSection_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
};

export type UseJobOfferClassificationFromTextForm_JobOfferLeadFragment = {
  __typename?: 'JobOfferLead';
  id: string;
  title: string;
  description?: string | null;
  location?: string | null;
  jobBoard: string;
  jobBoardUrl: string;
};

export type UseJobOfferClassificationFromTextForm_EmployerJobOfferLeadQueryVariables =
  Exact<{
    jobOfferLeadId: Scalars['String']['input'];
  }>;

export type UseJobOfferClassificationFromTextForm_EmployerJobOfferLeadQuery = {
  __typename?: 'Query';
  employerJobOfferLead: {
    __typename?: 'JobOfferLead';
    id: string;
    title: string;
    description?: string | null;
    location?: string | null;
    jobBoard: string;
    jobBoardUrl: string;
  };
};

export type UseJobOfferClassificationFromTextForm_ClassifyAndCreateJobOfferMutationVariables =
  Exact<{
    input: ClassifyAndCreateJobOfferInput;
  }>;

export type UseJobOfferClassificationFromTextForm_ClassifyAndCreateJobOfferMutation =
  {
    __typename?: 'Mutation';
    classifyAndCreateJobOffer: {
      __typename?: 'ClassificationJob';
      jobId: string;
    };
  };

export type JobOfferContact_UserFragment = {
  __typename: 'User';
  id: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  myOneToOneChatChannelId?: string | null;
};

export type JobOfferContact_InternalManagerFragment = {
  __typename: 'InternalManager';
  id: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  myOneToOneChatChannelId?: string | null;
};

export type JobOfferContacts_JobOfferFragment = {
  __typename?: 'JobOffer';
  creator?: {
    __typename: 'User';
    id: string;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
    activityMetric: {
      __typename?: 'UserActivityMetric';
      jobApplicationsResponseTime?: number | null;
      jobApplicationsResponseRate?: number | null;
    };
  } | null;
  internalManager?: {
    __typename: 'InternalManager';
    id: string;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
  } | null;
};

export type JobOfferHeader_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  contractType?: string | null;
  isTerminated: boolean;
  status: JobOfferStatus;
  employer?: {
    __typename?: 'Employer';
    id: string;
    name: string;
    logoUrl?: string | null;
  } | null;
  replacedJobApplication?: { __typename?: 'JobApplication'; id: string } | null;
};

export type JobOfferHeaderContainer_JobOfferFragment = {
  __typename?: 'JobOffer';
  isTerminated: boolean;
  replacedJobApplication?: { __typename?: 'JobApplication'; id: string } | null;
};

export type JobOfferMinimalFormAcademicBackgroundStep_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
};

export type JobOfferMinimalFormLocationStep_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  location?: string | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
};

export type JobOfferMinimalFormProfileStep_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  minNumberOfYearsOfExperience?: number | null;
  jobCategory?: { __typename?: 'JobCategory'; id: string; name: string } | null;
};

export type JobOfferMinimalFormSalaryStep_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
};

export type JobOfferMinimalFormSuccessFeeStep_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  latestSuccessFee?: { __typename?: 'SuccessFee'; basisPoints: number } | null;
};

export type VideoBriefExpertRecordingClickableCard_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  videoAskUrl?: string | null;
  videoBriefMeeting?: {
    __typename?: 'VideoBriefMeeting';
    id: string;
    startDate: any;
  } | null;
};

export type VideoBriefSoloRecordingClickableCard_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  videoAskUrl?: string | null;
  videoBriefMeeting?: {
    __typename?: 'VideoBriefMeeting';
    id: string;
    startDate: any;
  } | null;
};

export type UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferQueryVariables =
  Exact<{
    id: Scalars['String']['input'];
  }>;

export type UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferQuery = {
  __typename?: 'Query';
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    videoAskUrl?: string | null;
  };
};

export type UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  videoAskUrl?: string | null;
};

export type JobOfferMinimalFormVideoBriefStep_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  videoAskUrl?: string | null;
  videoBriefMeeting?: {
    __typename?: 'VideoBriefMeeting';
    id: string;
    startDate: any;
  } | null;
};

export type UseJobOfferMinimalForm_JobOfferQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UseJobOfferMinimalForm_JobOfferQuery = {
  __typename?: 'Query';
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    videoAskUrl?: string | null;
    minNumberOfYearsOfExperience?: number | null;
    location?: string | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    maximumVariableRemuneration?: number | null;
    currencyCode?: CurrencyIso4217Code | null;
    videoBriefMeeting?: {
      __typename?: 'VideoBriefMeeting';
      id: string;
      startDate: any;
    } | null;
    jobCategory?: {
      __typename?: 'JobCategory';
      id: string;
      name: string;
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }> | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      basisPoints: number;
    } | null;
  };
};

export type UseJobOfferMinimalForm_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  videoAskUrl?: string | null;
  minNumberOfYearsOfExperience?: number | null;
  location?: string | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  videoBriefMeeting?: {
    __typename?: 'VideoBriefMeeting';
    id: string;
    startDate: any;
  } | null;
  jobCategory?: { __typename?: 'JobCategory'; id: string; name: string } | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
  latestSuccessFee?: { __typename?: 'SuccessFee'; basisPoints: number } | null;
};

export type JobOfferMinimalForm_UpdateJobOfferMutationVariables = Exact<{
  input: UpdateJobOfferInput;
}>;

export type JobOfferMinimalForm_UpdateJobOfferMutation = {
  __typename?: 'Mutation';
  updateJobOffer: {
    __typename?: 'JobOffer';
    id: string;
    videoAskUrl?: string | null;
    minNumberOfYearsOfExperience?: number | null;
    location?: string | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    maximumVariableRemuneration?: number | null;
    currencyCode?: CurrencyIso4217Code | null;
    videoBriefMeeting?: {
      __typename?: 'VideoBriefMeeting';
      id: string;
      startDate: any;
    } | null;
    jobCategory?: {
      __typename?: 'JobCategory';
      id: string;
      name: string;
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }> | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      basisPoints: number;
    } | null;
  };
};

export type UseJobOfferMinimalFormHandleStepSubmit_CompleteJobOfferCreationFormMutationVariables =
  Exact<{
    input: CompleteJobOfferCreationFormInput;
  }>;

export type UseJobOfferMinimalFormHandleStepSubmit_CompleteJobOfferCreationFormMutation =
  {
    __typename?: 'Mutation';
    completeJobOfferCreationForm: { __typename?: 'JobOffer'; id: string };
  };

export type UseJobOfferMinimalFormHandleStepSubmit_ExpressInterestInProfilesMutationVariables =
  Exact<{
    input: ExpressInterestInProfilesInput;
  }>;

export type UseJobOfferMinimalFormHandleStepSubmit_ExpressInterestInProfilesMutation =
  {
    __typename?: 'Mutation';
    expressInterestInProfiles: { __typename?: 'JobOffer'; id: string };
  };

export type JobOfferNewsFeed_NewsFragment = {
  __typename?: 'News';
  id: string;
  createdAt: any;
  body: string;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePictureUrl?: string | null;
  };
};

export type JobOfferPackageTableCell_JobOfferFragment = {
  __typename?: 'JobOffer';
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  maximumVariableRemuneration?: number | null;
};

export type JobOfferStatusTag_JobOfferFragment = {
  __typename?: 'JobOffer';
  status: JobOfferStatus;
  isTerminated: boolean;
};

export type UseJobOfferUpdateForm_JobOfferQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UseJobOfferUpdateForm_JobOfferQuery = {
  __typename?: 'Query';
  jobOffer: {
    __typename?: 'JobOffer';
    title: string;
    description: string;
    profileDescription?: string | null;
    recruitmentProcess?: string | null;
    numberOfPositionsToFill: number;
    location?: string | null;
    contractType?: string | null;
    currencyCode?: CurrencyIso4217Code | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    minNumberOfYearsOfExperience?: number | null;
    maxNumberOfYearsOfExperience?: number | null;
    maximumVariableRemuneration?: number | null;
    careerOutlook?: string | null;
    mandatoryProfileAttributes?: string | null;
    technicalSkillsDescription?: string | null;
    perksDescription?: string | null;
    typicalProfileUrl?: string | null;
    hasIncentiveBonus?: boolean | null;
    hasLuncheonVouchers?: boolean | null;
    hasHealthInsurancePlan?: boolean | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    motivationalMessageToRecruiters?: string | null;
    status: JobOfferStatus;
    newJobCategory?: {
      __typename?: 'JobCategory';
      id: string;
      name: string;
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
    }> | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      id: string;
      basisPoints: number;
    } | null;
  };
};

export type UseJobOfferUpdateForm_UpdateJobOfferMutationVariables = Exact<{
  input: UpdateJobOfferInput;
}>;

export type UseJobOfferUpdateForm_UpdateJobOfferMutation = {
  __typename?: 'Mutation';
  updateJobOffer: {
    __typename?: 'JobOffer';
    title: string;
    description: string;
    profileDescription?: string | null;
    recruitmentProcess?: string | null;
    numberOfPositionsToFill: number;
    location?: string | null;
    contractType?: string | null;
    currencyCode?: CurrencyIso4217Code | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    minNumberOfYearsOfExperience?: number | null;
    maxNumberOfYearsOfExperience?: number | null;
    maximumVariableRemuneration?: number | null;
    careerOutlook?: string | null;
    mandatoryProfileAttributes?: string | null;
    technicalSkillsDescription?: string | null;
    perksDescription?: string | null;
    typicalProfileUrl?: string | null;
    hasIncentiveBonus?: boolean | null;
    hasLuncheonVouchers?: boolean | null;
    hasHealthInsurancePlan?: boolean | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    motivationalMessageToRecruiters?: string | null;
    status: JobOfferStatus;
    newJobCategory?: {
      __typename?: 'JobCategory';
      id: string;
      name: string;
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
    }> | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      id: string;
      basisPoints: number;
    } | null;
  };
};

export type UseJobOfferUpdateForm_JobOfferFragment = {
  __typename?: 'JobOffer';
  title: string;
  description: string;
  profileDescription?: string | null;
  recruitmentProcess?: string | null;
  numberOfPositionsToFill: number;
  location?: string | null;
  contractType?: string | null;
  currencyCode?: CurrencyIso4217Code | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  minNumberOfYearsOfExperience?: number | null;
  maxNumberOfYearsOfExperience?: number | null;
  maximumVariableRemuneration?: number | null;
  careerOutlook?: string | null;
  mandatoryProfileAttributes?: string | null;
  technicalSkillsDescription?: string | null;
  perksDescription?: string | null;
  typicalProfileUrl?: string | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  motivationalMessageToRecruiters?: string | null;
  status: JobOfferStatus;
  newJobCategory?: {
    __typename?: 'JobCategory';
    id: string;
    name: string;
  } | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
  }> | null;
  latestSuccessFee?: {
    __typename?: 'SuccessFee';
    id: string;
    basisPoints: number;
  } | null;
};

export type JobOfferWizardForm_JobOfferFragment = {
  __typename?: 'JobOffer';
  status: JobOfferStatus;
};

export type JobOfferWizardFormSalaryStep_JobOfferFragment = {
  __typename?: 'JobOffer';
  status: JobOfferStatus;
};

export type UseJobOfferNewsFeed_NewsFragment = {
  __typename?: 'News';
  id: string;
  createdAt: any;
  body: string;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePictureUrl?: string | null;
  };
};

export type UseJobOfferNewsFeed_PostJobOfferNewsMutationVariables = Exact<{
  input: PostJobOfferNewsInput;
}>;

export type UseJobOfferNewsFeed_PostJobOfferNewsMutation = {
  __typename?: 'Mutation';
  postJobOfferNews: {
    __typename?: 'News';
    id: string;
    createdAt: any;
    body: string;
    creator: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      profilePictureUrl?: string | null;
    };
  };
};

export type UseJobOfferNewsFeed_JobOfferNewsFeedQueryVariables = Exact<{
  input: GetJobOfferNewsFeedInput;
}>;

export type UseJobOfferNewsFeed_JobOfferNewsFeedQuery = {
  __typename?: 'Query';
  jobOfferNewsFeed: {
    __typename?: 'NewsOffsetBasedConnection';
    hasNextPage: boolean;
    totalCount: number;
    nodes: Array<{
      __typename?: 'News';
      id: string;
      createdAt: any;
      body: string;
      creator: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        profilePictureUrl?: string | null;
      };
    }>;
  };
};

export type UseJobOfferUploadVideoBrief_UpdateJobOfferMutationVariables =
  Exact<{
    input: UpdateJobOfferInput;
  }>;

export type UseJobOfferUploadVideoBrief_UpdateJobOfferMutation = {
  __typename?: 'Mutation';
  updateJobOffer: {
    __typename?: 'JobOffer';
    id: string;
    description: string;
    profileDescription?: string | null;
    mandatoryProfileAttributes?: string | null;
    technicalSkillsDescription?: string | null;
    typicalProfileUrl?: string | null;
    careerOutlook?: string | null;
    recruitmentProcess?: string | null;
    perksDescription?: string | null;
    recruitmentAdvice?: string | null;
    motivationalMessageToRecruiters?: string | null;
    location?: string | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    maximumVariableRemuneration?: number | null;
    currencyCode?: CurrencyIso4217Code | null;
    numberOfPositionsToFill: number;
    minNumberOfYearsOfExperience?: number | null;
    maxNumberOfYearsOfExperience?: number | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    briefingVideoUrl?: string | null;
    videoAskUrl?: string | null;
    hasIncentiveBonus?: boolean | null;
    hasLuncheonVouchers?: boolean | null;
    hasHealthInsurancePlan?: boolean | null;
    employer?: {
      __typename?: 'Employer';
      id: string;
      description?: string | null;
      nonPoachingAgreementsDescription?: string | null;
    } | null;
    creator?: {
      __typename: 'User';
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      id: string;
      phoneNumber: string;
      email: string;
      myOneToOneChatChannelId?: string | null;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      activityMetric: {
        __typename?: 'UserActivityMetric';
        jobApplicationsResponseTime?: number | null;
        jobApplicationsResponseRate?: number | null;
      };
    } | null;
    internalManager?: {
      __typename: 'InternalManager';
      id: string;
      phoneNumber: string;
      email: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
    } | null;
    replacedJobApplication?: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        linkedInProfileUrl?: string | null;
        curriculumVitaeUrl?: string | null;
      };
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }> | null;
  };
};

export type CheckIfJobOfferBriefIsRecorded_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
};

export type MyUserQueryVariables = Exact<{ [key: string]: never }>;

export type MyUserQuery = {
  __typename?: 'Query';
  myUser?:
    | {
        __typename: 'User';
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        profilePictureUrl?: string | null;
        mainBusinessType: BusinessType;
        termsOfServiceVersion?: number | null;
        id: string;
        isAuthorizedToUseMarketplace: boolean;
        permissions: Array<{ __typename?: 'Permission'; name: PermissionName }>;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
        searchFirm?: {
          __typename?: 'SearchFirm';
          id: string;
          createdAt: any;
          name: string;
          subscriptions: Array<{
            __typename?: 'Subscription';
            id: string;
            paymentServiceProviderName: PaymentServiceProviderName;
            status: SubscriptionStatus;
          }>;
          contracts?: Array<{
            __typename?: 'Contract';
            id: string;
            statuses?: Array<{
              __typename?: 'ContractStatus';
              id: string;
              name: ContractStatusName;
            }> | null;
          }> | null;
        } | null;
        employer?: { __typename?: 'Employer'; id: string; name: string } | null;
      }
    | {
        __typename: 'UserLead';
        id: string;
        email: string;
        mainBusinessType: BusinessType;
      }
    | null;
};

export type MyUserProvider_UserLeadFragment = {
  __typename?: 'UserLead';
  id: string;
  email: string;
  mainBusinessType: BusinessType;
};

export type ProfileDrawerAcademicBackgroundsSection_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  academicBackgrounds: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }>;
};

export type ProfileDrawerEducationsSection_ProfileFragment = {
  __typename?: 'Profile';
  educations: Array<{
    __typename?: 'Education';
    id: string;
    degreeTitle?: string | null;
    startedAt?: any | null;
    endedAt?: any | null;
    school: { __typename?: 'School'; id: string; name: string };
  }>;
};

export type ProfileDrawerEducationsSection_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  educations: Array<{
    __typename?: 'Education';
    id: string;
    degreeTitle?: string | null;
    startedAt?: any | null;
    endedAt?: any | null;
    school: { __typename?: 'School'; id: string; name: string };
  }>;
};

export type ProfileDrawerExperiencesSection_ProfileFragment = {
  __typename?: 'Profile';
  experiences: Array<{
    __typename?: 'Experience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
  }>;
};

export type ProfileDrawerExperiencesSection_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  experiences: Array<{
    __typename?: 'AnonymizedExperience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
  }>;
};

export type ProfileDrawerInfoSection_ProfileFragment = {
  __typename?: 'Profile';
  salaryClaimAmount?: number | null;
  salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
  approximateFirstJobStartDate?: any | null;
  preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
};

export type ProfileDrawerInfoSection_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  salaryClaimAmount?: number | null;
  salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
  approximateFirstJobStartDate?: any | null;
  preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
};

export type ProfileDrawerJobCategoriesSection_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  newJobCategories: Array<{
    __typename?: 'JobCategory';
    id: string;
    name: string;
  }>;
};

export type ProfileDrawerPersonalNoteSection_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  myNote?: { __typename?: 'ProfileNote'; body: string } | null;
};

export type ProfileDrawerRecruiterSection_ProfileFragment = {
  __typename?: 'Profile';
  creator?: {
    __typename: 'User';
    id: string;
    createdAt: any;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  } | null;
};

export type ProfileDrawerRecruiterSection_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  creator?: {
    __typename: 'User';
    id: string;
    createdAt: any;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  } | null;
};

export type ProfileDrawerRecruiterSection_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
};

export type ProfileDrawerSkillsSection_ProfileFragment = {
  __typename?: 'Profile';
  validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
};

export type ProfileDrawerSkillsSection_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
};

export type ProfileHeader_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  linkedInProfileUrl?: string | null;
  curriculumVitaeUrl?: string | null;
  profilePictureUrl?: string | null;
};

export type ProfileEducation_EducationFragment = {
  __typename?: 'Education';
  id: string;
  degreeTitle?: string | null;
  startedAt?: any | null;
  endedAt?: any | null;
  school: { __typename?: 'School'; id: string; name: string };
};

export type ProfileEducationsForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  educations: Array<{
    __typename?: 'Education';
    id: string;
    degreeTitle?: string | null;
    startedAt?: any | null;
    endedAt?: any | null;
    school: { __typename?: 'School'; id: string; name: string };
  }>;
};

export type ProfileExperience_ExperienceFragment = {
  __typename?: 'Experience';
  id: string;
  jobTitle: string;
  startedAt?: any | null;
  endedAt?: any | null;
  employer: { __typename?: 'ExperienceEmployer'; name: string };
  skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
};

export type ProfileExperience_AnonymizedExperienceFragment = {
  __typename?: 'AnonymizedExperience';
  id: string;
  jobTitle: string;
  startedAt?: any | null;
  endedAt?: any | null;
  employer: { __typename?: 'ExperienceEmployer'; name: string };
  skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
};

export type ProfileExperiences_ExperienceFragment = {
  __typename?: 'Experience';
  id: string;
  jobTitle: string;
  startedAt?: any | null;
  endedAt?: any | null;
  employer: { __typename?: 'ExperienceEmployer'; name: string };
  skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
};

export type ProfileExperiences_AnonymizedExperienceFragment = {
  __typename?: 'AnonymizedExperience';
  id: string;
  jobTitle: string;
  startedAt?: any | null;
  endedAt?: any | null;
  employer: { __typename?: 'ExperienceEmployer'; name: string };
  skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
};

export type ProfileExperiencesForm_ExperienceFragment = {
  __typename?: 'Experience';
  id: string;
  jobTitle: string;
  startedAt?: any | null;
  endedAt?: any | null;
  employer: { __typename?: 'ExperienceEmployer'; name: string };
};

export type ProfileExperiencesForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  linkedInProfileUrl?: string | null;
  experiences: Array<{
    __typename?: 'Experience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
  }>;
};

export type ProfileInfo_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  linkedInProfileUrl?: string | null;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  curriculumVitaeUrl?: string | null;
  profilePictureUrl?: string | null;
  approximateFirstJobStartDate?: any | null;
  openToWorkUntil?: any | null;
  salaryClaimAmount?: number | null;
  preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
  myNote?: {
    __typename?: 'ProfileNote';
    id: string;
    createdAt: any;
    updatedAt: any;
    body: string;
  } | null;
  educations: Array<{
    __typename?: 'Education';
    id: string;
    degreeTitle?: string | null;
    startedAt?: any | null;
    endedAt?: any | null;
    school: { __typename?: 'School'; id: string; name: string };
  }>;
  experiences: Array<{
    __typename?: 'Experience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
  }>;
  newJobCategories: Array<{
    __typename?: 'JobCategory';
    id: string;
    name: string;
  }>;
  academicBackgrounds: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }>;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
  currentEmployer?: {
    __typename?: 'Employer';
    name: string;
    id: string;
  } | null;
};

export type UpdateProfileCareerAutoSubmitForm_ProfileFragment = {
  __typename?: 'Profile';
  lastName: string;
  firstName: string;
  curriculumVitaeUrl?: string | null;
  openToWorkUntil?: any | null;
  id: string;
  email?: string | null;
  phoneNumber?: string | null;
  linkedInProfileUrl?: string | null;
  approximateFirstJobStartDate?: any | null;
  salaryClaimAmount?: number | null;
  preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
  currentEmployer?: {
    __typename?: 'Employer';
    id: string;
    name: string;
  } | null;
};

export type UseProfileAcademicBackgroundsAutoSubmitForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  academicBackgrounds: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }>;
};

export type UseProfileIsOpenToWorkAutoSubmitForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  openToWorkUntil?: any | null;
};

export type MarkProfileAsOpenToWorkMutationVariables = Exact<{
  input: MarkProfileAsOpenToWorkInput;
}>;

export type MarkProfileAsOpenToWorkMutation = {
  __typename?: 'Mutation';
  markProfileAsOpenToWork: {
    __typename?: 'Profile';
    id: string;
    openToWorkUntil?: any | null;
  };
};

export type MarkProfileAsNotOpenToWorkMutationVariables = Exact<{
  input: MarkProfileAsNotOpenToWorkInput;
}>;

export type MarkProfileAsNotOpenToWorkMutation = {
  __typename?: 'Mutation';
  markProfileAsNotOpenToWork: {
    __typename?: 'Profile';
    id: string;
    openToWorkUntil?: any | null;
  };
};

export type UseProfileJobCategoriesAutoSubmitForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  newJobCategories: Array<{
    __typename?: 'JobCategory';
    id: string;
    name: string;
  }>;
};

export type UpdateProfileJobCategoriesMutationVariables = Exact<{
  input: UpdateProfileJobCategoriesInput;
}>;

export type UpdateProfileJobCategoriesMutation = {
  __typename?: 'Mutation';
  updateProfileJobCategories: {
    __typename?: 'Profile';
    id: string;
    newJobCategories: Array<{
      __typename?: 'JobCategory';
      id: string;
      name: string;
    }>;
  };
};

export type UseProfileSkillsAutoSubmitForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
};

export type UseProfileSkillsAutoSubmitForm_AddProfilePotentialSkillMutationMutationVariables =
  Exact<{
    input: AddProfilePotentialSkillInput;
  }>;

export type UseProfileSkillsAutoSubmitForm_AddProfilePotentialSkillMutationMutation =
  {
    __typename?: 'Mutation';
    addProfilePotentialSkill: {
      __typename?: 'Profile';
      id: string;
      skills: Array<{
        __typename?: 'Skill';
        id: string;
        name: string;
        status: SkillStatus;
      }>;
    };
  };

export type UseUpdateProfileAutoSubmitForm_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  linkedInProfileUrl?: string | null;
  curriculumVitaeUrl?: string | null;
  approximateFirstJobStartDate?: any | null;
  salaryClaimAmount?: number | null;
  preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
  openToWorkUntil?: any | null;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
  currentEmployer?: {
    __typename?: 'Employer';
    id: string;
    name: string;
  } | null;
};

export type UseUpdateProfileAutoSubmitForm_UpdateProfileMutationMutationVariables =
  Exact<{
    input: UpdateProfileInput;
  }>;

export type UseUpdateProfileAutoSubmitForm_UpdateProfileMutationMutation = {
  __typename?: 'Mutation';
  updateProfile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber?: string | null;
    linkedInProfileUrl?: string | null;
    curriculumVitaeUrl?: string | null;
    approximateFirstJobStartDate?: any | null;
    salaryClaimAmount?: number | null;
    preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
    openToWorkUntil?: any | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    currentEmployer?: {
      __typename?: 'Employer';
      id: string;
      name: string;
    } | null;
  };
};

export type ProfileRequirementsChecklist_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  email?: string | null;
  approximateFirstJobStartDate?: any | null;
  newJobCategories: Array<{ __typename?: 'JobCategory'; id: string }>;
  academicBackgrounds: Array<{ __typename?: 'AcademicBackground'; id: string }>;
  skills: Array<{ __typename?: 'Skill'; id: string }>;
  urbanArea?: { __typename?: 'UrbanArea'; id: string } | null;
  experiences: Array<{ __typename?: 'Experience'; id: string }>;
};

export type UseProfile_ProfileQueryQueryVariables = Exact<{
  input: GetProfileInput;
}>;

export type UseProfile_ProfileQueryQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'Profile';
    id: string;
    linkedInProfileUrl?: string | null;
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber?: string | null;
    curriculumVitaeUrl?: string | null;
    profilePictureUrl?: string | null;
    approximateFirstJobStartDate?: any | null;
    openToWorkUntil?: any | null;
    salaryClaimAmount?: number | null;
    preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
    myNote?: {
      __typename?: 'ProfileNote';
      id: string;
      createdAt: any;
      updatedAt: any;
      body: string;
    } | null;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      degreeTitle?: string | null;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
    experiences: Array<{
      __typename?: 'Experience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
    }>;
    newJobCategories: Array<{
      __typename?: 'JobCategory';
      id: string;
      name: string;
    }>;
    academicBackgrounds: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }>;
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      status: SkillStatus;
    }>;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    currentEmployer?: {
      __typename?: 'Employer';
      name: string;
      id: string;
    } | null;
  };
};

export type UseRecruiterProfilesSearch_RecruiterJobApplicationsQueryQueryVariables =
  Exact<{
    offsetBasedPaginationParams: OffsetBasedPaginationParams;
    filters?: InputMaybe<JobApplicationsFilters>;
  }>;

export type UseRecruiterProfilesSearch_RecruiterJobApplicationsQueryQuery = {
  __typename?: 'Query';
  recruiterJobApplications: {
    __typename?: 'JobApplicationOffsetBasedConnection';
    nodes: Array<{
      __typename?: 'JobApplication';
      profile: { __typename?: 'Profile'; id: string };
    }>;
  };
};

export type RecruiterProfilesQueryVariables = Exact<{
  filters?: InputMaybe<RecruiterProfilesFilters>;
}>;

export type RecruiterProfilesQuery = {
  __typename?: 'Query';
  recruiterProfiles: Array<{
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber?: string | null;
    linkedInProfileUrl?: string | null;
    curriculumVitaeUrl?: string | null;
    profilePictureUrl?: string | null;
    createdAt: any;
  }>;
};

export type RecruiterJobApplicationCard_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
  }> | null;
};

export type RecruiterJobApplicationStatus_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
  }> | null;
};

export type UseRecruiterJobApplicationsTable_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    employer?: {
      __typename?: 'Employer';
      id: string;
      name: string;
      logoUrl?: string | null;
    } | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
  }> | null;
};

export type RecruiterJobApplicationsQueryVariables = Exact<{
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
  filters?: InputMaybe<JobApplicationsFilters>;
}>;

export type RecruiterJobApplicationsQuery = {
  __typename?: 'Query';
  recruiterJobApplications: {
    __typename?: 'JobApplicationOffsetBasedConnection';
    totalCount: number;
    hasNextPage: boolean;
    nodes: Array<{
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
      };
      jobOffer: {
        __typename?: 'JobOffer';
        id: string;
        title: string;
        employer?: {
          __typename?: 'Employer';
          id: string;
          name: string;
          logoUrl?: string | null;
        } | null;
      };
      statuses?: Array<{
        __typename?: 'JobApplicationStatus';
        id: string;
        name: JobApplicationStatusName;
        createdAt: any;
      }> | null;
    }>;
  };
};

export type RecruiterLayout_RecruiterRecruitmentTaskRecommendationsCountQueryVariables =
  Exact<{
    filters: RecruitmentTaskRecommendationsFilters;
  }>;

export type RecruiterLayout_RecruiterRecruitmentTaskRecommendationsCountQuery =
  {
    __typename?: 'Query';
    recruiterRecruitmentTaskRecommendationsCount: number;
  };

export type RecruiterLayout_UserFragment = {
  __typename?: 'User';
  id: string;
  isAuthorizedToUseMarketplace: boolean;
  roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  searchFirm?: {
    __typename?: 'SearchFirm';
    id: string;
    createdAt: any;
    name: string;
    subscriptions: Array<{
      __typename?: 'Subscription';
      id: string;
      paymentServiceProviderName: PaymentServiceProviderName;
      status: SubscriptionStatus;
    }>;
    contracts?: Array<{
      __typename?: 'Contract';
      id: string;
      statuses?: Array<{
        __typename?: 'ContractStatus';
        id: string;
        name: ContractStatusName;
      }> | null;
    }> | null;
  } | null;
};

export type RecruiterSettingsGeneralTab_UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  isAuthorizedToUseMarketplace: boolean;
  profilePictureUrl?: string | null;
  searchFirm?: {
    __typename?: 'SearchFirm';
    id: string;
    name: string;
    description?: string | null;
  } | null;
  roles: Array<{ __typename?: 'Role'; name: RoleName }>;
};

export type RecruiterSettingsSpecializationsTab_UserFragment = {
  __typename?: 'User';
  recruiterNewJobCategories?: Array<{
    __typename?: 'JobCategory';
    id: string;
    name: string;
  }> | null;
  recruiterActivitySectors: Array<{
    __typename?: 'ActivitySector';
    id: string;
    name: string;
  }>;
};

export type JobCategories_UseRecruiterSettingsSpecializationsTabQueryVariables =
  Exact<{
    filters?: InputMaybe<JobCategoriesFilters>;
  }>;

export type JobCategories_UseRecruiterSettingsSpecializationsTabQuery = {
  __typename?: 'Query';
  jobCategories: Array<{
    __typename?: 'JobCategory';
    id: string;
    name: string;
  }>;
};

export type UpdateRecruiterNewJobCategoryAssociationsMutationVariables = Exact<{
  recruiterJobCategoryAssociationInputs:
    | Array<RecruiterJobCategoryAssociationInput>
    | RecruiterJobCategoryAssociationInput;
}>;

export type UpdateRecruiterNewJobCategoryAssociationsMutation = {
  __typename?: 'Mutation';
  updateRecruiterNewJobCategoryAssociations: boolean;
};

export type RecruiterSuperRecruiter_RecruiterActivityMetricsFragment = {
  __typename?: 'RecruiterActivityMetrics';
  jobApplicationMetrics: {
    __typename?: 'RecruiterJobApplicationMetrics';
    numberOfJobApplications: number;
    interviewConversionRateInBasisPoints: number;
    hireConversionRateInBasisPoints: number;
  };
};

export type RecruiterSuperRecruiter_UserActivityMetricFragment = {
  __typename?: 'UserActivityMetric';
  recruiterActivityMetrics?: {
    __typename?: 'RecruiterActivityMetrics';
    jobApplicationMetrics: {
      __typename?: 'RecruiterJobApplicationMetrics';
      numberOfJobApplications: number;
      interviewConversionRateInBasisPoints: number;
      hireConversionRateInBasisPoints: number;
    };
  } | null;
};

export type RecruiterSuperRecruiter_UserFragment = {
  __typename?: 'User';
  id: string;
  activityMetric: {
    __typename?: 'UserActivityMetric';
    jobApplicationsResponseTime?: number | null;
    jobApplicationsResponseRate?: number | null;
    recruiterActivityMetrics?: {
      __typename?: 'RecruiterActivityMetrics';
      jobApplicationMetrics: {
        __typename?: 'RecruiterJobApplicationMetrics';
        numberOfJobApplications: number;
        interviewConversionRateInBasisPoints: number;
        hireConversionRateInBasisPoints: number;
      };
    } | null;
  };
};

export type RecruiterSuperRecruiter_MyUserQueryQueryVariables = Exact<{
  jobApplicationMetricsInput: RecruiterJobApplicationMetricsInput;
}>;

export type RecruiterSuperRecruiter_MyUserQueryQuery = {
  __typename?: 'Query';
  myUser?:
    | {
        __typename: 'User';
        id: string;
        activityMetric: {
          __typename?: 'UserActivityMetric';
          jobApplicationsResponseTime?: number | null;
          jobApplicationsResponseRate?: number | null;
          recruiterActivityMetrics?: {
            __typename?: 'RecruiterActivityMetrics';
            jobApplicationMetrics: {
              __typename?: 'RecruiterJobApplicationMetrics';
              numberOfJobApplications: number;
              interviewConversionRateInBasisPoints: number;
              hireConversionRateInBasisPoints: number;
            };
          } | null;
        };
      }
    | { __typename: 'UserLead' }
    | null;
};

export type RecruitmentTaskWithApplicationsCard_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  jobApplications: Array<{
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      curriculumVitaeUrl?: string | null;
      linkedInProfileUrl?: string | null;
      phoneNumber?: string | null;
      email?: string | null;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      salaryClaimAmount?: number | null;
      salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
      approximateFirstJobStartDate?: any | null;
      preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
      creator?: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
        createdAt: any;
        phoneNumber: string;
        email: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
      validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
      educations: Array<{
        __typename?: 'Education';
        id: string;
        degreeTitle?: string | null;
        startedAt?: any | null;
        endedAt?: any | null;
        school: { __typename?: 'School'; id: string; name: string };
      }>;
      experiences: Array<{
        __typename?: 'Experience';
        id: string;
        jobTitle: string;
        startedAt?: any | null;
        endedAt?: any | null;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
        }> | null;
      }>;
    };
    recruitmentTask: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      message?: {
        __typename?: 'Message';
        body?: string | null;
        creator: { __typename?: 'User'; firstName: string; lastName: string };
      } | null;
    }> | null;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      contractType?: string | null;
      isTerminated: boolean;
      numberOfOngoingRecruitmentTasks: number;
      status: JobOfferStatus;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
      } | null;
      creator?: { __typename?: 'User'; id: string; email: string } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    };
  }>;
  jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
};

export type RecruitmentTaskWithApplicationsCardHeader_RecruitmentTaskFragment =
  {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
    jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
  };

export type RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  jobApplications: Array<{
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      curriculumVitaeUrl?: string | null;
      linkedInProfileUrl?: string | null;
      phoneNumber?: string | null;
      email?: string | null;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      salaryClaimAmount?: number | null;
      salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
      approximateFirstJobStartDate?: any | null;
      preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
      creator?: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
        createdAt: any;
        phoneNumber: string;
        email: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
      validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
      educations: Array<{
        __typename?: 'Education';
        id: string;
        degreeTitle?: string | null;
        startedAt?: any | null;
        endedAt?: any | null;
        school: { __typename?: 'School'; id: string; name: string };
      }>;
      experiences: Array<{
        __typename?: 'Experience';
        id: string;
        jobTitle: string;
        startedAt?: any | null;
        endedAt?: any | null;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
        }> | null;
      }>;
    };
    recruitmentTask: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
      message?: {
        __typename?: 'Message';
        body?: string | null;
        creator: { __typename?: 'User'; firstName: string; lastName: string };
      } | null;
    }> | null;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      contractType?: string | null;
      isTerminated: boolean;
      numberOfOngoingRecruitmentTasks: number;
      status: JobOfferStatus;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
      } | null;
      creator?: { __typename?: 'User'; id: string; email: string } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    };
  }>;
  jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
};

export type UseRecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTasksOfRecruiterForMyEmployerQueryVariables =
  Exact<{
    input: RecruitmentTasksOfRecruiterForMyEmployerInput;
  }>;

export type UseRecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTasksOfRecruiterForMyEmployerQuery =
  {
    __typename?: 'Query';
    recruitmentTasksOfRecruiterForMyEmployer: Array<{
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
      jobApplications: Array<{
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          curriculumVitaeUrl?: string | null;
          linkedInProfileUrl?: string | null;
          phoneNumber?: string | null;
          email?: string | null;
          firstName: string;
          lastName: string;
          profilePictureUrl?: string | null;
          salaryClaimAmount?: number | null;
          salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
          approximateFirstJobStartDate?: any | null;
          preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
          creator?: {
            __typename: 'User';
            id: string;
            firstName: string;
            lastName: string;
            createdAt: any;
            phoneNumber: string;
            email: string;
            profilePictureUrl?: string | null;
            myOneToOneChatChannelId?: string | null;
            roles: Array<{ __typename?: 'Role'; name: RoleName }>;
          } | null;
          urbanArea?: {
            __typename?: 'UrbanArea';
            id: string;
            name: string;
          } | null;
          validSkills: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
          }>;
          educations: Array<{
            __typename?: 'Education';
            id: string;
            degreeTitle?: string | null;
            startedAt?: any | null;
            endedAt?: any | null;
            school: { __typename?: 'School'; id: string; name: string };
          }>;
          experiences: Array<{
            __typename?: 'Experience';
            id: string;
            jobTitle: string;
            startedAt?: any | null;
            endedAt?: any | null;
            employer: { __typename?: 'ExperienceEmployer'; name: string };
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
            }> | null;
          }>;
        };
        recruitmentTask: {
          __typename?: 'RecruitmentTask';
          id: string;
          status: RecruitmentTaskStatus;
        };
        statuses?: Array<{
          __typename?: 'JobApplicationStatus';
          id: string;
          name: JobApplicationStatusName;
          createdAt: any;
          message?: {
            __typename?: 'Message';
            body?: string | null;
            creator: {
              __typename?: 'User';
              firstName: string;
              lastName: string;
            };
          } | null;
        }> | null;
        jobOffer: {
          __typename?: 'JobOffer';
          id: string;
          title: string;
          contractType?: string | null;
          isTerminated: boolean;
          numberOfOngoingRecruitmentTasks: number;
          status: JobOfferStatus;
          employer?: {
            __typename?: 'Employer';
            id: string;
            name: string;
            logoUrl?: string | null;
          } | null;
          creator?: { __typename?: 'User'; id: string; email: string } | null;
          replacedJobApplication?: {
            __typename?: 'JobApplication';
            id: string;
          } | null;
        };
      }>;
      jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
    }>;
  };

export type PendingRecruiterRecruitmentTaskRecommendationsCountQueryVariables =
  Exact<{
    filters: RecruitmentTaskRecommendationsFilters;
  }>;

export type PendingRecruiterRecruitmentTaskRecommendationsCountQuery = {
  __typename?: 'Query';
  recruiterRecruitmentTaskRecommendationsCount: number;
};

export type RejectRecruitmentTaskRecommendationMutationVariables = Exact<{
  input: RejectRecruitementTaskRecommendationInput;
}>;

export type RejectRecruitmentTaskRecommendationMutation = {
  __typename?: 'Mutation';
  rejectRecruitmentTaskRecommendation: {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    status: RecruitmentTaskRecommendationStatus;
  };
};

export type UseSchoolsOptions_SchoolFragment = {
  __typename?: 'School';
  id: string;
  name: string;
};

export type UseSchoolsOptions_SchoolsQueryVariables = Exact<{
  input: GetSchoolsInput;
}>;

export type UseSchoolsOptions_SchoolsQuery = {
  __typename?: 'Query';
  schools: Array<{ __typename?: 'School'; id: string; name: string }>;
};

export type CreateStripeCustomerCheckoutSessionMutationVariables = Exact<{
  input: CreateStripeCustomerCheckoutSessionInput;
}>;

export type CreateStripeCustomerCheckoutSessionMutation = {
  __typename?: 'Mutation';
  createStripeCustomerCheckoutSession: {
    __typename?: 'StripeCustomerCheckoutSession';
    url: string;
  };
};

export type CreateStripeCustomerPortalSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateStripeCustomerPortalSessionMutation = {
  __typename?: 'Mutation';
  createStripeCustomerPortalSession: {
    __typename?: 'StripeCustomerPortalSession';
    url: string;
  };
};

export type AgreeToTermsOfServiceMutationVariables = Exact<{
  input: AgreeToTermsOfServiceInput;
}>;

export type AgreeToTermsOfServiceMutation = {
  __typename?: 'Mutation';
  agreeToTermsOfService: {
    __typename?: 'User';
    id: string;
    termsOfServiceVersion?: number | null;
  };
};

export type UseAccountCreationForm_UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  mainBusinessType: BusinessType;
  email: string;
  employer?: { __typename?: 'Employer'; id: string } | null;
};

export type UseAccountCreationForm_CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type UseAccountCreationForm_CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    mainBusinessType: BusinessType;
    email: string;
    employer?: { __typename?: 'Employer'; id: string } | null;
  };
};

export type ActivityMetric_UserFragment = {
  __typename?: 'User';
  id: string;
  activityMetric: {
    __typename?: 'UserActivityMetric';
    jobApplicationsResponseTime?: number | null;
    jobApplicationsResponseRate?: number | null;
  };
};

export type ProfilePictureUploadUrlAndFileNameQueryVariables = Exact<{
  input: ProfilePictureUploadUrlAndFileNameInput;
}>;

export type ProfilePictureUploadUrlAndFileNameQuery = {
  __typename?: 'Query';
  profilePictureUploadUrlAndFileName: {
    __typename?: 'AWSS3UploadUrlAndKey';
    uploadUrl: string;
    awsS3Key: string;
  };
};

export type UpdateMyProfilePictureMutationVariables = Exact<{
  input: UpdateMyProfilePictureInput;
}>;

export type UpdateMyProfilePictureMutation = {
  __typename?: 'Mutation';
  updateMyProfilePicture: {
    __typename?: 'User';
    id: string;
    profilePictureUrl?: string | null;
  };
};

export type ChatContact_UserFragment = {
  __typename: 'User';
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  myOneToOneChatChannelId?: string | null;
};

export type ChatContact_InternalManagerFragment = {
  __typename: 'InternalManager';
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  myOneToOneChatChannelId?: string | null;
};

export type CreateMyEmployerMutationVariables = Exact<{
  input: CreateMyEmployerInput;
}>;

export type CreateMyEmployerMutation = {
  __typename?: 'Mutation';
  createMyEmployer: {
    __typename?: 'User';
    id: string;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  };
};

export type UseRecruiterProfilesTableColumns_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  curriculumVitaeUrl?: string | null;
  linkedInProfileUrl?: string | null;
  profilePictureUrl?: string | null;
  createdAt: any;
  openToWorkUntil?: any | null;
  lastJobApplicationRecommendationsComputation?: {
    __typename?: 'ProfileJobApplicationRecommendationsComputation';
    id: string;
    startedAt: any;
    status: ProfileJobApplicationRecommendationsComputationStatus;
  } | null;
};

export type UseRecruiterProfilesPage_PaginatedRecruiterProfilesQueryQueryVariables =
  Exact<{
    filters?: InputMaybe<RecruiterProfilesFilters>;
    offsetBasedPaginationParams: OffsetBasedPaginationParams;
  }>;

export type UseRecruiterProfilesPage_PaginatedRecruiterProfilesQueryQuery = {
  __typename?: 'Query';
  paginatedRecruiterProfiles: {
    __typename?: 'ProfileOffsetBasedConnection';
    hasNextPage: boolean;
    totalCount: number;
    nodes: Array<{
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      email?: string | null;
      phoneNumber?: string | null;
      curriculumVitaeUrl?: string | null;
      linkedInProfileUrl?: string | null;
      profilePictureUrl?: string | null;
      createdAt: any;
      openToWorkUntil?: any | null;
      lastJobApplicationRecommendationsComputation?: {
        __typename?: 'ProfileJobApplicationRecommendationsComputation';
        id: string;
        startedAt: any;
        status: ProfileJobApplicationRecommendationsComputationStatus;
      } | null;
    }>;
  };
};

export type RecruitmentTaskRecommendationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RecruitmentTaskRecommendationQuery = {
  __typename?: 'Query';
  recruitmentTaskRecommendation: {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    status: RecruitmentTaskRecommendationStatus;
    recruiterFlatFeeInBasisPoints?: number | null;
    recruiterSuccessFeeInBasisPoints?: number | null;
    expirationDate: any;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      isTerminated: boolean;
      title: string;
      contractType?: string | null;
      description: string;
      profileDescription?: string | null;
      mandatoryProfileAttributes?: string | null;
      technicalSkillsDescription?: string | null;
      typicalProfileUrl?: string | null;
      careerOutlook?: string | null;
      recruitmentProcess?: string | null;
      perksDescription?: string | null;
      recruitmentAdvice?: string | null;
      status: JobOfferStatus;
      motivationalMessageToRecruiters?: string | null;
      location?: string | null;
      minimumRemuneration?: number | null;
      maximumRemuneration?: number | null;
      maximumVariableRemuneration?: number | null;
      currencyCode?: CurrencyIso4217Code | null;
      numberOfPositionsToFill: number;
      minNumberOfYearsOfExperience?: number | null;
      maxNumberOfYearsOfExperience?: number | null;
      remoteWorkPolicy?: RemoteWorkPolicy | null;
      briefingVideoUrl?: string | null;
      videoAskUrl?: string | null;
      hasIncentiveBonus?: boolean | null;
      hasLuncheonVouchers?: boolean | null;
      hasHealthInsurancePlan?: boolean | null;
      latestSuccessFee?: {
        __typename?: 'SuccessFee';
        id: string;
        basisPoints: number;
      } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
          profilePictureUrl?: string | null;
          linkedInProfileUrl?: string | null;
          curriculumVitaeUrl?: string | null;
        };
      } | null;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
        description?: string | null;
        nonPoachingAgreementsDescription?: string | null;
      } | null;
      creator?: {
        __typename: 'User';
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        id: string;
        phoneNumber: string;
        email: string;
        myOneToOneChatChannelId?: string | null;
        employer?: { __typename?: 'Employer'; name: string; id: string } | null;
        activityMetric: {
          __typename?: 'UserActivityMetric';
          jobApplicationsResponseTime?: number | null;
          jobApplicationsResponseRate?: number | null;
        };
      } | null;
      internalManager?: {
        __typename: 'InternalManager';
        id: string;
        phoneNumber: string;
        email: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
      } | null;
      academicBackgrounds?: Array<{
        __typename?: 'AcademicBackground';
        id: string;
        name: string;
      }> | null;
    };
  };
};

export type RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendationFragment =
  {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    status: RecruitmentTaskRecommendationStatus;
    recruiterFlatFeeInBasisPoints?: number | null;
    recruiterSuccessFeeInBasisPoints?: number | null;
    expirationDate: any;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      isTerminated: boolean;
      title: string;
      contractType?: string | null;
      description: string;
      profileDescription?: string | null;
      mandatoryProfileAttributes?: string | null;
      technicalSkillsDescription?: string | null;
      typicalProfileUrl?: string | null;
      careerOutlook?: string | null;
      recruitmentProcess?: string | null;
      perksDescription?: string | null;
      recruitmentAdvice?: string | null;
      status: JobOfferStatus;
      motivationalMessageToRecruiters?: string | null;
      location?: string | null;
      minimumRemuneration?: number | null;
      maximumRemuneration?: number | null;
      maximumVariableRemuneration?: number | null;
      currencyCode?: CurrencyIso4217Code | null;
      numberOfPositionsToFill: number;
      minNumberOfYearsOfExperience?: number | null;
      maxNumberOfYearsOfExperience?: number | null;
      remoteWorkPolicy?: RemoteWorkPolicy | null;
      briefingVideoUrl?: string | null;
      videoAskUrl?: string | null;
      hasIncentiveBonus?: boolean | null;
      hasLuncheonVouchers?: boolean | null;
      hasHealthInsurancePlan?: boolean | null;
      latestSuccessFee?: {
        __typename?: 'SuccessFee';
        id: string;
        basisPoints: number;
      } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
          profilePictureUrl?: string | null;
          linkedInProfileUrl?: string | null;
          curriculumVitaeUrl?: string | null;
        };
      } | null;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
        description?: string | null;
        nonPoachingAgreementsDescription?: string | null;
      } | null;
      creator?: {
        __typename: 'User';
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        id: string;
        phoneNumber: string;
        email: string;
        myOneToOneChatChannelId?: string | null;
        employer?: { __typename?: 'Employer'; name: string; id: string } | null;
        activityMetric: {
          __typename?: 'UserActivityMetric';
          jobApplicationsResponseTime?: number | null;
          jobApplicationsResponseRate?: number | null;
        };
      } | null;
      internalManager?: {
        __typename: 'InternalManager';
        id: string;
        phoneNumber: string;
        email: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
      } | null;
      academicBackgrounds?: Array<{
        __typename?: 'AcademicBackground';
        id: string;
        name: string;
      }> | null;
    };
  };

export type RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendationFragment =
  {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    status: RecruitmentTaskRecommendationStatus;
    expirationDate: any;
    jobOffer: { __typename?: 'JobOffer'; isTerminated: boolean };
  };

export type RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragment =
  {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    expirationDate: any;
    recruiterSuccessFeeInBasisPoints?: number | null;
    recruiterFlatFeeInBasisPoints?: number | null;
    jobOffer: {
      __typename?: 'JobOffer';
      title: string;
      priorityIndicator?: JobOfferPriorityIndicator | null;
      location?: string | null;
      minimumRemuneration?: number | null;
      maximumRemuneration?: number | null;
      currencyCode?: CurrencyIso4217Code | null;
      maximumVariableRemuneration?: number | null;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
      } | null;
      latestSuccessFee?: {
        __typename?: 'SuccessFee';
        basisPoints: number;
      } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    };
  };

export type RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendationsQueryVariables =
  Exact<{
    filters: RecruitmentTaskRecommendationsFilters;
  }>;

export type RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendationsQuery =
  {
    __typename?: 'Query';
    recruiterRecruitmentTaskRecommendations: Array<{
      __typename?: 'RecruitmentTaskRecommendation';
      id: string;
      expirationDate: any;
      recruiterSuccessFeeInBasisPoints?: number | null;
      recruiterFlatFeeInBasisPoints?: number | null;
      jobOffer: {
        __typename?: 'JobOffer';
        title: string;
        priorityIndicator?: JobOfferPriorityIndicator | null;
        location?: string | null;
        minimumRemuneration?: number | null;
        maximumRemuneration?: number | null;
        currencyCode?: CurrencyIso4217Code | null;
        maximumVariableRemuneration?: number | null;
        employer?: {
          __typename?: 'Employer';
          id: string;
          name: string;
          logoUrl?: string | null;
        } | null;
        latestSuccessFee?: {
          __typename?: 'SuccessFee';
          basisPoints: number;
        } | null;
        replacedJobApplication?: {
          __typename?: 'JobApplication';
          id: string;
        } | null;
      };
    }>;
  };

export type TermsOfServiceAgreementBox_TermsOfServiceQueryVariables = Exact<{
  input: TermsOfServiceInput;
}>;

export type TermsOfServiceAgreementBox_TermsOfServiceQuery = {
  __typename?: 'Query';
  termsOfService?: {
    __typename?: 'TermsOfService';
    id: string;
    content: string;
  } | null;
};

export type DeprecatedJobCategories_RecruiterRecruitmentTasksFilterModalQueryVariables =
  Exact<{
    filters?: InputMaybe<DeprecatedJobCategoriesFilters>;
  }>;

export type DeprecatedJobCategories_RecruiterRecruitmentTasksFilterModalQuery =
  {
    __typename?: 'Query';
    deprecatedJobCategories: Array<{
      __typename?: 'JobCategory';
      id: string;
      name: string;
    }>;
  };

export type RecruiterRecruitmentTasksTable_RecruiterRecruitmentTasksQueryVariables =
  Exact<{
    offsetBasedPaginationParams: OffsetBasedPaginationParams;
    filters?: InputMaybe<RecruiterRecruitmentTasksFilters>;
  }>;

export type RecruiterRecruitmentTasksTable_RecruiterRecruitmentTasksQuery = {
  __typename?: 'Query';
  recruiterRecruitmentTasks: {
    __typename?: 'RecruitmentTaskOffsetBasedConnection';
    totalCount: number;
    hasNextPage: boolean;
    nodes: Array<{
      __typename?: 'RecruitmentTask';
      id: string;
      recruiterSuccessFeeInBasisPoints?: number | null;
      recruiterFlatFeeInBasisPoints?: number | null;
      jobOffer: {
        __typename?: 'JobOffer';
        id: string;
        title: string;
        location?: string | null;
        priorityIndicator?: JobOfferPriorityIndicator | null;
        isTerminated: boolean;
        minimumRemuneration?: number | null;
        maximumRemuneration?: number | null;
        currencyCode?: CurrencyIso4217Code | null;
        maximumVariableRemuneration?: number | null;
        status: JobOfferStatus;
        latestSuccessFee?: {
          __typename?: 'SuccessFee';
          id: string;
          basisPoints: number;
        } | null;
        creator?: {
          __typename: 'User';
          id: string;
          firstName: string;
          lastName: string;
          profilePictureUrl?: string | null;
          myOneToOneChatChannelId?: string | null;
        } | null;
        employer?: {
          __typename?: 'Employer';
          id: string;
          name: string;
          logoUrl?: string | null;
        } | null;
        replacedJobApplication?: {
          __typename?: 'JobApplication';
          id: string;
        } | null;
      };
    }>;
  };
};

export type RecruiterRecruitmentTasksTable_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  recruiterSuccessFeeInBasisPoints?: number | null;
  recruiterFlatFeeInBasisPoints?: number | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    location?: string | null;
    priorityIndicator?: JobOfferPriorityIndicator | null;
    isTerminated: boolean;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    currencyCode?: CurrencyIso4217Code | null;
    maximumVariableRemuneration?: number | null;
    status: JobOfferStatus;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      id: string;
      basisPoints: number;
    } | null;
    creator?: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
    } | null;
    employer?: {
      __typename?: 'Employer';
      id: string;
      name: string;
      logoUrl?: string | null;
    } | null;
    replacedJobApplication?: {
      __typename?: 'JobApplication';
      id: string;
    } | null;
  };
};

export type ActivateMyJobOfferPage_EmployerJobOfferLeadQueryVariables = Exact<{
  jobOfferLeadId: Scalars['String']['input'];
}>;

export type ActivateMyJobOfferPage_EmployerJobOfferLeadQuery = {
  __typename?: 'Query';
  employerJobOfferLead: {
    __typename?: 'JobOfferLead';
    id: string;
    title: string;
    description?: string | null;
    location?: string | null;
    jobBoard: string;
    jobBoardUrl: string;
  };
};

export type ActivateMyJobOfferPage_JobOfferLeadFragment = {
  __typename?: 'JobOfferLead';
  id: string;
  title: string;
  description?: string | null;
  location?: string | null;
  jobBoard: string;
  jobBoardUrl: string;
};

export type SelectedProfileDrawer_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  id: string;
  profilePictureUrl?: string | null;
  salaryClaimAmount?: number | null;
  salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
  approximateFirstJobStartDate?: any | null;
  preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
  creator?: {
    __typename: 'User';
    id: string;
    createdAt: any;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  } | null;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
  validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
  experiences: Array<{
    __typename?: 'AnonymizedExperience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
  }>;
  educations: Array<{
    __typename?: 'Education';
    id: string;
    degreeTitle?: string | null;
    startedAt?: any | null;
    endedAt?: any | null;
    school: { __typename?: 'School'; id: string; name: string };
  }>;
};

export type SelectedProfileDrawerHeader_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  id: string;
  profilePictureUrl?: string | null;
};

export type EmployerJobOfferDashboard_JobOfferFragment = {
  __typename?: 'JobOffer';
  status: JobOfferStatus;
  id: string;
  title: string;
  createdAt: any;
  numberOfOngoingRecruitmentTasks: number;
  briefingVideoUrl?: string | null;
  flatFee?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  numberOfJobApplications: number;
  totalNumberOfAcceptedForFirstInterviewJobApplications: number;
  numberOfHiredJobApplications: number;
  numberOfNewJobApplications: number;
  isTerminated: boolean;
  videoAskUrl?: string | null;
  minNumberOfYearsOfExperience?: number | null;
  description: string;
  profileDescription?: string | null;
  mandatoryProfileAttributes?: string | null;
  technicalSkillsDescription?: string | null;
  typicalProfileUrl?: string | null;
  careerOutlook?: string | null;
  recruitmentProcess?: string | null;
  perksDescription?: string | null;
  recruitmentAdvice?: string | null;
  motivationalMessageToRecruiters?: string | null;
  location?: string | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  numberOfPositionsToFill: number;
  maxNumberOfYearsOfExperience?: number | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  internalManager?: {
    __typename: 'InternalManager';
    id: string;
    firstName: string;
    lastName: string;
    myOneToOneChatChannelId?: string | null;
    phoneNumber: string;
    email: string;
    profilePictureUrl?: string | null;
  } | null;
  employer?: {
    __typename?: 'Employer';
    id: string;
    name: string;
    logoUrl?: string | null;
    description?: string | null;
    nonPoachingAgreementsDescription?: string | null;
  } | null;
  creator?: {
    __typename: 'User';
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    id: string;
    phoneNumber: string;
    email: string;
    myOneToOneChatChannelId?: string | null;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    activityMetric: {
      __typename?: 'UserActivityMetric';
      jobApplicationsResponseTime?: number | null;
      jobApplicationsResponseRate?: number | null;
    };
  } | null;
  latestSuccessFee?: {
    __typename?: 'SuccessFee';
    id: string;
    basisPoints: number;
  } | null;
  replacedJobApplication?: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
    };
  } | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
  newJobCategory?: {
    __typename?: 'JobCategory';
    id: string;
    name: string;
  } | null;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
  likedAnonymizedProfiles: Array<{
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    createdAt: any;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      creator?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        profilePictureUrl?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
      }>;
    };
  }>;
};

export type EmployerJobOfferDashboard_JobOfferQueryQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type EmployerJobOfferDashboard_JobOfferQueryQuery = {
  __typename?: 'Query';
  jobOffer: {
    __typename?: 'JobOffer';
    status: JobOfferStatus;
    id: string;
    title: string;
    createdAt: any;
    numberOfOngoingRecruitmentTasks: number;
    briefingVideoUrl?: string | null;
    flatFee?: number | null;
    currencyCode?: CurrencyIso4217Code | null;
    numberOfJobApplications: number;
    totalNumberOfAcceptedForFirstInterviewJobApplications: number;
    numberOfHiredJobApplications: number;
    numberOfNewJobApplications: number;
    isTerminated: boolean;
    videoAskUrl?: string | null;
    minNumberOfYearsOfExperience?: number | null;
    description: string;
    profileDescription?: string | null;
    mandatoryProfileAttributes?: string | null;
    technicalSkillsDescription?: string | null;
    typicalProfileUrl?: string | null;
    careerOutlook?: string | null;
    recruitmentProcess?: string | null;
    perksDescription?: string | null;
    recruitmentAdvice?: string | null;
    motivationalMessageToRecruiters?: string | null;
    location?: string | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    maximumVariableRemuneration?: number | null;
    numberOfPositionsToFill: number;
    maxNumberOfYearsOfExperience?: number | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    hasIncentiveBonus?: boolean | null;
    hasLuncheonVouchers?: boolean | null;
    hasHealthInsurancePlan?: boolean | null;
    internalManager?: {
      __typename: 'InternalManager';
      id: string;
      firstName: string;
      lastName: string;
      myOneToOneChatChannelId?: string | null;
      phoneNumber: string;
      email: string;
      profilePictureUrl?: string | null;
    } | null;
    employer?: {
      __typename?: 'Employer';
      id: string;
      name: string;
      logoUrl?: string | null;
      description?: string | null;
      nonPoachingAgreementsDescription?: string | null;
    } | null;
    creator?: {
      __typename: 'User';
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      id: string;
      phoneNumber: string;
      email: string;
      myOneToOneChatChannelId?: string | null;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      activityMetric: {
        __typename?: 'UserActivityMetric';
        jobApplicationsResponseTime?: number | null;
        jobApplicationsResponseRate?: number | null;
      };
    } | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      id: string;
      basisPoints: number;
    } | null;
    replacedJobApplication?: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        linkedInProfileUrl?: string | null;
        curriculumVitaeUrl?: string | null;
      };
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }> | null;
    newJobCategory?: {
      __typename?: 'JobCategory';
      id: string;
      name: string;
    } | null;
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      status: SkillStatus;
    }>;
    likedAnonymizedProfiles: Array<{
      __typename?: 'HiringManagerLikesAnonymizedProfile';
      id: string;
      createdAt: any;
      anonymizedProfile: {
        __typename?: 'AnonymizedProfile';
        id: string;
        firstName: string;
        profilePictureUrl?: string | null;
        creator?: {
          __typename?: 'User';
          id: string;
          email: string;
          firstName: string;
          profilePictureUrl?: string | null;
          roles: Array<{ __typename?: 'Role'; name: RoleName }>;
        } | null;
        experiences: Array<{
          __typename?: 'AnonymizedExperience';
          id: string;
          jobTitle: string;
          employer: { __typename?: 'ExperienceEmployer'; name: string };
        }>;
      };
    }>;
  };
};

export type EmployerJobOfferHeader_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  createdAt: any;
  numberOfOngoingRecruitmentTasks: number;
  briefingVideoUrl?: string | null;
  flatFee?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  videoAskUrl?: string | null;
  isTerminated: boolean;
  status: JobOfferStatus;
  employer?: {
    __typename?: 'Employer';
    id: string;
    name: string;
    logoUrl?: string | null;
  } | null;
  creator?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  latestSuccessFee?: {
    __typename?: 'SuccessFee';
    id: string;
    basisPoints: number;
  } | null;
  replacedJobApplication?: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
    };
  } | null;
};

export type UseBookVideoBriefMeeting_VideoBriefMeetingFragment = {
  __typename?: 'VideoBriefMeeting';
  id: string;
  startDate: any;
  endDate: any;
  updatedAt: any;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    videoBriefMeeting?: {
      __typename?: 'VideoBriefMeeting';
      id: string;
      startDate: any;
    } | null;
  };
};

export type UseBookVideoBriefMeeting_BookVideoBriefMeetingMutationMutationVariables =
  Exact<{
    input: BookVideoBriefMeetingInput;
  }>;

export type UseBookVideoBriefMeeting_BookVideoBriefMeetingMutationMutation = {
  __typename?: 'Mutation';
  bookVideoBriefMeeting: {
    __typename?: 'VideoBriefMeeting';
    id: string;
    startDate: any;
    endDate: any;
    updatedAt: any;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      videoBriefMeeting?: {
        __typename?: 'VideoBriefMeeting';
        id: string;
        startDate: any;
      } | null;
    };
  };
};

export type EmployerJobOfferManagementPopover_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  videoAskUrl?: string | null;
  briefingVideoUrl?: string | null;
  isTerminated: boolean;
  title: string;
  numberOfOngoingRecruitmentTasks: number;
};

export type EmployerJobOfferTerminationModal_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  numberOfOngoingRecruitmentTasks: number;
};

export type EmployerJobOfferTabs_JobOfferFragment = {
  __typename?: 'JobOffer';
  status: JobOfferStatus;
  id: string;
  numberOfJobApplications: number;
  isTerminated: boolean;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
  title: string;
  minNumberOfYearsOfExperience?: number | null;
  numberOfOngoingRecruitmentTasks: number;
  description: string;
  profileDescription?: string | null;
  mandatoryProfileAttributes?: string | null;
  technicalSkillsDescription?: string | null;
  typicalProfileUrl?: string | null;
  careerOutlook?: string | null;
  recruitmentProcess?: string | null;
  perksDescription?: string | null;
  recruitmentAdvice?: string | null;
  motivationalMessageToRecruiters?: string | null;
  location?: string | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  numberOfPositionsToFill: number;
  maxNumberOfYearsOfExperience?: number | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
  newJobCategory?: {
    __typename?: 'JobCategory';
    id: string;
    name: string;
  } | null;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
  likedAnonymizedProfiles: Array<{
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    createdAt: any;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      creator?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        profilePictureUrl?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
      }>;
    };
  }>;
  employer?: {
    __typename?: 'Employer';
    id: string;
    description?: string | null;
    nonPoachingAgreementsDescription?: string | null;
  } | null;
  creator?: {
    __typename: 'User';
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    id: string;
    phoneNumber: string;
    email: string;
    myOneToOneChatChannelId?: string | null;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    activityMetric: {
      __typename?: 'UserActivityMetric';
      jobApplicationsResponseTime?: number | null;
      jobApplicationsResponseRate?: number | null;
    };
  } | null;
  internalManager?: {
    __typename: 'InternalManager';
    id: string;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
  } | null;
  replacedJobApplication?: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
    };
  } | null;
};

export type EmployerJobOfferJobApplicationsCards_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    linkedInProfileUrl?: string | null;
    curriculumVitaeUrl?: string | null;
    profilePictureUrl?: string | null;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
  }> | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    numberOfOngoingRecruitmentTasks: number;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    creator?: { __typename?: 'User'; id: string; email: string } | null;
  };
};

export type EmployerJobOfferJobApplicationsList_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    linkedInProfileUrl?: string | null;
    curriculumVitaeUrl?: string | null;
    profilePictureUrl?: string | null;
    creator?: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      createdAt: any;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      searchFirm?: {
        __typename?: 'SearchFirm';
        id: string;
        name: string;
        description?: string | null;
      } | null;
    } | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
  }> | null;
  recruitmentTask: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    numberOfOngoingRecruitmentTasks: number;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    creator?: { __typename?: 'User'; id: string; email: string } | null;
  };
};

export type EmployerJobOfferJobApplicationsTabContent_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  status: JobOfferStatus;
  numberOfJobApplications: number;
  isTerminated: boolean;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
  title: string;
  minNumberOfYearsOfExperience?: number | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
  newJobCategory?: {
    __typename?: 'JobCategory';
    id: string;
    name: string;
  } | null;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
};

export type EmployerJobOfferJobApplicationsQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
  jobApplicationsFilters?: InputMaybe<JobApplicationsFilters>;
}>;

export type EmployerJobOfferJobApplicationsQuery = {
  __typename?: 'Query';
  jobOfferApplications: Array<{
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
      profilePictureUrl?: string | null;
      creator?: {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
        createdAt: any;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
        searchFirm?: {
          __typename?: 'SearchFirm';
          id: string;
          name: string;
          description?: string | null;
        } | null;
      } | null;
    };
    statuses?: Array<{
      __typename?: 'JobApplicationStatus';
      id: string;
      name: JobApplicationStatusName;
      createdAt: any;
    }> | null;
    recruitmentTask: {
      __typename?: 'RecruitmentTask';
      id: string;
      status: RecruitmentTaskStatus;
    };
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      title: string;
      numberOfOngoingRecruitmentTasks: number;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      creator?: { __typename?: 'User'; id: string; email: string } | null;
    };
  }>;
};

export type JobApplicationRecruiterDetailsModal_UserFragment = {
  __typename?: 'User';
  id: string;
  createdAt: any;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  searchFirm?: {
    __typename?: 'SearchFirm';
    id: string;
    name: string;
    description?: string | null;
  } | null;
  roles: Array<{ __typename?: 'Role'; name: RoleName }>;
};

export type EmployerJobOfferJobApplicationsTable_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
    linkedInProfileUrl?: string | null;
    curriculumVitaeUrl?: string | null;
    profilePictureUrl?: string | null;
    creator?: {
      __typename: 'User';
      id: string;
      firstName: string;
      lastName: string;
      createdAt: any;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      searchFirm?: {
        __typename?: 'SearchFirm';
        id: string;
        name: string;
        description?: string | null;
      } | null;
    } | null;
  };
  statuses?: Array<{
    __typename?: 'JobApplicationStatus';
    id: string;
    name: JobApplicationStatusName;
    createdAt: any;
  }> | null;
  recruitmentTask: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
  };
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    numberOfOngoingRecruitmentTasks: number;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    creator?: { __typename?: 'User'; id: string; email: string } | null;
  };
};

export type JobApplicationProfileCareerCell_JobApplicationFragment = {
  __typename?: 'JobApplication';
  profile: {
    __typename?: 'Profile';
    curriculumVitaeUrl?: string | null;
    linkedInProfileUrl?: string | null;
  };
};

export type JobApplicationProfileNameCell_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
  };
};

export type JobApplicationRecruiterCell_UserFragment = {
  __typename: 'User';
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  myOneToOneChatChannelId?: string | null;
  roles: Array<{ __typename?: 'Role'; name: RoleName }>;
};

export type JobApplicationRecruiterCell_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
};

export type EmployerJobOfferPreview_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  description: string;
  profileDescription?: string | null;
  mandatoryProfileAttributes?: string | null;
  technicalSkillsDescription?: string | null;
  typicalProfileUrl?: string | null;
  careerOutlook?: string | null;
  recruitmentProcess?: string | null;
  perksDescription?: string | null;
  recruitmentAdvice?: string | null;
  motivationalMessageToRecruiters?: string | null;
  location?: string | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  numberOfPositionsToFill: number;
  minNumberOfYearsOfExperience?: number | null;
  maxNumberOfYearsOfExperience?: number | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  employer?: {
    __typename?: 'Employer';
    id: string;
    description?: string | null;
    nonPoachingAgreementsDescription?: string | null;
  } | null;
  creator?: {
    __typename: 'User';
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    id: string;
    phoneNumber: string;
    email: string;
    myOneToOneChatChannelId?: string | null;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    activityMetric: {
      __typename?: 'UserActivityMetric';
      jobApplicationsResponseTime?: number | null;
      jobApplicationsResponseRate?: number | null;
    };
  } | null;
  internalManager?: {
    __typename: 'InternalManager';
    id: string;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
  } | null;
  replacedJobApplication?: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
    };
  } | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
};

export type EmployerJobOfferPreviewTabContent_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  description: string;
  profileDescription?: string | null;
  mandatoryProfileAttributes?: string | null;
  technicalSkillsDescription?: string | null;
  typicalProfileUrl?: string | null;
  careerOutlook?: string | null;
  recruitmentProcess?: string | null;
  perksDescription?: string | null;
  recruitmentAdvice?: string | null;
  motivationalMessageToRecruiters?: string | null;
  location?: string | null;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  numberOfPositionsToFill: number;
  minNumberOfYearsOfExperience?: number | null;
  maxNumberOfYearsOfExperience?: number | null;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  employer?: {
    __typename?: 'Employer';
    id: string;
    description?: string | null;
    nonPoachingAgreementsDescription?: string | null;
  } | null;
  creator?: {
    __typename: 'User';
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    id: string;
    phoneNumber: string;
    email: string;
    myOneToOneChatChannelId?: string | null;
    employer?: { __typename?: 'Employer'; name: string; id: string } | null;
    activityMetric: {
      __typename?: 'UserActivityMetric';
      jobApplicationsResponseTime?: number | null;
      jobApplicationsResponseRate?: number | null;
    };
  } | null;
  internalManager?: {
    __typename: 'InternalManager';
    id: string;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
  } | null;
  replacedJobApplication?: {
    __typename?: 'JobApplication';
    id: string;
    profile: {
      __typename?: 'Profile';
      id: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      linkedInProfileUrl?: string | null;
      curriculumVitaeUrl?: string | null;
    };
  } | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
};

export type EmployerJobOfferRecruitersTabContent_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  status: JobOfferStatus;
  numberOfOngoingRecruitmentTasks: number;
};

export type EmployerJobOfferRecruitmentTasksList_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  status: JobOfferStatus;
  numberOfOngoingRecruitmentTasks: number;
};

export type UseEmployerJobOfferRecruitmentTasksTable_JobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
};

export type UseEmployerJobOfferRecruitmentTasksTable_UserFragment = {
  __typename: 'User';
  id: string;
  createdAt: any;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  myOneToOneChatChannelId?: string | null;
  roles: Array<{ __typename?: 'Role'; name: RoleName }>;
};

export type UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  recruiter: {
    __typename: 'User';
    id: string;
    createdAt: any;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  };
  jobApplications: Array<{ __typename?: 'JobApplication'; id: string }>;
};

export type UseEmployerJobOfferRecruitmentTasksListQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type UseEmployerJobOfferRecruitmentTasksListQuery = {
  __typename?: 'Query';
  jobOfferRecruitmentTasks: Array<{
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
    recruiter: {
      __typename: 'User';
      id: string;
      createdAt: any;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
      roles: Array<{ __typename?: 'Role'; name: RoleName }>;
    };
    jobApplications: Array<{ __typename?: 'JobApplication'; id: string }>;
  }>;
};

export type EmployerJobOfferSelectedProfilesTabContent_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  minNumberOfYearsOfExperience?: number | null;
  likedAnonymizedProfiles: Array<{
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    createdAt: any;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      creator?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        profilePictureUrl?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
      }>;
    };
  }>;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
  newJobCategory?: {
    __typename?: 'JobCategory';
    id: string;
    name: string;
  } | null;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
};

export type EmployerJobOfferGoToProfileSearchButton_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  minNumberOfYearsOfExperience?: number | null;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
  newJobCategory?: {
    __typename?: 'JobCategory';
    id: string;
    name: string;
  } | null;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
};

export type EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfileFragment =
  {
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    createdAt: any;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      creator?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        profilePictureUrl?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
      }>;
    };
  };

export type EmployerJobOfferLikedAnonymizedProfilesTable_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  likedAnonymizedProfiles: Array<{
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    createdAt: any;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      creator?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        profilePictureUrl?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
      }>;
    };
  }>;
};

export type EmployerJobOfferLikedAnonymizedProfilesTable_AnonymizedProfileQueryVariables =
  Exact<{
    input: GetProfileInput;
  }>;

export type EmployerJobOfferLikedAnonymizedProfilesTable_AnonymizedProfileQuery =
  {
    __typename?: 'Query';
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      profilePictureUrl?: string | null;
      salaryClaimAmount?: number | null;
      salaryClaimCurrencyCode?: CurrencyIso4217Code | null;
      approximateFirstJobStartDate?: any | null;
      preferredRemoteWorkPolicy?: RemoteWorkPolicy | null;
      creator?: {
        __typename: 'User';
        id: string;
        createdAt: any;
        phoneNumber: string;
        email: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
      urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
      validSkills: Array<{ __typename?: 'Skill'; id: string; name: string }>;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        startedAt?: any | null;
        endedAt?: any | null;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
        }> | null;
      }>;
      educations: Array<{
        __typename?: 'Education';
        id: string;
        degreeTitle?: string | null;
        startedAt?: any | null;
        endedAt?: any | null;
        school: { __typename?: 'School'; id: string; name: string };
      }>;
    };
  };

export type EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfileFragment =
  {
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    createdAt: any;
  };

export type EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfileFragment =
  {
    __typename?: 'AnonymizedProfile';
    id: string;
    firstName: string;
    profilePictureUrl?: string | null;
    experiences: Array<{
      __typename?: 'AnonymizedExperience';
      id: string;
      jobTitle: string;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
    }>;
  };

export type EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfileFragment =
  {
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
      }>;
    };
  };

export type EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_UserFragment =
  {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    profilePictureUrl?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  };

export type EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragmentFragment =
  {
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      creator?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        profilePictureUrl?: string | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
      } | null;
    };
  };

export type EmployerJobOfferTimeline_JobOfferFragment = {
  __typename?: 'JobOffer';
  status: JobOfferStatus;
  numberOfOngoingRecruitmentTasks: number;
  numberOfJobApplications: number;
  totalNumberOfAcceptedForFirstInterviewJobApplications: number;
  numberOfHiredJobApplications: number;
  numberOfNewJobApplications: number;
};

export type EmployerJobOfferPageProvider_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  numberOfJobApplications: number;
};

export type EmployerJobOffersPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmployerJobOffersPageQuery = {
  __typename?: 'Query';
  myUser?:
    | {
        __typename: 'User';
        id: string;
        activityMetric: {
          __typename?: 'UserActivityMetric';
          jobApplicationsResponseTime?: number | null;
          jobApplicationsResponseRate?: number | null;
        };
      }
    | { __typename: 'UserLead' }
    | null;
};

export type EmployerJobOfferLeads_EmployerPaginatedJobOfferLeadsQueryVariables =
  Exact<{
    offsetBasedPaginationParams: OffsetBasedPaginationParams;
    filters?: InputMaybe<EmployerJobOfferLeadsFilters>;
  }>;

export type EmployerJobOfferLeads_EmployerPaginatedJobOfferLeadsQuery = {
  __typename?: 'Query';
  employerPaginatedJobOfferLeads: {
    __typename?: 'JobOfferLeadOffsetBasedConnection';
    totalCount: number;
    hasNextPage: boolean;
    nodes: Array<{
      __typename?: 'JobOfferLead';
      id: string;
      title: string;
      location?: string | null;
      description?: string | null;
      jobBoard: string;
      jobBoardUrl: string;
    }>;
  };
};

export type EmployerJobOfferLeads_JobOfferLeadFragment = {
  __typename?: 'JobOfferLead';
  id: string;
  title: string;
  location?: string | null;
  description?: string | null;
  jobBoard: string;
  jobBoardUrl: string;
};

export type EmployerJobOffersTable_EmployerPaginatedJobOffersQueryVariables =
  Exact<{
    offsetBasedPaginationParams: OffsetBasedPaginationParams;
    filters?: InputMaybe<EmployerJobOffersFilters>;
  }>;

export type EmployerJobOffersTable_EmployerPaginatedJobOffersQuery = {
  __typename?: 'Query';
  employerPaginatedJobOffers: {
    __typename?: 'JobOfferOffsetBasedConnection';
    totalCount: number;
    hasNextPage: boolean;
    nodes: Array<{
      __typename?: 'JobOffer';
      id: string;
      title: string;
      createdAt: any;
      numberOfNewJobApplications: number;
      status: JobOfferStatus;
      isTerminated: boolean;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
      } | null;
    }>;
  };
};

export type EmployerJobOffersTableColumns_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  createdAt: any;
  numberOfNewJobApplications: number;
  status: JobOfferStatus;
  isTerminated: boolean;
  replacedJobApplication?: { __typename?: 'JobApplication'; id: string } | null;
};

export type EmployerProfileSearchPageAnonymizedProfileCard_SkillFragment = {
  __typename?: 'Skill';
  id: string;
  name: string;
};

export type EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragment =
  {
    __typename?: 'AnonymizedExperience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
  };

export type EmployerProfileSearchPageAnonymizedProfileCard_EducationFragment = {
  __typename?: 'Education';
  id: string;
  startedAt?: any | null;
  endedAt?: any | null;
  school: { __typename?: 'School'; id: string; name: string };
};

export type EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragment =
  {
    __typename?: 'AnonymizedProfile';
    id: string;
    profilePictureUrl?: string | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    experiences: Array<{
      __typename?: 'AnonymizedExperience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
      skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
    }>;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
  };

export type EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfileFragment =
  {
    __typename?: 'AnonymizedProfile';
    id: string;
    profilePictureUrl?: string | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    experiences: Array<{
      __typename?: 'AnonymizedExperience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
      skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
    }>;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
  };

export type EmployerProfileSearchPageUseAnonymizedProfileList_PaginatedAnonymizedProfilesQueryVariables =
  Exact<{
    filters?: InputMaybe<AnonymizedProfilesFilters>;
    offsetBasedPaginationParams: OffsetBasedPaginationParams;
  }>;

export type EmployerProfileSearchPageUseAnonymizedProfileList_PaginatedAnonymizedProfilesQuery =
  {
    __typename?: 'Query';
    paginatedAnonymizedProfiles: {
      __typename?: 'AnonymizedProfileOffsetBasedConnection';
      totalCount: number;
      hasNextPage: boolean;
      nodes: Array<{
        __typename?: 'AnonymizedProfile';
        id: string;
        profilePictureUrl?: string | null;
        urbanArea?: {
          __typename?: 'UrbanArea';
          id: string;
          name: string;
        } | null;
        experiences: Array<{
          __typename?: 'AnonymizedExperience';
          id: string;
          jobTitle: string;
          startedAt?: any | null;
          endedAt?: any | null;
          employer: { __typename?: 'ExperienceEmployer'; name: string };
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
          }> | null;
        }>;
        educations: Array<{
          __typename?: 'Education';
          id: string;
          startedAt?: any | null;
          endedAt?: any | null;
          school: { __typename?: 'School'; id: string; name: string };
        }>;
      }>;
    };
  };

export type EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfileFragment =
  { __typename?: 'AnonymizedProfile'; id: string };

export type UseJobOfferSelectionForm_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  id: string;
};

export type UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfileFragment =
  {
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    anonymizedProfile: { __typename?: 'AnonymizedProfile'; id: string };
  };

export type UseJobOfferSelectionForm_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  minNumberOfYearsOfExperience?: number | null;
  status: JobOfferStatus;
  isTerminated: boolean;
  academicBackgrounds?: Array<{
    __typename?: 'AcademicBackground';
    id: string;
    name: string;
  }> | null;
  newJobCategory?: {
    __typename?: 'JobCategory';
    id: string;
    name: string;
  } | null;
  skills: Array<{
    __typename?: 'Skill';
    id: string;
    name: string;
    status: SkillStatus;
  }>;
  likedAnonymizedProfiles: Array<{
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    anonymizedProfile: { __typename?: 'AnonymizedProfile'; id: string };
  }>;
};

export type EmployerProfileSearchPage_EmployerJobOffersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmployerProfileSearchPage_EmployerJobOffersQuery = {
  __typename?: 'Query';
  employerJobOffers: Array<{
    __typename?: 'JobOffer';
    id: string;
    title: string;
    minNumberOfYearsOfExperience?: number | null;
    status: JobOfferStatus;
    isTerminated: boolean;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }> | null;
    newJobCategory?: {
      __typename?: 'JobCategory';
      id: string;
      name: string;
    } | null;
    skills: Array<{
      __typename?: 'Skill';
      id: string;
      name: string;
      status: SkillStatus;
    }>;
    likedAnonymizedProfiles: Array<{
      __typename?: 'HiringManagerLikesAnonymizedProfile';
      id: string;
      anonymizedProfile: { __typename?: 'AnonymizedProfile'; id: string };
    }>;
  }>;
};

export type UseProfileSearchSelectionSubmit_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  id: string;
};

export type EmployerSettingsEmployerTab_EmployerFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
  logoUrl?: string | null;
  description?: string | null;
  nonPoachingAgreementsDescription?: string | null;
};

export type UseEmployerSettingsEmployerForm_EmployerFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
  description?: string | null;
  nonPoachingAgreementsDescription?: string | null;
};

export type UseEmployerSettingsEmployerForm_UpdateEmployerMutationVariables =
  Exact<{
    input: UpdateEmployerInput;
  }>;

export type UseEmployerSettingsEmployerForm_UpdateEmployerMutation = {
  __typename?: 'Mutation';
  updateEmployer: {
    __typename?: 'Employer';
    id: string;
    name: string;
    description?: string | null;
    nonPoachingAgreementsDescription?: string | null;
  };
};

export type EmployerSettingsHiringManagerTab_UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  email: string;
  phoneNumber: string;
};

export type UseEmployerSettingsHiringManagerForm_UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type UseEmployerSettingsHiringManagerForm_UpdateUserMutationVariables =
  Exact<{
    input: UpdateUserInput;
  }>;

export type UseEmployerSettingsHiringManagerForm_UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
};

export type EmployerSettingsPage_MyUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmployerSettingsPage_MyUserQuery = {
  __typename?: 'Query';
  myUser?:
    | {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        email: string;
        phoneNumber: string;
        employer?: {
          __typename?: 'Employer';
          id: string;
          name: string;
          logoUrl?: string | null;
          description?: string | null;
          nonPoachingAgreementsDescription?: string | null;
        } | null;
      }
    | { __typename: 'UserLead' }
    | null;
};

export type UseEmployerInvitationForm_CreateInvitationMutationVariables =
  Exact<{
    input: CreateInvitationInput;
  }>;

export type UseEmployerInvitationForm_CreateInvitationMutation = {
  __typename?: 'Mutation';
  createInvitation: { __typename?: 'Invitation'; id: string; email: string };
};

export type EmployerInvitationsTable_InvitationFragment = {
  __typename?: 'Invitation';
  id: string;
  createdAt: any;
  expirationDate?: any | null;
  email: string;
  status: InvitationStatus;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type UseEmployerInvitationsTable_InvitationsQueryVariables = Exact<{
  filters?: InputMaybe<InvitationsFilters>;
}>;

export type UseEmployerInvitationsTable_InvitationsQuery = {
  __typename?: 'Query';
  invitations: Array<{
    __typename?: 'Invitation';
    id: string;
    createdAt: any;
    expirationDate?: any | null;
    email: string;
    status: InvitationStatus;
    creator: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
};

export type EmployerTeamMember_UserFragment = {
  __typename: 'User';
  id: string;
  email: string;
  isAuthorizedToUseMarketplace: boolean;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
  myOneToOneChatChannelId?: string | null;
  roles: Array<{ __typename?: 'Role'; name: RoleName }>;
};

export type UseEmployerTeamTable_MyEmployerMembersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UseEmployerTeamTable_MyEmployerMembersQuery = {
  __typename?: 'Query';
  myEmployerMembers: Array<{
    __typename: 'User';
    id: string;
    email: string;
    isAuthorizedToUseMarketplace: boolean;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string | null;
    myOneToOneChatChannelId?: string | null;
    roles: Array<{ __typename?: 'Role'; name: RoleName }>;
  }>;
};

export type UseAcceptInvitation_UserFragment = {
  __typename?: 'User';
  id: string;
  mainBusinessType: BusinessType;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  searchFirm?: { __typename?: 'SearchFirm'; id: string; name: string } | null;
};

export type UseAcceptInvitation_AcceptInvitationMutationVariables = Exact<{
  input: AcceptInvitationInput;
}>;

export type UseAcceptInvitation_AcceptInvitationMutation = {
  __typename?: 'Mutation';
  acceptInvitation: {
    __typename?: 'User';
    id: string;
    mainBusinessType: BusinessType;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
    searchFirm?: { __typename?: 'SearchFirm'; id: string; name: string } | null;
  };
};

export type UseIsAuthenticatedOrRedirectToSignUp_PublicInvitationFragment = {
  __typename?: 'PublicInvitation';
  id: string;
  status: InvitationStatus;
  organizationName: string;
  businessType: BusinessType;
};

export type UseMarkInvitationAsOpened_PublicInvitationFragment = {
  __typename?: 'PublicInvitation';
  id: string;
  status: InvitationStatus;
};

export type UseMarkInvitationAsOpened_MarkInvitationAsOpenedMutationVariables =
  Exact<{
    input: MarkInvitationAsOpenedInput;
  }>;

export type UseMarkInvitationAsOpened_MarkInvitationAsOpenedMutation = {
  __typename?: 'Mutation';
  markInvitationAsOpened: {
    __typename?: 'PublicInvitation';
    id: string;
    status: InvitationStatus;
  };
};

export type UsePublicInvitation_PublicInvitationFragment = {
  __typename?: 'PublicInvitation';
  id: string;
  status: InvitationStatus;
  organizationName: string;
  businessType: BusinessType;
};

export type UsePublicInvitation_PublicInvitationQueryVariables = Exact<{
  input: PublicInvitationInput;
}>;

export type UsePublicInvitation_PublicInvitationQuery = {
  __typename?: 'Query';
  publicInvitation: {
    __typename?: 'PublicInvitation';
    id: string;
    status: InvitationStatus;
    organizationName: string;
    businessType: BusinessType;
  };
};

export type OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragment =
  {
    __typename?: 'AnonymizedExperience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
  };

export type OnboardingEmployerProfileSearchPageAnonymizedProfileCard_EducationFragment =
  {
    __typename?: 'Education';
    id: string;
    startedAt?: any | null;
    endedAt?: any | null;
    school: { __typename?: 'School'; id: string; name: string };
  };

export type OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragment =
  {
    __typename?: 'AnonymizedProfile';
    id: string;
    profilePictureUrl?: string | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    experiences: Array<{
      __typename?: 'AnonymizedExperience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
    }>;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
  };

export type OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfileFragment =
  {
    __typename?: 'AnonymizedProfile';
    id: string;
    profilePictureUrl?: string | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    experiences: Array<{
      __typename?: 'AnonymizedExperience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
    }>;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
  };

export type EmployerProfileSearchPageAnonymizedProfileList_PaginatedAnonymizedProfilesQueryVariables =
  Exact<{
    filters?: InputMaybe<AnonymizedProfilesFilters>;
    offsetBasedPaginationParams: OffsetBasedPaginationParams;
  }>;

export type EmployerProfileSearchPageAnonymizedProfileList_PaginatedAnonymizedProfilesQuery =
  {
    __typename?: 'Query';
    paginatedAnonymizedProfiles: {
      __typename?: 'AnonymizedProfileOffsetBasedConnection';
      totalCount: number;
      hasNextPage: boolean;
      nodes: Array<{
        __typename?: 'AnonymizedProfile';
        id: string;
        profilePictureUrl?: string | null;
        urbanArea?: {
          __typename?: 'UrbanArea';
          id: string;
          name: string;
        } | null;
        experiences: Array<{
          __typename?: 'AnonymizedExperience';
          id: string;
          jobTitle: string;
          startedAt?: any | null;
          endedAt?: any | null;
          employer: { __typename?: 'ExperienceEmployer'; name: string };
        }>;
        educations: Array<{
          __typename?: 'Education';
          id: string;
          startedAt?: any | null;
          endedAt?: any | null;
          school: { __typename?: 'School'; id: string; name: string };
        }>;
      }>;
    };
  };

export type CreateMySearchFirmMutationVariables = Exact<{
  input: CreateSearchFirmInput;
}>;

export type CreateMySearchFirmMutation = {
  __typename?: 'Mutation';
  createSearchFirm: { __typename?: 'SearchFirm'; id: string; name: string };
};

export type RecruiterRecruitmentTaskDetailsByJobOfferIdQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type RecruiterRecruitmentTaskDetailsByJobOfferIdQuery = {
  __typename?: 'Query';
  recruiterRecruitmentTaskByJobOfferId: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
    recruiterFlatFeeInBasisPoints?: number | null;
    recruiterSuccessFeeInBasisPoints?: number | null;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      isTerminated: boolean;
      contractType?: string | null;
      title: string;
      description: string;
      profileDescription?: string | null;
      mandatoryProfileAttributes?: string | null;
      technicalSkillsDescription?: string | null;
      typicalProfileUrl?: string | null;
      careerOutlook?: string | null;
      recruitmentProcess?: string | null;
      perksDescription?: string | null;
      recruitmentAdvice?: string | null;
      status: JobOfferStatus;
      motivationalMessageToRecruiters?: string | null;
      location?: string | null;
      minimumRemuneration?: number | null;
      maximumRemuneration?: number | null;
      maximumVariableRemuneration?: number | null;
      currencyCode?: CurrencyIso4217Code | null;
      numberOfPositionsToFill: number;
      minNumberOfYearsOfExperience?: number | null;
      maxNumberOfYearsOfExperience?: number | null;
      remoteWorkPolicy?: RemoteWorkPolicy | null;
      briefingVideoUrl?: string | null;
      videoAskUrl?: string | null;
      hasIncentiveBonus?: boolean | null;
      hasLuncheonVouchers?: boolean | null;
      hasHealthInsurancePlan?: boolean | null;
      latestSuccessFee?: {
        __typename?: 'SuccessFee';
        id: string;
        basisPoints: number;
      } | null;
      employer?: {
        __typename?: 'Employer';
        id: string;
        name: string;
        logoUrl?: string | null;
        description?: string | null;
        nonPoachingAgreementsDescription?: string | null;
      } | null;
      replacedJobApplication?: {
        __typename?: 'JobApplication';
        id: string;
        profile: {
          __typename?: 'Profile';
          id: string;
          firstName: string;
          lastName: string;
          profilePictureUrl?: string | null;
          linkedInProfileUrl?: string | null;
          curriculumVitaeUrl?: string | null;
        };
      } | null;
      likedAnonymizedProfiles: Array<{
        __typename?: 'HiringManagerLikesAnonymizedProfile';
        id: string;
        anonymizedProfile: {
          __typename?: 'AnonymizedProfile';
          id: string;
          firstName: string;
          profilePictureUrl?: string | null;
          urbanArea?: {
            __typename?: 'UrbanArea';
            id: string;
            name: string;
          } | null;
          experiences: Array<{
            __typename?: 'AnonymizedExperience';
            id: string;
            jobTitle: string;
            startedAt?: any | null;
            endedAt?: any | null;
            employer: { __typename?: 'ExperienceEmployer'; name: string };
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
            }> | null;
          }>;
          educations: Array<{
            __typename?: 'Education';
            id: string;
            startedAt?: any | null;
            endedAt?: any | null;
            school: { __typename?: 'School'; id: string; name: string };
          }>;
        };
      }>;
      creator?: {
        __typename: 'User';
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        id: string;
        phoneNumber: string;
        email: string;
        myOneToOneChatChannelId?: string | null;
        employer?: { __typename?: 'Employer'; name: string; id: string } | null;
        activityMetric: {
          __typename?: 'UserActivityMetric';
          jobApplicationsResponseTime?: number | null;
          jobApplicationsResponseRate?: number | null;
        };
      } | null;
      internalManager?: {
        __typename: 'InternalManager';
        id: string;
        phoneNumber: string;
        email: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        myOneToOneChatChannelId?: string | null;
      } | null;
      academicBackgrounds?: Array<{
        __typename?: 'AcademicBackground';
        id: string;
        name: string;
      }> | null;
    };
  };
};

export type RecruitmentTaskDetailsTabContent_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  recruiterFlatFeeInBasisPoints?: number | null;
  recruiterSuccessFeeInBasisPoints?: number | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    isTerminated: boolean;
    contractType?: string | null;
    description: string;
    profileDescription?: string | null;
    mandatoryProfileAttributes?: string | null;
    technicalSkillsDescription?: string | null;
    typicalProfileUrl?: string | null;
    careerOutlook?: string | null;
    recruitmentProcess?: string | null;
    perksDescription?: string | null;
    recruitmentAdvice?: string | null;
    motivationalMessageToRecruiters?: string | null;
    location?: string | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    maximumVariableRemuneration?: number | null;
    currencyCode?: CurrencyIso4217Code | null;
    numberOfPositionsToFill: number;
    minNumberOfYearsOfExperience?: number | null;
    maxNumberOfYearsOfExperience?: number | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    briefingVideoUrl?: string | null;
    videoAskUrl?: string | null;
    hasIncentiveBonus?: boolean | null;
    hasLuncheonVouchers?: boolean | null;
    hasHealthInsurancePlan?: boolean | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      id: string;
      basisPoints: number;
    } | null;
    employer?: {
      __typename?: 'Employer';
      id: string;
      description?: string | null;
      nonPoachingAgreementsDescription?: string | null;
    } | null;
    creator?: {
      __typename: 'User';
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      id: string;
      phoneNumber: string;
      email: string;
      myOneToOneChatChannelId?: string | null;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      activityMetric: {
        __typename?: 'UserActivityMetric';
        jobApplicationsResponseTime?: number | null;
        jobApplicationsResponseRate?: number | null;
      };
    } | null;
    internalManager?: {
      __typename: 'InternalManager';
      id: string;
      phoneNumber: string;
      email: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
    } | null;
    replacedJobApplication?: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        linkedInProfileUrl?: string | null;
        curriculumVitaeUrl?: string | null;
      };
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }> | null;
  };
};

export type RecruitmentTaskJobApplicationsTabContent_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  jobOffer: { __typename?: 'JobOffer'; id: string };
};

export type RecruiterRecruitmentTaskTabs_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  recruiterFlatFeeInBasisPoints?: number | null;
  recruiterSuccessFeeInBasisPoints?: number | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    isTerminated: boolean;
    contractType?: string | null;
    description: string;
    profileDescription?: string | null;
    mandatoryProfileAttributes?: string | null;
    technicalSkillsDescription?: string | null;
    typicalProfileUrl?: string | null;
    careerOutlook?: string | null;
    recruitmentProcess?: string | null;
    perksDescription?: string | null;
    recruitmentAdvice?: string | null;
    motivationalMessageToRecruiters?: string | null;
    location?: string | null;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    maximumVariableRemuneration?: number | null;
    currencyCode?: CurrencyIso4217Code | null;
    numberOfPositionsToFill: number;
    minNumberOfYearsOfExperience?: number | null;
    maxNumberOfYearsOfExperience?: number | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    briefingVideoUrl?: string | null;
    videoAskUrl?: string | null;
    hasIncentiveBonus?: boolean | null;
    hasLuncheonVouchers?: boolean | null;
    hasHealthInsurancePlan?: boolean | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      id: string;
      basisPoints: number;
    } | null;
    likedAnonymizedProfiles: Array<{
      __typename?: 'HiringManagerLikesAnonymizedProfile';
      id: string;
      anonymizedProfile: {
        __typename?: 'AnonymizedProfile';
        id: string;
        firstName: string;
        profilePictureUrl?: string | null;
        urbanArea?: {
          __typename?: 'UrbanArea';
          id: string;
          name: string;
        } | null;
        experiences: Array<{
          __typename?: 'AnonymizedExperience';
          id: string;
          jobTitle: string;
          startedAt?: any | null;
          endedAt?: any | null;
          employer: { __typename?: 'ExperienceEmployer'; name: string };
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
          }> | null;
        }>;
        educations: Array<{
          __typename?: 'Education';
          id: string;
          startedAt?: any | null;
          endedAt?: any | null;
          school: { __typename?: 'School'; id: string; name: string };
        }>;
      };
    }>;
    employer?: {
      __typename?: 'Employer';
      id: string;
      description?: string | null;
      nonPoachingAgreementsDescription?: string | null;
      name: string;
    } | null;
    creator?: {
      __typename: 'User';
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      id: string;
      phoneNumber: string;
      email: string;
      myOneToOneChatChannelId?: string | null;
      employer?: { __typename?: 'Employer'; name: string; id: string } | null;
      activityMetric: {
        __typename?: 'UserActivityMetric';
        jobApplicationsResponseTime?: number | null;
        jobApplicationsResponseRate?: number | null;
      };
    } | null;
    internalManager?: {
      __typename: 'InternalManager';
      id: string;
      phoneNumber: string;
      email: string;
      firstName: string;
      lastName: string;
      profilePictureUrl?: string | null;
      myOneToOneChatChannelId?: string | null;
    } | null;
    replacedJobApplication?: {
      __typename?: 'JobApplication';
      id: string;
      profile: {
        __typename?: 'Profile';
        id: string;
        firstName: string;
        lastName: string;
        profilePictureUrl?: string | null;
        linkedInProfileUrl?: string | null;
        curriculumVitaeUrl?: string | null;
      };
    } | null;
    academicBackgrounds?: Array<{
      __typename?: 'AcademicBackground';
      id: string;
      name: string;
    }> | null;
  };
};

export type RecruiterRecruitmentTaskRelevantProfileCard_SkillFragment = {
  __typename?: 'Skill';
  id: string;
  name: string;
};

export type RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperienceFragment =
  {
    __typename?: 'AnonymizedExperience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
  };

export type RecruiterRecruitmentTaskRelevantProfileCard_EducationFragment = {
  __typename?: 'Education';
  id: string;
  startedAt?: any | null;
  endedAt?: any | null;
  school: { __typename?: 'School'; id: string; name: string };
};

export type RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfileFragment =
  {
    __typename?: 'AnonymizedProfile';
    id: string;
    firstName: string;
    profilePictureUrl?: string | null;
    urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
    experiences: Array<{
      __typename?: 'AnonymizedExperience';
      id: string;
      jobTitle: string;
      startedAt?: any | null;
      endedAt?: any | null;
      employer: { __typename?: 'ExperienceEmployer'; name: string };
      skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
    }>;
    educations: Array<{
      __typename?: 'Education';
      id: string;
      startedAt?: any | null;
      endedAt?: any | null;
      school: { __typename?: 'School'; id: string; name: string };
    }>;
  };

export type RecruitmentTaskRelevantProfileCardList_EmployerFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
};

export type RecruitmentTaskRelevantProfileCardList_AnonymizedProfileFragment = {
  __typename?: 'AnonymizedProfile';
  id: string;
  firstName: string;
  profilePictureUrl?: string | null;
  urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
  experiences: Array<{
    __typename?: 'AnonymizedExperience';
    id: string;
    jobTitle: string;
    startedAt?: any | null;
    endedAt?: any | null;
    employer: { __typename?: 'ExperienceEmployer'; name: string };
    skills?: Array<{ __typename?: 'Skill'; id: string; name: string }> | null;
  }>;
  educations: Array<{
    __typename?: 'Education';
    id: string;
    startedAt?: any | null;
    endedAt?: any | null;
    school: { __typename?: 'School'; id: string; name: string };
  }>;
};

export type RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfileFragment =
  {
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        startedAt?: any | null;
        endedAt?: any | null;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
        }> | null;
      }>;
      educations: Array<{
        __typename?: 'Education';
        id: string;
        startedAt?: any | null;
        endedAt?: any | null;
        school: { __typename?: 'School'; id: string; name: string };
      }>;
    };
  };

export type RecruitmentTaskRelevantProfileCardList_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  likedAnonymizedProfiles: Array<{
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
    anonymizedProfile: {
      __typename?: 'AnonymizedProfile';
      id: string;
      firstName: string;
      profilePictureUrl?: string | null;
      urbanArea?: { __typename?: 'UrbanArea'; id: string; name: string } | null;
      experiences: Array<{
        __typename?: 'AnonymizedExperience';
        id: string;
        jobTitle: string;
        startedAt?: any | null;
        endedAt?: any | null;
        employer: { __typename?: 'ExperienceEmployer'; name: string };
        skills?: Array<{
          __typename?: 'Skill';
          id: string;
          name: string;
        }> | null;
      }>;
      educations: Array<{
        __typename?: 'Education';
        id: string;
        startedAt?: any | null;
        endedAt?: any | null;
        school: { __typename?: 'School'; id: string; name: string };
      }>;
    };
  }>;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
};

export type RecruitmentTaskRelevantProfileCardList_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    likedAnonymizedProfiles: Array<{
      __typename?: 'HiringManagerLikesAnonymizedProfile';
      id: string;
      anonymizedProfile: {
        __typename?: 'AnonymizedProfile';
        id: string;
        firstName: string;
        profilePictureUrl?: string | null;
        urbanArea?: {
          __typename?: 'UrbanArea';
          id: string;
          name: string;
        } | null;
        experiences: Array<{
          __typename?: 'AnonymizedExperience';
          id: string;
          jobTitle: string;
          startedAt?: any | null;
          endedAt?: any | null;
          employer: { __typename?: 'ExperienceEmployer'; name: string };
          skills?: Array<{
            __typename?: 'Skill';
            id: string;
            name: string;
          }> | null;
        }>;
        educations: Array<{
          __typename?: 'Education';
          id: string;
          startedAt?: any | null;
          endedAt?: any | null;
          school: { __typename?: 'School'; id: string; name: string };
        }>;
      };
    }>;
    employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  };
};

export type RecruitmentTaskRelevantProfilesEmptyListDisplay_EmployerFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
};

export type RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTaskFragment =
  {
    __typename?: 'RecruitmentTask';
    id: string;
    jobOffer: {
      __typename?: 'JobOffer';
      id: string;
      likedAnonymizedProfiles: Array<{
        __typename?: 'HiringManagerLikesAnonymizedProfile';
        id: string;
        anonymizedProfile: {
          __typename?: 'AnonymizedProfile';
          id: string;
          firstName: string;
          profilePictureUrl?: string | null;
          urbanArea?: {
            __typename?: 'UrbanArea';
            id: string;
            name: string;
          } | null;
          experiences: Array<{
            __typename?: 'AnonymizedExperience';
            id: string;
            jobTitle: string;
            startedAt?: any | null;
            endedAt?: any | null;
            employer: { __typename?: 'ExperienceEmployer'; name: string };
            skills?: Array<{
              __typename?: 'Skill';
              id: string;
              name: string;
            }> | null;
          }>;
          educations: Array<{
            __typename?: 'Education';
            id: string;
            startedAt?: any | null;
            endedAt?: any | null;
            school: { __typename?: 'School'; id: string; name: string };
          }>;
        };
      }>;
      employer?: { __typename?: 'Employer'; id: string; name: string } | null;
    };
  };

export type RecruiterRecruitmentTaskPageProvider_JobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  likedAnonymizedProfiles: Array<{
    __typename?: 'HiringManagerLikesAnonymizedProfile';
    id: string;
  }>;
};

export type RecruitmentTaskPageProvider_RecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    likedAnonymizedProfiles: Array<{
      __typename?: 'HiringManagerLikesAnonymizedProfile';
      id: string;
    }>;
  };
};

export type RecruiterSettingsUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecruiterSettingsUserQuery = {
  __typename?: 'Query';
  myUser?:
    | {
        __typename: 'User';
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        isAuthorizedToUseMarketplace: boolean;
        profilePictureUrl?: string | null;
        searchFirm?: {
          __typename?: 'SearchFirm';
          id: string;
          name: string;
          description?: string | null;
        } | null;
        roles: Array<{ __typename?: 'Role'; name: RoleName }>;
        recruiterNewJobCategories?: Array<{
          __typename?: 'JobCategory';
          id: string;
          name: string;
        }> | null;
        recruiterActivitySectors: Array<{
          __typename?: 'ActivitySector';
          id: string;
          name: string;
        }>;
      }
    | { __typename: 'UserLead' }
    | null;
};

export const ChatContact_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatContact_UserFragment, unknown>;
export const JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiter' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'phoneNumber' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'roles' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ChatContact_User' },
                  },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragment,
    unknown
  >;
export const JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiter' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'phoneNumber' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'roles' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ChatContact_User' },
                  },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendationFragment,
    unknown
  >;
export const JobOfferRecruitmentTasksTable_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTasksTable_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTasksTable_RecruitmentTaskFragment,
  unknown
>;
export const JobOfferRecruitmentTasksList_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTasksList_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTasksTable_RecruitmentTask',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTasksTable_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTasksList_RecruitmentTaskFragment,
  unknown
>;
export const UseJobOfferAutoStaffing_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useJobOfferAutoStaffing_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferStaffingRecruiterTier' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseJobOfferAutoStaffing_UserFragment, unknown>;
export const JobOfferStatusTag_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferStatusTag_JobOfferFragment, unknown>;
export const JobOfferRecruitmentTasksList_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTasksList_JobOfferFragment,
  unknown
>;
export const JobOfferRecruitmentTaskRecommendationsList_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTaskRecommendationsList_JobOfferFragment,
  unknown
>;
export const JobOfferCurrentStaffingDrawer_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferCurrentStaffingDrawer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTasksList_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferCurrentStaffingDrawer_JobOfferFragment,
  unknown
>;
export const JobOfferLinksPopover_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferCurrentStaffingDrawer_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferCurrentStaffingDrawer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTasksList_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferLinksPopover_JobOfferFragment, unknown>;
export const UseJobOfferSelectionTableColumns_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferSelectionTableColumns_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferCurrentStaffingDrawer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTasksList_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferCurrentStaffingDrawer_JobOffer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferSelectionTableColumns_JobOfferFragment,
  unknown
>;
export const UseStaffingFromRecruiterJobOffersTableColumns_JobOfferFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseStaffingFromRecruiterJobOffersTableColumns_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'recruiterLatestRecruitmentTaskRecommendation',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'recruiterId' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'recruiterId' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterRecruitmentTask' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'recruiterId' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'recruiterId' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferCurrentStaffingDrawer_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferRecruitmentTasksList_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferCurrentStaffingDrawer_JobOffer',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseStaffingFromRecruiterJobOffersTableColumns_JobOfferFragment,
    unknown
  >;
export const ChatMessageBubble_JobApplicationStatusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChatMessageBubble_JobApplicationStatusFragment,
  unknown
>;
export const ChatMessageBubble_StoredFileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatMessageBubble_StoredFileFragment, unknown>;
export const ChatMessageBubble_MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatMessageBubble_MessageFragment, unknown>;
export const UseChatChannel_MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useChatChannel_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseChatChannel_MessageFragment, unknown>;
export const UseChatChannel_MessageOffsetBasedConnectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'useChatChannel_MessageOffsetBasedConnection',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageOffsetBasedConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'useChatChannel_Message' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useChatChannel_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseChatChannel_MessageOffsetBasedConnectionFragment,
  unknown
>;
export const UsePostMessage_MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePostMessage_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsePostMessage_MessageFragment, unknown>;
export const UsePostMessage_MessageOffsetBasedConnectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'usePostMessage_MessageOffsetBasedConnection',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageOffsetBasedConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'usePostMessage_Message' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePostMessage_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsePostMessage_MessageOffsetBasedConnectionFragment,
  unknown
>;
export const UsePostMessage_ChatChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePostMessage_ChatChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChatChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'usePostMessage_Message' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePostMessage_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsePostMessage_ChatChannelFragment, unknown>;
export const UseScrollToLastMessage_MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useScrollToLastMessage_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<UseScrollToLastMessage_MessageFragment, unknown>;
export const ChatChannelHeaderInterlocutor_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelHeaderInterlocutor_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChatChannelHeaderInterlocutor_UserFragment,
  unknown
>;
export const UseChatChannelPreview_MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useChatChannelPreview_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseChatChannelPreview_MessageFragment, unknown>;
export const ChatMessageBubble_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<ChatMessageBubble_UserFragment, unknown>;
export const ChatChannelPreview_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatChannelPreview_UserFragment, unknown>;
export const ChatChannelPreview_JobApplicationStatusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChatChannelPreview_JobApplicationStatusFragment,
  unknown
>;
export const ChatChannelPreview_MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatChannelPreview_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatChannelPreview_MessageFragment, unknown>;
export const ChatChannelPreview_ChatChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_ChatChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChatChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatChannelPreview_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatChannelPreview_Message' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatChannelPreview_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatChannelPreview_ChatChannelFragment, unknown>;
export const UseMyChatChannels_ChatChannelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useMyChatChannels_ChatChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChatChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatChannelPreview_ChatChannel' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatChannelPreview_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_ChatChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChatChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatChannelPreview_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatChannelPreview_Message' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseMyChatChannels_ChatChannelFragment, unknown>;
export const NewJobApplicationStatusCallout_2_JobApplicationStatusFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'NewJobApplicationStatusCallout_2_JobApplicationStatus',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplicationStatus' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profile' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'jobOffer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'title' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    NewJobApplicationStatusCallout_2_JobApplicationStatusFragment,
    unknown
  >;
export const UseRedirectToChatChannel_InternalManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useRedirectToChatChannel_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseRedirectToChatChannel_InternalManagerFragment,
  unknown
>;
export const UseRedirectToChatChannel_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useRedirectToChatChannel_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseRedirectToChatChannel_UserFragment, unknown>;
export const HiringManagerLayout_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringManagerLayout_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HiringManagerLayout_UserFragment, unknown>;
export const JobApplicationStatusMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationStatusMessageFragment, unknown>;
export const JobApplicationStatusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationStatusFragment, unknown>;
export const JobApplicationWithStatusesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationWithStatusesFragment, unknown>;
export const GetJobApplicationLastUpdateAlert_JobApplicationStatusFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplicationStatus' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    GetJobApplicationLastUpdateAlert_JobApplicationStatusFragment,
    unknown
  >;
export const JobApplicationLastUpdateAlert_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationLastUpdateAlert_JobApplicationFragment,
  unknown
>;
export const JobApplicationProfileDrawerHeader_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHeader_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationProfileDrawerHeader_JobApplicationFragment,
  unknown
>;
export const JobOfferHeader_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferHeader_JobOfferFragment, unknown>;
export const JobApplicationProfileDrawerJobOfferSection_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    JobApplicationProfileDrawerJobOfferSection_JobApplicationFragment,
    unknown
  >;
export const JobApplicationProfileDrawerHiringManagerSection_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'JobApplicationProfileDrawerHiringManagerSection_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'FragmentSpread',
                          name: { kind: 'Name', value: 'ChatContact_User' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'email' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'phoneNumber' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    JobApplicationProfileDrawerHiringManagerSection_JobApplicationFragment,
    unknown
  >;
export const JobApplicationProfileDrawerHistorySection_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'message' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'body' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'creator' },
                          selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'firstName' },
                              },
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'lastName' },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    JobApplicationProfileDrawerHistorySection_JobApplicationFragment,
    unknown
  >;
export const EmployerJobOfferTerminationModal_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferTerminationModal_JobOfferFragment,
  unknown
>;
export const UseJobApplicationRejectionForm_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRejectionForm_JobApplicationFragment,
  unknown
>;
export const JobApplicationRejectionModal_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationRejectionModal_JobApplicationFragment,
  unknown
>;
export const RecruiterJobApplicationProfileDrawerFooter_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterJobApplicationProfileDrawerFooter_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationRejectionModal_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRejectionForm_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'email' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationRejectionModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmployerJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'UseJobApplicationRejectionForm_JobApplication',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterJobApplicationProfileDrawerFooter_JobApplicationFragment,
    unknown
  >;
export const ProfileDrawerInfoSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileDrawerInfoSection_ProfileFragment, unknown>;
export const ProfileDrawerAcademicBackgroundsSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerAcademicBackgroundsSection_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerAcademicBackgroundsSection_ProfileFragment,
  unknown
>;
export const ProfileDrawerSkillsSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerSkillsSection_ProfileFragment,
  unknown
>;
export const ProfileDrawerJobCategoriesSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerJobCategoriesSection_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerJobCategoriesSection_ProfileFragment,
  unknown
>;
export const ProfileDrawerEducationsSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerEducationsSection_ProfileFragment,
  unknown
>;
export const ProfileExperience_ExperienceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileExperience_ExperienceFragment, unknown>;
export const ProfileExperiences_ExperienceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperience_Experience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileExperiences_ExperienceFragment, unknown>;
export const ProfileDrawerExperiencesSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerExperiencesSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_Experience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperience_Experience' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerExperiencesSection_ProfileFragment,
  unknown
>;
export const ProfileDrawerPersonalNoteSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerPersonalNoteSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerPersonalNoteSection_ProfileFragment,
  unknown
>;
export const RecruiterJobApplicationProfileDrawer_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationProfileDrawer_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHeader_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobApplicationProfileDrawerJobOfferSection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobApplicationProfileDrawerHiringManagerSection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruiterJobApplicationProfileDrawerFooter_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerInfoSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerAcademicBackgroundsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerSkillsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerJobCategoriesSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerEducationsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerExperiencesSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerPersonalNoteSection_Profile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperience_Experience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHeader_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHiringManagerSection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChatContact_User' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationProfileDrawerFooter_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerAcademicBackgroundsSection_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerJobCategoriesSection_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerExperiencesSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_Experience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerPersonalNoteSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterJobApplicationProfileDrawer_JobApplicationFragment,
  unknown
>;
export const UseJobApplicationSubmissionForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationSubmissionForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentEmployer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationSubmissionForm_ProfileFragment,
  unknown
>;
export const SortByStatus_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SortByStatus_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SortByStatus_JobApplicationFragment, unknown>;
export const UseJobApplicationRecommendation_SuccessFeeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRecommendation_SuccessFee',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SuccessFee' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRecommendation_SuccessFeeFragment,
  unknown
>;
export const UseJobApplicationRecommendation_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationRecommendation_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRecommendation_UserFragment,
  unknown
>;
export const UseJobApplicationRecommendation_InternalManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRecommendation_InternalManager',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRecommendation_InternalManagerFragment,
  unknown
>;
export const UseJobApplicationRecommendation_EmployerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationRecommendation_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRecommendation_EmployerFragment,
  unknown
>;
export const UseJobApplicationRecommendation_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationRecommendation_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobApplicationRecommendation_SuccessFee',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobApplicationRecommendation_User',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobApplicationRecommendation_InternalManager',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobApplicationRecommendation_Employer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRecommendation_SuccessFee',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SuccessFee' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationRecommendation_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRecommendation_InternalManager',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationRecommendation_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRecommendation_JobOfferFragment,
  unknown
>;
export const JobOfferContact_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferContact_UserFragment, unknown>;
export const ActivityMetric_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityMetric_UserFragment, unknown>;
export const ChatContact_InternalManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatContact_InternalManagerFragment, unknown>;
export const JobOfferContact_InternalManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferContact_InternalManagerFragment, unknown>;
export const JobOfferContacts_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferContacts_JobOfferFragment, unknown>;
export const JobOfferBodyMotivationalSection_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferBodyMotivationalSection_JobOfferFragment,
  unknown
>;
export const JobOfferBodyReplacementSection_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferBodyReplacementSection_JobOfferFragment,
  unknown
>;
export const JobOfferBodySummarySection_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferBodySummarySection_JobOfferFragment,
  unknown
>;
export const JobOfferBodyVideoBriefSection_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferBodyVideoBriefSection_JobOfferFragment,
  unknown
>;
export const JobOfferBodyPerksSection_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferBodyPerksSection_JobOfferFragment,
  unknown
>;
export const JobOfferBody_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferBody_JobOfferFragment, unknown>;
export const UseJobApplicationRecommendation_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationRecommendation_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRecommendation_ProfileFragment,
  unknown
>;
export const UseJobApplicationRecommendation_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRecommendation_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRecommendation_RecruitmentTaskFragment,
  unknown
>;
export const UseJobApplicationRecommendation_JobApplicationRecommendationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_JobApplicationRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplicationRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'hasApplied' } },
            { kind: 'Field', name: { kind: 'Name', value: 'score' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOfferMatchingCriteria' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'isMatching' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_JobOffer',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'replacedJobApplication' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_Profile',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_RecruitmentTask',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_SuccessFee',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SuccessFee' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'UseJobApplicationRecommendation_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'activityMetric' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseTime',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseRate',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_InternalManager',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_Employer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Employer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ChatContact_User' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ActivityMetric_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'activityMetric' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseTime',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseRate',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_InternalManager' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ChatContact_InternalManager' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferBodyMotivationalSection_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profilePictureUrl' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferBodyReplacementSection_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profile' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'profilePictureUrl' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'linkedInProfileUrl' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfPositionsToFill' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'remoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'academicBackgrounds' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'briefingVideoUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasIncentiveBonus' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasLuncheonVouchers' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentProcess' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasIncentiveBonus' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasLuncheonVouchers' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'perksDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'briefingVideoUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfPositionsToFill' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profileDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'technicalSkillsDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'typicalProfileUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentAdvice' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'remoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'latestSuccessFee' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_SuccessFee',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_User',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'internalManager' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_InternalManager',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_Employer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferContact_User' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ActivityMetric_User' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'internalManager' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobOfferContact_InternalManager',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profileDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'technicalSkillsDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'typicalProfileUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentProcess' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'perksDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentAdvice' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'description' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'nonPoachingAgreementsDescription',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyMotivationalSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyReplacementSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodySummarySection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyVideoBriefSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyPerksSection_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_Profile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'linkedInProfileUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'curriculumVitaeUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobApplicationRecommendation_JobApplicationRecommendationFragment,
    unknown
  >;
export const UseJobOfferClassificationFromTextForm_JobOfferLeadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferClassificationFromTextForm_JobOfferLead',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOfferLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoardUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferClassificationFromTextForm_JobOfferLeadFragment,
  unknown
>;
export const UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferFragment,
  unknown
>;
export const JobOfferMinimalFormProfileStep_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormProfileStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferMinimalFormProfileStep_JobOfferFragment,
  unknown
>;
export const JobOfferMinimalFormAcademicBackgroundStep_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormAcademicBackgroundStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferMinimalFormAcademicBackgroundStep_JobOfferFragment,
  unknown
>;
export const JobOfferMinimalFormLocationStep_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormLocationStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferMinimalFormLocationStep_JobOfferFragment,
  unknown
>;
export const JobOfferMinimalFormSalaryStep_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferMinimalFormSalaryStep_JobOfferFragment,
  unknown
>;
export const JobOfferMinimalFormSuccessFeeStep_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormSuccessFeeStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferMinimalFormSuccessFeeStep_JobOfferFragment,
  unknown
>;
export const VideoBriefSoloRecordingClickableCard_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VideoBriefSoloRecordingClickableCard_JobOfferFragment,
  unknown
>;
export const VideoBriefExpertRecordingClickableCard_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VideoBriefExpertRecordingClickableCard_JobOfferFragment,
  unknown
>;
export const JobOfferMinimalFormVideoBriefStep_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormVideoBriefStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferMinimalFormVideoBriefStep_JobOfferFragment,
  unknown
>;
export const UseJobOfferMinimalForm_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferMinimalForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormProfileStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormAcademicBackgroundStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormLocationStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormSalaryStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormSuccessFeeStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormVideoBriefStep_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormProfileStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormAcademicBackgroundStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormLocationStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormSuccessFeeStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormVideoBriefStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseJobOfferMinimalForm_JobOfferFragment, unknown>;
export const JobOfferWizardFormSalaryStep_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferWizardFormSalaryStep_JobOfferFragment,
  unknown
>;
export const JobOfferWizardForm_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferWizardFormSalaryStep_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferWizardForm_JobOfferFragment, unknown>;
export const UseJobOfferUpdateForm_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferUpdateForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferWizardForm_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferWizardFormSalaryStep_JobOffer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseJobOfferUpdateForm_JobOfferFragment, unknown>;
export const JobOfferNewsFeed_NewsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferNewsFeed_News' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'News' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferNewsFeed_NewsFragment, unknown>;
export const UseJobOfferNewsFeed_NewsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useJobOfferNewsFeed_News' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'News' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferNewsFeed_News' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferNewsFeed_News' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'News' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseJobOfferNewsFeed_NewsFragment, unknown>;
export const MyUserProvider_UserLeadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyUserProvider_UserLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyUserProvider_UserLeadFragment, unknown>;
export const ProfileEducation_EducationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileEducation_Education' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Education' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileEducation_EducationFragment, unknown>;
export const ProfileEducationsForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileEducationsForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileEducation_Education' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileEducation_Education' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Education' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileEducationsForm_ProfileFragment, unknown>;
export const ProfileExperiencesForm_ExperienceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiencesForm_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileExperiencesForm_ExperienceFragment,
  unknown
>;
export const ProfileExperiencesForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiencesForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiencesForm_Experience',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiencesForm_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileExperiencesForm_ProfileFragment, unknown>;
export const ProfileHeader_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileHeader_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileHeader_ProfileFragment, unknown>;
export const ProfileRequirementsChecklist_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileRequirementsChecklist_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileRequirementsChecklist_ProfileFragment,
  unknown
>;
export const UseUpdateProfileAutoSubmitForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseUpdateProfileAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentEmployer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateProfileAutoSubmitForm_ProfileFragment,
  unknown
>;
export const UseProfileIsOpenToWorkAutoSubmitForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseProfileIsOpenToWorkAutoSubmitForm_ProfileFragment,
  unknown
>;
export const UpdateProfileCareerAutoSubmitForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateProfileCareerAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseUpdateProfileAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseUpdateProfileAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentEmployer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileCareerAutoSubmitForm_ProfileFragment,
  unknown
>;
export const UseProfileAcademicBackgroundsAutoSubmitForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileAcademicBackgroundsAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseProfileAcademicBackgroundsAutoSubmitForm_ProfileFragment,
  unknown
>;
export const UseProfileJobCategoriesAutoSubmitForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileJobCategoriesAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseProfileJobCategoriesAutoSubmitForm_ProfileFragment,
  unknown
>;
export const UseProfileSkillsAutoSubmitForm_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseProfileSkillsAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseProfileSkillsAutoSubmitForm_ProfileFragment,
  unknown
>;
export const ProfileInfo_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileInfo_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileEducationsForm_Profile' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperiencesForm_Profile' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileHeader_Profile' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileRequirementsChecklist_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UpdateProfileCareerAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileAcademicBackgroundsAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileJobCategoriesAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileSkillsAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseUpdateProfileAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileEducation_Education' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Education' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiencesForm_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseUpdateProfileAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentEmployer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileEducationsForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileEducation_Education' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiencesForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiencesForm_Experience',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileHeader_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileRequirementsChecklist_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateProfileCareerAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseUpdateProfileAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileAcademicBackgroundsAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileJobCategoriesAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseProfileSkillsAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileInfo_ProfileFragment, unknown>;
export const RecruiterJobApplicationStatus_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationStatus_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterJobApplicationStatus_JobApplicationFragment,
  unknown
>;
export const RecruiterJobApplicationCard_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationCard_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruiterJobApplicationStatus_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationStatus_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterJobApplicationCard_JobApplicationFragment,
  unknown
>;
export const UseRecruiterJobApplicationsTable_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseRecruiterJobApplicationsTable_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruiterJobApplicationCard_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruiterJobApplicationStatus_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationStatus_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationCard_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruiterJobApplicationStatus_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseRecruiterJobApplicationsTable_JobApplicationFragment,
  unknown
>;
export const RecruiterLayout_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterLayout_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'paymentServiceProviderName',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contracts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterLayout_UserFragment, unknown>;
export const RecruiterSettingsGeneralTab_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsGeneralTab_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterSettingsGeneralTab_UserFragment, unknown>;
export const RecruiterSettingsSpecializationsTab_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsSpecializationsTab_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterNewJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterActivitySectors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSettingsSpecializationsTab_UserFragment,
  unknown
>;
export const RecruiterSuperRecruiter_RecruiterActivityMetricsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterSuperRecruiter_RecruiterActivityMetrics',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruiterActivityMetrics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationMetrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobApplicationMetricsInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfJobApplications' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'interviewConversionRateInBasisPoints',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'hireConversionRateInBasisPoints',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSuperRecruiter_RecruiterActivityMetricsFragment,
  unknown
>;
export const RecruiterSuperRecruiter_UserActivityMetricFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterSuperRecruiter_UserActivityMetric',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserActivityMetric' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterActivityMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSuperRecruiter_RecruiterActivityMetrics',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterSuperRecruiter_RecruiterActivityMetrics',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruiterActivityMetrics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationMetrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobApplicationMetricsInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfJobApplications' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'interviewConversionRateInBasisPoints',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'hireConversionRateInBasisPoints',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSuperRecruiter_UserActivityMetricFragment,
  unknown
>;
export const RecruiterSuperRecruiter_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSuperRecruiter_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSuperRecruiter_UserActivityMetric',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterSuperRecruiter_RecruiterActivityMetrics',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruiterActivityMetrics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationMetrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobApplicationMetricsInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfJobApplications' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'interviewConversionRateInBasisPoints',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'hireConversionRateInBasisPoints',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterSuperRecruiter_UserActivityMetric',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserActivityMetric' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterActivityMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSuperRecruiter_RecruiterActivityMetrics',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterSuperRecruiter_UserFragment, unknown>;
export const RecruitmentTaskWithApplicationsCardHeader_RecruitmentTaskFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskWithApplicationsCardHeader_RecruitmentTaskFragment,
    unknown
  >;
export const UseAfterHiringJobOfferTerminationModal_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseAfterHiringJobOfferTerminationModal_JobOfferFragment,
  unknown
>;
export const AfterHiringJobOfferTerminationModal_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AfterHiringJobOfferTerminationModal_JobApplicationFragment,
  unknown
>;
export const HiringConfirmationModal_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HiringConfirmationModal_JobApplicationFragment,
  unknown
>;
export const AskForFirstInterviewWithoutTemplateModal_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    AskForFirstInterviewWithoutTemplateModal_JobApplicationFragment,
    unknown
  >;
export const UseAskForFirstInterviewWithTemplateModal_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseAskForFirstInterviewWithTemplateModal_JobApplicationFragment,
    unknown
  >;
export const AskForFirstInterviewWithTemplateModal_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AskForFirstInterviewWithTemplateModal_JobApplicationFragment,
  unknown
>;
export const StartApplicantRecruitmentProcessModal_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartApplicantRecruitmentProcessModal_JobApplicationFragment,
  unknown
>;
export const EmployerJobApplicationProfileDrawerFooter_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'HiringConfirmationModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationRejectionModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'StartApplicantRecruitmentProcessModal_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AfterHiringJobOfferTerminationModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRejectionForm_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'email' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'UseAskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AfterHiringJobOfferTerminationModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationRejectionModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmployerJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'UseJobApplicationRejectionForm_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'StartApplicantRecruitmentProcessModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'AskForFirstInterviewWithoutTemplateModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobApplicationProfileDrawerFooter_JobApplicationFragment,
    unknown
  >;
export const ProfileDrawerRecruiterSection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerRecruiterSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerRecruiterSection_ProfileFragment,
  unknown
>;
export const ProfileDrawerRecruiterSection_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerRecruiterSection_RecruitmentTaskFragment,
  unknown
>;
export const EmployerJobApplicationProfileDrawer_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobApplicationProfileDrawer_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHeader_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobApplicationProfileDrawerJobOfferSection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerRecruiterSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerInfoSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerSkillsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerEducationsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerExperiencesSection_Profile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperience_Experience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHeader_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerRecruiterSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerExperiencesSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_Experience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobApplicationProfileDrawer_JobApplicationFragment,
  unknown
>;
export const EmployerJobOfferJobApplicationEditableStatusTag_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'StartApplicantRecruitmentProcessModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationRejectionModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'HiringConfirmationModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'UseAskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRejectionForm_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'email' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AfterHiringJobOfferTerminationModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'StartApplicantRecruitmentProcessModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'AskForFirstInterviewWithoutTemplateModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationRejectionModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmployerJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'UseJobApplicationRejectionForm_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AfterHiringJobOfferTerminationModal_JobApplication',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferJobApplicationEditableStatusTag_JobApplicationFragment,
    unknown
  >;
export const RecruitmentTaskWithApplicationsCard_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskWithApplicationsCard_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobApplicationProfileDrawer_JobApplication',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationLastUpdateAlert_JobApplication',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHeader_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerRecruiterSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperience_Experience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerExperiencesSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_Experience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobApplicationProfileDrawer_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHeader_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobApplicationProfileDrawerJobOfferSection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerRecruiterSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerInfoSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerSkillsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerEducationsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerExperiencesSection_Profile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruitmentTaskWithApplicationsCard_RecruitmentTaskFragment,
  unknown
>;
export const RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTaskFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'RecruitmentTaskWithApplicationsCard_RecruitmentTask',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplicationStatus' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationLastUpdateAlert_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerHeader_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationLastUpdateAlert_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'linkedInProfileUrl' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'phoneNumber' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profilePictureUrl' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'message' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'body' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'creator' },
                          selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'firstName' },
                              },
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'lastName' },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AfterHiringJobOfferTerminationModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AfterHiringJobOfferTerminationModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRejectionForm_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'email' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationRejectionModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmployerJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'UseJobApplicationRejectionForm_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'UseAskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'StartApplicantRecruitmentProcessModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'AskForFirstInterviewWithoutTemplateModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'HiringConfirmationModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationRejectionModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'StartApplicantRecruitmentProcessModal_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerRecruiterSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'phoneNumber' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'roles' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ChatContact_User' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'salaryClaimAmount' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'validSkills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'degreeTitle' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'school' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileExperience_Experience' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Experience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Experience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ProfileExperience_Experience' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerExperiencesSection_Profile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileExperiences_Experience',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobApplicationProfileDrawer_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationProfileDrawerHeader_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'JobApplicationProfileDrawerHistorySection_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'JobApplicationProfileDrawerJobOfferSection_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobApplicationProfileDrawerFooter_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerRecruiterSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerInfoSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerSkillsSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerEducationsSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerExperiencesSection_Profile',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'StartApplicantRecruitmentProcessModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationRejectionModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'HiringConfirmationModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskWithApplicationsCard_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobApplications' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobApplicationProfileDrawer_JobApplication',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobApplicationLastUpdateAlert_JobApplication',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profile' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTaskFragment,
    unknown
  >;
export const UseSchoolsOptions_SchoolFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseSchoolsOptions_School' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'School' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseSchoolsOptions_SchoolFragment, unknown>;
export const UseAccountCreationForm_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useAccountCreationForm_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseAccountCreationForm_UserFragment, unknown>;
export const UseRecruiterProfilesTableColumns_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseRecruiterProfilesTableColumns_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'lastJobApplicationRecommendationsComputation',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseRecruiterProfilesTableColumns_ProfileFragment,
  unknown
>;
export const JobOfferHeaderContainer_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferHeaderContainer_JobOfferFragment, unknown>;
export const RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'isTerminated' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendationFragment,
    unknown
  >;
export const RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobOfferHeaderContainer_JobOffer',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'latestSuccessFee' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'basisPoints' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'isTerminated' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'replacedJobApplication' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferBodyMotivationalSection_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profilePictureUrl' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferBodyReplacementSection_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profile' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'profilePictureUrl' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'linkedInProfileUrl' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfPositionsToFill' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'remoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'academicBackgrounds' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'briefingVideoUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasIncentiveBonus' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasLuncheonVouchers' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ChatContact_User' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ActivityMetric_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'activityMetric' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseTime',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseRate',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_InternalManager' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ChatContact_InternalManager' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profileDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'technicalSkillsDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'typicalProfileUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentProcess' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'perksDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentAdvice' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'description' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'nonPoachingAgreementsDescription',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyMotivationalSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyReplacementSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodySummarySection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyVideoBriefSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyPerksSection_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferContact_User' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ActivityMetric_User' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'internalManager' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobOfferContact_InternalManager',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'isTerminated' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendationFragment,
    unknown
  >;
export const JobOfferPackageTableCell_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPackageTableCell_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferPackageTableCell_JobOfferFragment,
  unknown
>;
export const RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'priorityIndicator' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'logoUrl' },
                        },
                      ],
                    },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'latestSuccessFee' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'basisPoints' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'replacedJobApplication' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobOfferPackageTableCell_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferPackageTableCell_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragment,
    unknown
  >;
export const RecruiterRecruitmentTasksTable_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTasksTable_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priorityIndicator' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isTerminated' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestSuccessFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basisPoints' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChatContact_User' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'replacedJobApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPackageTableCell_JobOffer',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPackageTableCell_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterRecruitmentTasksTable_RecruitmentTaskFragment,
  unknown
>;
export const ActivateMyJobOfferPage_JobOfferLeadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivateMyJobOfferPage_JobOfferLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOfferLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoardUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateMyJobOfferPage_JobOfferLeadFragment,
  unknown
>;
export const SelectedProfileDrawerHeader_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SelectedProfileDrawerHeader_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectedProfileDrawerHeader_AnonymizedProfileFragment,
  unknown
>;
export const ProfileDrawerRecruiterSection_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerRecruiterSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerRecruiterSection_AnonymizedProfileFragment,
  unknown
>;
export const ProfileDrawerInfoSection_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerInfoSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerInfoSection_AnonymizedProfileFragment,
  unknown
>;
export const ProfileDrawerSkillsSection_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerSkillsSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerSkillsSection_AnonymizedProfileFragment,
  unknown
>;
export const ProfileExperience_AnonymizedExperienceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_AnonymizedExperience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileExperience_AnonymizedExperienceFragment,
  unknown
>;
export const ProfileExperiences_AnonymizedExperienceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_AnonymizedExperience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileExperience_AnonymizedExperience',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_AnonymizedExperience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileExperiences_AnonymizedExperienceFragment,
  unknown
>;
export const ProfileDrawerExperiencesSection_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerExperiencesSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_AnonymizedExperience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_AnonymizedExperience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_AnonymizedExperience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileExperience_AnonymizedExperience',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerExperiencesSection_AnonymizedProfileFragment,
  unknown
>;
export const ProfileDrawerEducationsSection_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerEducationsSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileDrawerEducationsSection_AnonymizedProfileFragment,
  unknown
>;
export const SelectedProfileDrawer_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SelectedProfileDrawer_AnonymizedProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'SelectedProfileDrawerHeader_AnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileDrawerRecruiterSection_AnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileDrawerInfoSection_AnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileDrawerSkillsSection_AnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileDrawerExperiencesSection_AnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileDrawerEducationsSection_AnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_AnonymizedExperience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_AnonymizedExperience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileExperience_AnonymizedExperience',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SelectedProfileDrawerHeader_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerRecruiterSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerInfoSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerSkillsSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerExperiencesSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_AnonymizedExperience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerEducationsSection_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectedProfileDrawer_AnonymizedProfileFragment,
  unknown
>;
export const EmployerJobOfferManagementPopover_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferManagementPopover_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferTerminationModal_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferManagementPopover_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferHeader_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flatFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferManagementPopover_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferManagementPopover_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferTerminationModal_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobOfferHeader_JobOfferFragment, unknown>;
export const EmployerJobOfferTimeline_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferTimeline_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'totalNumberOfAcceptedForFirstInterviewJobApplications',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfHiredJobApplications' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNewJobApplications' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferTimeline_JobOfferFragment,
  unknown
>;
export const CheckIfJobOfferBriefIsRecorded_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckIfJobOfferBriefIsRecorded_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIfJobOfferBriefIsRecorded_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferGoToProfileSearchButton_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferGoToProfileSearchButton_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferJobApplicationsTabContent_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'CheckIfJobOfferBriefIsRecorded_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckIfJobOfferBriefIsRecorded_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferJobApplicationsTabContent_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferRecruitmentTasksList_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferRecruitmentTasksList_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferRecruitersTabContent_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitersTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferRecruitersTabContent_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferPreview_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobOfferPreview_JobOfferFragment, unknown>;
export const EmployerJobOfferPreviewTabContent_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferPreviewTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferPreviewTabContent_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfileFragment,
    unknown
  >;
export const EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfileFragment,
    unknown
  >;
export const EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_UserFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_UserFragment,
    unknown
  >;
export const EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragmentFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value:
                              'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragmentFragment,
    unknown
  >;
export const EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfileFragment,
    unknown
  >;
export const EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value:
                              'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfileFragment,
    unknown
  >;
export const EmployerJobOfferLikedAnonymizedProfilesTable_JobOfferFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value:
                              'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesTable_JobOfferFragment,
    unknown
  >;
export const EmployerJobOfferSelectedProfilesTabContent_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferSelectedProfilesTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferSelectedProfilesTabContent_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferTabs_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferTabs_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferJobApplicationsTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferRecruitersTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferPreviewTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferSelectedProfilesTabContent_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckIfJobOfferBriefIsRecorded_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'CheckIfJobOfferBriefIsRecorded_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitersTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferPreviewTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferSelectedProfilesTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobOfferTabs_JobOfferFragment, unknown>;
export const EmployerJobOfferPageProvider_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPageProvider_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferPageProvider_JobOfferFragment,
  unknown
>;
export const EmployerJobOfferDashboard_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferDashboard_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferHeader_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferTimeline_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferTabs_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferPageProvider_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferManagementPopover_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferTerminationModal_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckIfJobOfferBriefIsRecorded_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'CheckIfJobOfferBriefIsRecorded_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitersTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferPreviewTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferSelectedProfilesTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flatFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferManagementPopover_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferTimeline_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'totalNumberOfAcceptedForFirstInterviewJobApplications',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfHiredJobApplications' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNewJobApplications' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferTabs_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferJobApplicationsTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferRecruitersTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferPreviewTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferSelectedProfilesTabContent_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPageProvider_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferDashboard_JobOfferFragment,
  unknown
>;
export const UseBookVideoBriefMeeting_VideoBriefMeetingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseBookVideoBriefMeeting_VideoBriefMeeting',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoBriefMeeting' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoBriefMeeting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBookVideoBriefMeeting_VideoBriefMeetingFragment,
  unknown
>;
export const JobApplicationProfileNameCell_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileNameCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationProfileNameCell_JobApplicationFragment,
  unknown
>;
export const JobApplicationProfileCareerCell_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileCareerCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationProfileCareerCell_JobApplicationFragment,
  unknown
>;
export const JobApplicationRecruiterCell_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterCell_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationRecruiterCell_UserFragment, unknown>;
export const JobApplicationRecruiterDetailsModal_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterDetailsModal_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationRecruiterDetailsModal_UserFragment,
  unknown
>;
export const JobApplicationRecruiterCell_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRecruiterCell_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobApplicationRecruiterCell_RecruitmentTaskFragment,
  unknown
>;
export const EmployerJobOfferJobApplicationsTable_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsTable_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileNameCell_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileCareerCell_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobApplicationRecruiterCell_User',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobApplicationRecruiterDetailsModal_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruiterCell_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileNameCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileCareerCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterCell_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterDetailsModal_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRecruiterCell_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferJobApplicationsTable_JobApplicationFragment,
  unknown
>;
export const EmployerJobOfferJobApplicationsCards_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsCards_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferJobApplicationsCards_JobApplicationFragment,
  unknown
>;
export const EmployerJobOfferJobApplicationsList_JobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsList_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferJobApplicationsTable_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferJobApplicationsCards_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileNameCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileCareerCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterCell_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterDetailsModal_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRecruiterCell_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsTable_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileNameCell_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileCareerCell_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobApplicationRecruiterCell_User',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobApplicationRecruiterDetailsModal_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruiterCell_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsCards_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferJobApplicationsList_JobApplicationFragment,
  unknown
>;
export const UseEmployerJobOfferRecruitmentTasksTable_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerJobOfferRecruitmentTasksTable_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerJobOfferRecruitmentTasksTable_UserFragment,
  unknown
>;
export const UseEmployerJobOfferRecruitmentTasksTable_JobApplicationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseEmployerJobOfferRecruitmentTasksTable_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseEmployerJobOfferRecruitmentTasksTable_JobApplicationFragment,
    unknown
  >;
export const UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTaskFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiter' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseEmployerJobOfferRecruitmentTasksTable_User',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobApplications' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UseEmployerJobOfferRecruitmentTasksTable_JobApplication',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseEmployerJobOfferRecruitmentTasksTable_User',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ChatContact_User' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseEmployerJobOfferRecruitmentTasksTable_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTaskFragment,
    unknown
  >;
export const EmployerJobOfferLeads_JobOfferLeadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferLeads_JobOfferLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOfferLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoardUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferLeads_JobOfferLeadFragment,
  unknown
>;
export const EmployerJobOffersTableColumns_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOffersTableColumns_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNewJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOffersTableColumns_JobOfferFragment,
  unknown
>;
export const EmployerProfileSearchPageAnonymizedProfileCard_SkillFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerProfileSearchPageAnonymizedProfileCard_SkillFragment,
  unknown
>;
export const EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragment,
    unknown
  >;
export const EmployerProfileSearchPageAnonymizedProfileCard_EducationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerProfileSearchPageAnonymizedProfileCard_EducationFragment,
    unknown
  >;
export const EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragment,
    unknown
  >;
export const EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfileFragment,
    unknown
  >;
export const EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfileFragment,
    unknown
  >;
export const UseJobOfferSelectionForm_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferSelectionForm_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferSelectionForm_AnonymizedProfileFragment,
  unknown
>;
export const UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobOfferSelectionForm_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobOfferSelectionForm_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfileFragment,
    unknown
  >;
export const UseJobOfferSelectionForm_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferSelectionForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferSelectionForm_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferSelectionForm_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferSelectionForm_JobOfferFragment,
  unknown
>;
export const UseProfileSearchSelectionSubmit_AnonymizedProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'useProfileSearchSelectionSubmit_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UseProfileSearchSelectionSubmit_AnonymizedProfileFragment,
  unknown
>;
export const UseEmployerSettingsEmployerForm_EmployerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseEmployerSettingsEmployerForm_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerSettingsEmployerForm_EmployerFragment,
  unknown
>;
export const EmployerSettingsEmployerTab_EmployerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerSettingsEmployerTab_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseEmployerSettingsEmployerForm_Employer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseEmployerSettingsEmployerForm_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerSettingsEmployerTab_EmployerFragment,
  unknown
>;
export const UseEmployerSettingsHiringManagerForm_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerSettingsHiringManagerForm_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerSettingsHiringManagerForm_UserFragment,
  unknown
>;
export const EmployerSettingsHiringManagerTab_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerSettingsHiringManagerTab_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseEmployerSettingsHiringManagerForm_User',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerSettingsHiringManagerForm_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerSettingsHiringManagerTab_UserFragment,
  unknown
>;
export const EmployerInvitationsTable_InvitationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerInvitationsTable_Invitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Invitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerInvitationsTable_InvitationFragment,
  unknown
>;
export const EmployerTeamMember_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerTeamMember_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerTeamMember_UserFragment, unknown>;
export const UseAcceptInvitation_UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useAcceptInvitation_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseAcceptInvitation_UserFragment, unknown>;
export const UseIsAuthenticatedOrRedirectToSignUp_PublicInvitationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'useIsAuthenticatedOrRedirectToSignUp_PublicInvitation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'PublicInvitation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'organizationName' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'businessType' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseIsAuthenticatedOrRedirectToSignUp_PublicInvitationFragment,
    unknown
  >;
export const UseMarkInvitationAsOpened_PublicInvitationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'useMarkInvitationAsOpened_PublicInvitation',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseMarkInvitationAsOpened_PublicInvitationFragment,
  unknown
>;
export const UsePublicInvitation_PublicInvitationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePublicInvitation_PublicInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsePublicInvitation_PublicInvitationFragment,
  unknown
>;
export const OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragment,
    unknown
  >;
export const OnboardingEmployerProfileSearchPageAnonymizedProfileCard_EducationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    OnboardingEmployerProfileSearchPageAnonymizedProfileCard_EducationFragment,
    unknown
  >;
export const OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragment,
    unknown
  >;
export const OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfileFragment,
    unknown
  >;
export const RecruiterRecruitmentTaskPageProvider_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskPageProvider_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterRecruitmentTaskPageProvider_JobOfferFragment,
  unknown
>;
export const RecruitmentTaskPageProvider_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskPageProvider_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterRecruitmentTaskPageProvider_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskPageProvider_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruitmentTaskPageProvider_RecruitmentTaskFragment,
  unknown
>;
export const RecruitmentTaskDetailsTabContent_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskDetailsTabContent_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isTerminated' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestSuccessFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basisPoints' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruitmentTaskDetailsTabContent_RecruitmentTaskFragment,
  unknown
>;
export const RecruitmentTaskJobApplicationsTabContent_RecruitmentTaskFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskJobApplicationsTabContent_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskJobApplicationsTabContent_RecruitmentTaskFragment,
    unknown
  >;
export const RecruiterRecruitmentTaskRelevantProfileCard_SkillFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterRecruitmentTaskRelevantProfileCard_SkillFragment,
  unknown
>;
export const RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperienceFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperienceFragment,
    unknown
  >;
export const RecruiterRecruitmentTaskRelevantProfileCard_EducationFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterRecruitmentTaskRelevantProfileCard_EducationFragment,
    unknown
  >;
export const RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfileFragment,
    unknown
  >;
export const RecruitmentTaskRelevantProfileCardList_AnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskRelevantProfileCardList_AnonymizedProfileFragment,
    unknown
  >;
export const RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfileFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfileFragment,
    unknown
  >;
export const RecruitmentTaskRelevantProfilesEmptyListDisplay_EmployerFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Employer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskRelevantProfilesEmptyListDisplay_EmployerFragment,
    unknown
  >;
export const RecruitmentTaskRelevantProfileCardList_EmployerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruitmentTaskRelevantProfileCardList_EmployerFragment,
  unknown
>;
export const RecruitmentTaskRelevantProfileCardList_JobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruitmentTaskRelevantProfileCardList_Employer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Education' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruitmentTaskRelevantProfileCardList_JobOfferFragment,
  unknown
>;
export const RecruitmentTaskRelevantProfileCardList_RecruitmentTaskFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Employer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_Employer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Employer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'RecruitmentTaskRelevantProfileCardList_Employer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitmentTaskRelevantProfileCardList_RecruitmentTaskFragment,
    unknown
  >;
export const RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTaskFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'RecruitmentTaskRelevantProfileCardList_RecruitmentTask',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Employer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_Employer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Employer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'RecruitmentTaskRelevantProfileCardList_Employer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskRelevantProfileCardList_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTaskFragment,
    unknown
  >;
export const RecruiterRecruitmentTaskTabs_RecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskTabs_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskPageProvider_RecruitmentTask',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskDetailsTabContent_RecruitmentTask',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskJobApplicationsTabContent_RecruitmentTask',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskPageProvider_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Education' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruitmentTaskRelevantProfileCardList_Employer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskPageProvider_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterRecruitmentTaskPageProvider_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskDetailsTabContent_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isTerminated' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestSuccessFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basisPoints' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskJobApplicationsTabContent_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskRelevantProfileCardList_RecruitmentTask',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterRecruitmentTaskTabs_RecruitmentTaskFragment,
  unknown
>;
export const UseAcademicBackgroundOptions_AcademicBackgroundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'useAcademicBackgroundOptions_academicBackgrounds',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseAcademicBackgroundOptions_AcademicBackgroundsQuery,
  UseAcademicBackgroundOptions_AcademicBackgroundsQueryVariables
>;
export const JobOfferRecruitmentTaskRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobOfferRecruitmentTaskRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RecruitmentTaskRecommendationsFilters',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'jobOfferRecruitmentTaskRecommendations',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTaskRecommendationsQuery,
  JobOfferRecruitmentTaskRecommendationsQueryVariables
>;
export const JobOfferRecruitmentTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobOfferRecruitmentTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferRecruitmentTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferRecruitmentTasksList_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTasksTable_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTasksList_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTasksTable_RecruitmentTask',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTasksQuery,
  JobOfferRecruitmentTasksQueryVariables
>;
export const JobOfferSelectionTable_PaginatedJobOffersQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'JobOfferSelectionTable_paginatedJobOffersQuery',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PaginatedJobOffersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedJobOffers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'UseJobOfferSelectionTableColumns_JobOffer',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferCurrentStaffingDrawer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTasksList_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferCurrentStaffingDrawer_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferSelectionTableColumns_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferSelectionTable_PaginatedJobOffersQueryQuery,
  JobOfferSelectionTable_PaginatedJobOffersQueryQueryVariables
>;
export const RecruitersCopySelectionFromJobOfferTable_PaginatedJobOffersQueryDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'RecruitersCopySelectionFromJobOfferTable_paginatedJobOffersQuery',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PaginatedJobOffersInput' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'paginatedJobOffers' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hasNextPage' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'totalCount' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'nodes' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value: 'UseJobOfferSelectionTableColumns_JobOffer',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferCurrentStaffingDrawer_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferRecruitmentTasksList_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferCurrentStaffingDrawer_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobOfferSelectionTableColumns_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobCategory' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruitersCopySelectionFromJobOfferTable_PaginatedJobOffersQueryQuery,
    RecruitersCopySelectionFromJobOfferTable_PaginatedJobOffersQueryQueryVariables
  >;
export const StaffJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'staffJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StaffJobOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staffJobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'recruiterId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recruiterEmail' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StaffJobOfferMutation,
  StaffJobOfferMutationVariables
>;
export const UseJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInputDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'useJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInput',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value:
                    'JobOfferStaffingStandardStrategySelectedRecruitersInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'jobOfferStaffingStandardStrategySelectedRecruiters',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'useJobOfferAutoStaffing_User',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'useJobOfferAutoStaffing_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOfferStaffingRecruiterTier' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInputQuery,
    UseJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInputQueryVariables
  >;
export const UseJobOfferAutoStaffingExploratory_JobOfferStaffingExploratoryStrategySelectedRecruitersDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'useJobOfferAutoStaffingExploratory_jobOfferStaffingExploratoryStrategySelectedRecruiters',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value:
                    'JobOfferStaffingExploratoryStrategySelectedRecruitersInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'jobOfferStaffingExploratoryStrategySelectedRecruiters',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'useJobOfferAutoStaffing_User',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'useJobOfferAutoStaffing_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOfferStaffingRecruiterTier' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferAutoStaffingExploratory_JobOfferStaffingExploratoryStrategySelectedRecruitersQuery,
    UseJobOfferAutoStaffingExploratory_JobOfferStaffingExploratoryStrategySelectedRecruitersQueryVariables
  >;
export const UseJobOfferAutoStaffingCopy_JobOfferStaffingCopyStrategySelectedRecruitersDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'useJobOfferAutoStaffingCopy_jobOfferStaffingCopyStrategySelectedRecruiters',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'JobOfferStaffingCopyStrategySelectedRecruitersInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'jobOfferStaffingCopyStrategySelectedRecruiters',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'useJobOfferAutoStaffing_User',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'useJobOfferAutoStaffing_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'roles' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOfferStaffingRecruiterTier' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferAutoStaffingCopy_JobOfferStaffingCopyStrategySelectedRecruitersQuery,
    UseJobOfferAutoStaffingCopy_JobOfferStaffingCopyStrategySelectedRecruitersQueryVariables
  >;
export const StaffRecruiterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'staffRecruiter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StaffRecruiterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staffRecruiter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobOfferId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOfferTitle' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StaffRecruiterMutation,
  StaffRecruiterMutationVariables
>;
export const StaffingFromRecruiterJobOffersTable_PaginatedJobOffersQueryDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'StaffingFromRecruiterJobOffersTable_paginatedJobOffersQuery',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PaginatedJobOffersInput' },
              },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'recruiterId' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'paginatedJobOffers' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hasNextPage' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'totalCount' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'nodes' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value:
                              'UseStaffingFromRecruiterJobOffersTableColumns_JobOffer',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferRecruitmentTasksList_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferCurrentStaffingDrawer_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferRecruitmentTasksList_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferRecruitmentTaskRecommendationsList_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferCurrentStaffingDrawer_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseStaffingFromRecruiterJobOffersTableColumns_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'recruiterLatestRecruitmentTaskRecommendation',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'recruiterId' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'recruiterId' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterRecruitmentTask' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'recruiterId' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'recruiterId' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferLinksPopover_JobOffer' },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    StaffingFromRecruiterJobOffersTable_PaginatedJobOffersQueryQuery,
    StaffingFromRecruiterJobOffersTable_PaginatedJobOffersQueryQueryVariables
  >;
export const EmployerJobOffersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employerJobOffers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmployerJobOffersFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employerPaginatedJobOffers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOffersQuery,
  EmployerJobOffersQueryVariables
>;
export const MyRecruiterActivityInformationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myRecruiterActivityInformation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'User' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'recruiterActivitySectors',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'recruiterDeprecatedJobCategories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'recruiterNewJobCategories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyRecruiterActivityInformationQuery,
  MyRecruiterActivityInformationQueryVariables
>;
export const UseChatChannel_MessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useChatChannel_messages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetMessagesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'useChatChannel_MessageOffsetBasedConnection',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useChatChannel_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'useChatChannel_MessageOffsetBasedConnection',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageOffsetBasedConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'useChatChannel_Message' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseChatChannel_MessagesQuery,
  UseChatChannel_MessagesQueryVariables
>;
export const UsePostMessage_MessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'usePostMessage_messages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetMessagesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'usePostMessage_MessageOffsetBasedConnection',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePostMessage_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'usePostMessage_MessageOffsetBasedConnection',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageOffsetBasedConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'usePostMessage_Message' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsePostMessage_MessagesQuery,
  UsePostMessage_MessagesQueryVariables
>;
export const UsePostMessage_PostMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'usePostMessage_postMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'usePostMessage_Message' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoredFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatMessageBubble_JobApplicationStatus',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatMessageBubble_StoredFile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePostMessage_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatMessageBubble_Message' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsePostMessage_PostMessageMutation,
  UsePostMessage_PostMessageMutationVariables
>;
export const UseUploadMessageFile_MessageFileUploadUrlAndFileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'useUploadMessageFile_messageFileUploadUrlAndFileName',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'MessageFileUploadUrlAndFileNameInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageFileUploadUrlAndFileName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awsS3Key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUploadMessageFile_MessageFileUploadUrlAndFileNameQuery,
  UseUploadMessageFile_MessageFileUploadUrlAndFileNameQueryVariables
>;
export const UseChatChannelPreview_MarkMessagesAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useChatChannelPreview_markMessagesAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkMessagesAsReadInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markMessagesAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'useChatChannelPreview_Message',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useChatChannelPreview_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseChatChannelPreview_MarkMessagesAsReadMutation,
  UseChatChannelPreview_MarkMessagesAsReadMutationVariables
>;
export const UseMessageToJobOfferRecruitersModal_SendOneToOneMessageToJobOfferRecruitersDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value:
            'useMessageToJobOfferRecruitersModal_sendOneToOneMessageToJobOfferRecruiters',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'SendOneToOneMessageToJobOfferRecruitersInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'sendOneToOneMessageToJobOfferRecruiters',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseMessageToJobOfferRecruitersModal_SendOneToOneMessageToJobOfferRecruitersMutation,
    UseMessageToJobOfferRecruitersModal_SendOneToOneMessageToJobOfferRecruitersMutationVariables
  >;
export const UseMyChatChannels_MyChatChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useMyChatChannels_myChatChannels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetMyChatChannelsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myChatChannels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'useMyChatChannels_ChatChannel',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'myReadStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatChannelPreview_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelPreview_ChatChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChatChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatChannelPreview_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatChannelPreview_Message' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useMyChatChannels_ChatChannel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChatChannel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatChannelPreview_ChatChannel' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseMyChatChannels_MyChatChannelsQuery,
  UseMyChatChannels_MyChatChannelsQueryVariables
>;
export const UseChatChannelInterlocutors_ChatChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useChatChannelInterlocutors_chatChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ChatChannelHeaderInterlocutor_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatChannelHeaderInterlocutor_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseChatChannelInterlocutors_ChatChannelQuery,
  UseChatChannelInterlocutors_ChatChannelQueryVariables
>;
export const UseRedirectToChatChannel_OpenChatChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useRedirectToChatChannel_openChatChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OpenChatChannelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openChatChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseRedirectToChatChannel_OpenChatChannelMutation,
  UseRedirectToChatChannel_OpenChatChannelMutationVariables
>;
export const UseUnreadChatMessagesCount_UnreadMessagesCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'useUnreadChatMessagesCount_unreadMessagesCount',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unreadMessagesCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUnreadChatMessagesCount_UnreadMessagesCountQuery,
  UseUnreadChatMessagesCount_UnreadMessagesCountQueryVariables
>;
export const ApolloQueryMockFactory_JobOfferQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'apolloQueryMockFactory_jobOfferQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApolloQueryMockFactory_JobOfferQueryQuery,
  ApolloQueryMockFactory_JobOfferQueryQueryVariables
>;
export const CompanyLogoUploadUrlAndFileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companyLogoUploadUrlAndFileName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CompanyLogoUploadUrlAndFileNameInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyLogoUploadUrlAndFileName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awsS3Key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyLogoUploadUrlAndFileNameQuery,
  CompanyLogoUploadUrlAndFileNameQueryVariables
>;
export const UpdateMyEmployerLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMyEmployerLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMyEmployerLogoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyEmployerLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMyEmployerLogoMutation,
  UpdateMyEmployerLogoMutationVariables
>;
export const UseEmployerJobApplicationProfileDrawer_JobApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'useEmployerJobApplicationProfileDrawer_jobApplication',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobApplicationProfileDrawer_JobApplication',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHeader_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerRecruiterSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatContact_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperience_Experience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerExperiencesSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_Experience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobApplicationProfileDrawer_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHeader_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobApplicationProfileDrawerJobOfferSection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerRecruiterSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerInfoSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerSkillsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerEducationsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerExperiencesSection_Profile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerJobApplicationProfileDrawer_JobApplicationQuery,
  UseEmployerJobApplicationProfileDrawer_JobApplicationQueryVariables
>;
export const UseRecruiterJobApplicationProfileDrawer_JobApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'useRecruiterJobApplicationProfileDrawer_jobApplication',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruiterJobApplicationProfileDrawer_JobApplication',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHeader_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHiringManagerSection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChatContact_User' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationProfileDrawerFooter_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerAcademicBackgroundsSection_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileDrawerJobCategoriesSection_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperience_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperience_Experience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerExperiencesSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiences_Experience',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileDrawerPersonalNoteSection_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationProfileDrawer_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHeader_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobApplicationProfileDrawerJobOfferSection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'JobApplicationProfileDrawerHiringManagerSection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruiterJobApplicationProfileDrawerFooter_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerInfoSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerAcademicBackgroundsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerSkillsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerJobCategoriesSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerEducationsSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerExperiencesSection_Profile',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileDrawerPersonalNoteSection_Profile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseRecruiterJobApplicationProfileDrawer_JobApplicationQuery,
  UseRecruiterJobApplicationProfileDrawer_JobApplicationQueryVariables
>;
export const UseJobApplicationSubmissionForm_UpdateProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useJobApplicationSubmissionForm_updateProfileMutation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobApplicationSubmissionForm_Profile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobApplicationSubmissionForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentEmployer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationSubmissionForm_UpdateProfileMutationMutation,
  UseJobApplicationSubmissionForm_UpdateProfileMutationMutationVariables
>;
export const UseJobApplicationRecommendation_JobApplicationRecommendationQueryDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'useJobApplicationRecommendation_jobApplicationRecommendationQuery',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'GetJobApplicationRecommendationInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobApplicationRecommendation' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UseJobApplicationRecommendation_JobApplicationRecommendation',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_SuccessFee',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'SuccessFee' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'UseJobApplicationRecommendation_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'activityMetric' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseTime',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseRate',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_InternalManager',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_Employer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Employer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentProcess' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasIncentiveBonus' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasLuncheonVouchers' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'perksDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'briefingVideoUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfPositionsToFill' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profileDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'technicalSkillsDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'typicalProfileUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentAdvice' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'remoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'latestSuccessFee' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_SuccessFee',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_User',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'internalManager' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_InternalManager',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_Employer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ChatContact_User' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ActivityMetric_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'activityMetric' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseTime',
                    },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'jobApplicationsResponseRate',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_InternalManager' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InternalManager' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ChatContact_InternalManager' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferContact_User' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ActivityMetric_User' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'internalManager' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobOfferContact_InternalManager',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferBodyMotivationalSection_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profilePictureUrl' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobOfferBodyReplacementSection_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profile' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'profilePictureUrl' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'linkedInProfileUrl' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfPositionsToFill' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'remoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'academicBackgrounds' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'briefingVideoUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasIncentiveBonus' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasLuncheonVouchers' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profileDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'technicalSkillsDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'typicalProfileUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentProcess' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'perksDescription' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentAdvice' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'description' },
                  },
                  {
                    kind: 'Field',
                    name: {
                      kind: 'Name',
                      value: 'nonPoachingAgreementsDescription',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyMotivationalSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyReplacementSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodySummarySection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyVideoBriefSection_JobOffer',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobOfferBodyPerksSection_JobOffer',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_Profile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'linkedInProfileUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'curriculumVitaeUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRecommendation_JobApplicationRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplicationRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'hasApplied' } },
            { kind: 'Field', name: { kind: 'Name', value: 'score' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOfferMatchingCriteria' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'isMatching' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_JobOffer',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'replacedJobApplication' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_Profile',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseJobApplicationRecommendation_RecruitmentTask',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobApplicationRecommendation_JobApplicationRecommendationQueryQuery,
    UseJobApplicationRecommendation_JobApplicationRecommendationQueryQueryVariables
  >;
export const UseJobApplicationRecommendationAcceptationModal_TakeRecruitmentTaskViaJobApplicationRecommendationMutationDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value:
            'useJobApplicationRecommendationAcceptationModal_takeRecruitmentTaskViaJobApplicationRecommendationMutation',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value:
                    'TakeRecruitmentTaskViaJobApplicationRecommendationInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'takeRecruitmentTaskViaJobApplicationRecommendation',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobApplicationRecommendationAcceptationModal_TakeRecruitmentTaskViaJobApplicationRecommendationMutationMutation,
    UseJobApplicationRecommendationAcceptationModal_TakeRecruitmentTaskViaJobApplicationRecommendationMutationMutationVariables
  >;
export const TerminateJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'terminateJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TerminateJobOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminateJobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isTerminated' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TerminateJobOfferMutation,
  TerminateJobOfferMutationVariables
>;
export const DeclareApplicantHiredByMyCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'declareApplicantHiredByMyCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeclareApplicantHiredByMyCompanyInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declareApplicantHiredByMyCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeclareApplicantHiredByMyCompanyMutation,
  DeclareApplicantHiredByMyCompanyMutationVariables
>;
export const RejectJobApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'rejectJobApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RejectJobApplicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectJobApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creator' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectJobApplicationMutation,
  RejectJobApplicationMutationVariables
>;
export const UseJobApplicationRejectionForm_PostJobOfferNewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useJobApplicationRejectionForm_postJobOfferNews',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostJobOfferNewsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postJobOfferNews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobApplicationRejectionForm_PostJobOfferNewsMutation,
  UseJobApplicationRejectionForm_PostJobOfferNewsMutationVariables
>;
export const JobCategories_UseAutoCompleteJobCategoryOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'jobCategories_useAutoCompleteJobCategoryOptions',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobCategoriesFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobCategories_UseAutoCompleteJobCategoryOptionsQuery,
  JobCategories_UseAutoCompleteJobCategoryOptionsQueryVariables
>;
export const JobCategories_UseJobCategoryOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobCategories_useJobCategoryOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobCategoriesFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobCategories_UseJobCategoryOptionsQuery,
  JobCategories_UseJobCategoryOptionsQueryVariables
>;
export const UseJobOfferClassificationFromTextForm_EmployerJobOfferLeadDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'UseJobOfferClassificationFromTextForm_employerJobOfferLead',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'jobOfferLeadId' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employerJobOfferLead' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'jobOfferLeadId' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'jobOfferLeadId' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'UseJobOfferClassificationFromTextForm_JobOfferLead',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobOfferClassificationFromTextForm_JobOfferLead',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOfferLead' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobBoard' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobBoardUrl' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferClassificationFromTextForm_EmployerJobOfferLeadQuery,
    UseJobOfferClassificationFromTextForm_EmployerJobOfferLeadQueryVariables
  >;
export const UseJobOfferClassificationFromTextForm_ClassifyAndCreateJobOfferDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value:
            'UseJobOfferClassificationFromTextForm_classifyAndCreateJobOffer',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClassifyAndCreateJobOfferInput' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'classifyAndCreateJobOffer' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferClassificationFromTextForm_ClassifyAndCreateJobOfferMutation,
    UseJobOfferClassificationFromTextForm_ClassifyAndCreateJobOfferMutationVariables
  >;
export const UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'UseJobOfferVideoBriefSoloRecordingDrawer_jobOffer',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferQuery,
  UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferQueryVariables
>;
export const UseJobOfferMinimalForm_JobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useJobOfferMinimalForm_jobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferMinimalForm_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormProfileStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormAcademicBackgroundStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormLocationStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormSuccessFeeStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormVideoBriefStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferMinimalForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormProfileStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormAcademicBackgroundStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormLocationStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormSalaryStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormSuccessFeeStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormVideoBriefStep_JobOffer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferMinimalForm_JobOfferQuery,
  UseJobOfferMinimalForm_JobOfferQueryVariables
>;
export const JobOfferMinimalForm_UpdateJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'jobOfferMinimalForm_updateJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateJobOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateJobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferMinimalForm_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormProfileStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormAcademicBackgroundStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormLocationStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferMinimalFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormSuccessFeeStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobOfferMinimalFormVideoBriefStep_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefSoloRecordingClickableCard_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'VideoBriefExpertRecordingClickableCard_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferMinimalForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoBriefMeeting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormProfileStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormAcademicBackgroundStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormLocationStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormSalaryStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormSuccessFeeStep_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferMinimalFormVideoBriefStep_JobOffer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferMinimalForm_UpdateJobOfferMutation,
  JobOfferMinimalForm_UpdateJobOfferMutationVariables
>;
export const UseJobOfferMinimalFormHandleStepSubmit_CompleteJobOfferCreationFormDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value:
            'useJobOfferMinimalFormHandleStepSubmit_completeJobOfferCreationForm',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'CompleteJobOfferCreationFormInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'completeJobOfferCreationForm' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferMinimalFormHandleStepSubmit_CompleteJobOfferCreationFormMutation,
    UseJobOfferMinimalFormHandleStepSubmit_CompleteJobOfferCreationFormMutationVariables
  >;
export const UseJobOfferMinimalFormHandleStepSubmit_ExpressInterestInProfilesDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value:
            'useJobOfferMinimalFormHandleStepSubmit_expressInterestInProfiles',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ExpressInterestInProfilesInput' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'expressInterestInProfiles' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseJobOfferMinimalFormHandleStepSubmit_ExpressInterestInProfilesMutation,
    UseJobOfferMinimalFormHandleStepSubmit_ExpressInterestInProfilesMutationVariables
  >;
export const UseJobOfferUpdateForm_JobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useJobOfferUpdateForm_jobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferUpdateForm_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferWizardFormSalaryStep_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferUpdateForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferWizardForm_JobOffer' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferUpdateForm_JobOfferQuery,
  UseJobOfferUpdateForm_JobOfferQueryVariables
>;
export const UseJobOfferUpdateForm_UpdateJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useJobOfferUpdateForm_updateJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateJobOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateJobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferUpdateForm_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardFormSalaryStep_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferWizardForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferWizardFormSalaryStep_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferUpdateForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferWizardForm_JobOffer' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferUpdateForm_UpdateJobOfferMutation,
  UseJobOfferUpdateForm_UpdateJobOfferMutationVariables
>;
export const UseJobOfferNewsFeed_PostJobOfferNewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useJobOfferNewsFeed_postJobOfferNews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostJobOfferNewsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postJobOfferNews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'useJobOfferNewsFeed_News' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferNewsFeed_News' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'News' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useJobOfferNewsFeed_News' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'News' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferNewsFeed_News' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferNewsFeed_PostJobOfferNewsMutation,
  UseJobOfferNewsFeed_PostJobOfferNewsMutationVariables
>;
export const UseJobOfferNewsFeed_JobOfferNewsFeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useJobOfferNewsFeed_jobOfferNewsFeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetJobOfferNewsFeedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferNewsFeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'useJobOfferNewsFeed_News',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferNewsFeed_News' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'News' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useJobOfferNewsFeed_News' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'News' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferNewsFeed_News' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferNewsFeed_JobOfferNewsFeedQuery,
  UseJobOfferNewsFeed_JobOfferNewsFeedQueryVariables
>;
export const UseJobOfferUploadVideoBrief_UpdateJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useJobOfferUploadVideoBrief_updateJobOffer',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateJobOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateJobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferPreview_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseJobOfferUploadVideoBrief_UpdateJobOfferMutation,
  UseJobOfferUploadVideoBrief_UpdateJobOfferMutationVariables
>;
export const MyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'User' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RecruiterLayout_User' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'HiringManagerLayout_User',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainBusinessType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termsOfServiceVersion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'permissions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UserLead' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MyUserProvider_UserLead',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterLayout_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'paymentServiceProviderName',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contracts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringManagerLayout_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyUserProvider_UserLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyUserQuery, MyUserQueryVariables>;
export const MarkProfileAsOpenToWorkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markProfileAsOpenToWork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkProfileAsOpenToWorkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markProfileAsOpenToWork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkProfileAsOpenToWorkMutation,
  MarkProfileAsOpenToWorkMutationVariables
>;
export const MarkProfileAsNotOpenToWorkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markProfileAsNotOpenToWork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkProfileAsNotOpenToWorkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markProfileAsNotOpenToWork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkProfileAsNotOpenToWorkMutation,
  MarkProfileAsNotOpenToWorkMutationVariables
>;
export const UpdateProfileJobCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProfileJobCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfileJobCategoriesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileJobCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseProfileJobCategoriesAutoSubmitForm_Profile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileJobCategoriesAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileJobCategoriesMutation,
  UpdateProfileJobCategoriesMutationVariables
>;
export const UseProfileSkillsAutoSubmitForm_AddProfilePotentialSkillMutationDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value:
            'useProfileSkillsAutoSubmitForm_addProfilePotentialSkillMutation',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AddProfilePotentialSkillInput' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'addProfilePotentialSkill' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseProfileSkillsAutoSubmitForm_Profile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'UseProfileSkillsAutoSubmitForm_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseProfileSkillsAutoSubmitForm_AddProfilePotentialSkillMutationMutation,
    UseProfileSkillsAutoSubmitForm_AddProfilePotentialSkillMutationMutationVariables
  >;
export const UseUpdateProfileAutoSubmitForm_UpdateProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useUpdateProfileAutoSubmitForm_updateProfileMutation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseUpdateProfileAutoSubmitForm_Profile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseUpdateProfileAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentEmployer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateProfileAutoSubmitForm_UpdateProfileMutationMutation,
  UseUpdateProfileAutoSubmitForm_UpdateProfileMutationMutationVariables
>;
export const UseProfile_ProfileQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useProfile_profileQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileInfo_Profile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileEducation_Education' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Education' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'degreeTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileEducationsForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileEducation_Education' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiencesForm_Experience' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Experience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileExperiencesForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileExperiencesForm_Experience',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileHeader_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileRequirementsChecklist_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseUpdateProfileAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salaryClaimAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentEmployer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UpdateProfileCareerAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseUpdateProfileAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileIsOpenToWorkAutoSubmitForm_Profile',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileAcademicBackgroundsAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseProfileJobCategoriesAutoSubmitForm_Profile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseProfileSkillsAutoSubmitForm_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileInfo_Profile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileEducationsForm_Profile' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileExperiencesForm_Profile' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProfileHeader_Profile' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'ProfileRequirementsChecklist_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UpdateProfileCareerAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileAcademicBackgroundsAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileJobCategoriesAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseProfileSkillsAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseUpdateProfileAutoSubmitForm_Profile',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseProfile_ProfileQueryQuery,
  UseProfile_ProfileQueryQueryVariables
>;
export const UseRecruiterProfilesSearch_RecruiterJobApplicationsQueryDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'useRecruiterProfilesSearch_recruiterJobApplicationsQuery',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
              },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'JobApplicationsFilters' },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterJobApplications' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'offsetBasedPaginationParams',
                    },
                  },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'nodes' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'profile' },
                          selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'id' },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseRecruiterProfilesSearch_RecruiterJobApplicationsQueryQuery,
    UseRecruiterProfilesSearch_RecruiterJobApplicationsQueryQueryVariables
  >;
export const RecruiterProfilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterProfiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RecruiterProfilesFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterProfiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterProfilesQuery,
  RecruiterProfilesQueryVariables
>;
export const RecruiterJobApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterJobApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobApplicationsFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterJobApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'UseRecruiterJobApplicationsTable_JobApplication',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationStatus_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterJobApplicationCard_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruiterJobApplicationStatus_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseRecruiterJobApplicationsTable_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruiterJobApplicationCard_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruiterJobApplicationStatus_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterJobApplicationsQuery,
  RecruiterJobApplicationsQueryVariables
>;
export const RecruiterLayout_RecruiterRecruitmentTaskRecommendationsCountDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'recruiterLayout_recruiterRecruitmentTaskRecommendationsCount',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'RecruitmentTaskRecommendationsFilters',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'recruiterRecruitmentTaskRecommendationsCount',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
              ],
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterLayout_RecruiterRecruitmentTaskRecommendationsCountQuery,
    RecruiterLayout_RecruiterRecruitmentTaskRecommendationsCountQueryVariables
  >;
export const JobCategories_UseRecruiterSettingsSpecializationsTabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'jobCategories_useRecruiterSettingsSpecializationsTab',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobCategoriesFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobCategories_UseRecruiterSettingsSpecializationsTabQuery,
  JobCategories_UseRecruiterSettingsSpecializationsTabQueryVariables
>;
export const UpdateRecruiterNewJobCategoryAssociationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateRecruiterNewJobCategoryAssociations',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'recruiterJobCategoryAssociationInputs',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterJobCategoryAssociationInput',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateRecruiterNewJobCategoryAssociations',
            },
            arguments: [
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'recruiterJobCategoryAssociationInputs',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'recruiterJobCategoryAssociationInputs',
                  },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRecruiterNewJobCategoryAssociationsMutation,
  UpdateRecruiterNewJobCategoryAssociationsMutationVariables
>;
export const RecruiterSuperRecruiter_MyUserQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterSuperRecruiter_myUserQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobApplicationMetricsInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RecruiterJobApplicationMetricsInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecruiterSuperRecruiter_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterSuperRecruiter_RecruiterActivityMetrics',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruiterActivityMetrics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplicationMetrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobApplicationMetricsInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfJobApplications' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'interviewConversionRateInBasisPoints',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'hireConversionRateInBasisPoints',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterSuperRecruiter_UserActivityMetric',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserActivityMetric' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterActivityMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSuperRecruiter_RecruiterActivityMetrics',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSuperRecruiter_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSuperRecruiter_UserActivityMetric',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSuperRecruiter_MyUserQueryQuery,
  RecruiterSuperRecruiter_MyUserQueryQueryVariables
>;
export const UseRecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTasksOfRecruiterForMyEmployerDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'useRecruitmentTasksOfRecruiterForMyEmployer_recruitmentTasksOfRecruiterForMyEmployer',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'RecruitmentTasksOfRecruiterForMyEmployerInput',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'recruitmentTasksOfRecruiterForMyEmployer',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplicationStatus' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationLastUpdateAlert_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerHeader_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationLastUpdateAlert_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'linkedInProfileUrl' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'phoneNumber' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profilePictureUrl' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerHistorySection_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'message' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'body' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'creator' },
                          selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'firstName' },
                              },
                              {
                                kind: 'Field',
                                name: { kind: 'Name', value: 'lastName' },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'replacedJobApplication' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationProfileDrawerJobOfferSection_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AfterHiringJobOfferTerminationModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AfterHiringJobOfferTerminationModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobOfferTerminationModal_JobOffer',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseJobApplicationRejectionForm_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'email' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'JobApplicationRejectionModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmployerJobOfferTerminationModal_JobOffer',
                    },
                  },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'UseJobApplicationRejectionForm_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'UseAskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'StartApplicantRecruitmentProcessModal_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'AskForFirstInterviewWithoutTemplateModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobApplicationProfileDrawerFooter_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'HiringConfirmationModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationRejectionModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'StartApplicantRecruitmentProcessModal_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerRecruiterSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'phoneNumber' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'roles' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ChatContact_User' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerInfoSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'salaryClaimAmount' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerSkillsSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'validSkills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileDrawerEducationsSection_Profile' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'degreeTitle' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'school' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileExperience_Experience' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Experience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileExperiences_Experience' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Experience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: { kind: 'Name', value: 'ProfileExperience_Experience' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerExperiencesSection_Profile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileExperiences_Experience',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerJobApplicationProfileDrawer_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationProfileDrawerHeader_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'JobApplicationProfileDrawerHistorySection_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'JobApplicationProfileDrawerJobOfferSection_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerJobApplicationProfileDrawerFooter_JobApplication',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profile' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerRecruiterSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerInfoSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerSkillsSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerEducationsSection_Profile',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerExperiencesSection_Profile',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruitmentTask' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileDrawerRecruiterSection_RecruitmentTask',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobApplication' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'statuses' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                ],
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'StartApplicantRecruitmentProcessModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'JobApplicationRejectionModal_JobApplication',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'HiringConfirmationModal_JobApplication',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTaskWithApplicationsCard_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask',
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobApplications' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobApplicationProfileDrawer_JobApplication',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobApplicationLastUpdateAlert_JobApplication',
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'profile' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'firstName' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'lastName' },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'RecruitmentTaskWithApplicationsCard_RecruitmentTask',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseRecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTasksOfRecruiterForMyEmployerQuery,
    UseRecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTasksOfRecruiterForMyEmployerQueryVariables
  >;
export const PendingRecruiterRecruitmentTaskRecommendationsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'pendingRecruiterRecruitmentTaskRecommendationsCount',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RecruitmentTaskRecommendationsFilters',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'recruiterRecruitmentTaskRecommendationsCount',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PendingRecruiterRecruitmentTaskRecommendationsCountQuery,
  PendingRecruiterRecruitmentTaskRecommendationsCountQueryVariables
>;
export const RejectRecruitmentTaskRecommendationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'rejectRecruitmentTaskRecommendation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RejectRecruitementTaskRecommendationInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'rejectRecruitmentTaskRecommendation',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectRecruitmentTaskRecommendationMutation,
  RejectRecruitmentTaskRecommendationMutationVariables
>;
export const UseSchoolsOptions_SchoolsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useSchoolsOptions_schools' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetSchoolsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'schools' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UseSchoolsOptions_School' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseSchoolsOptions_School' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'School' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseSchoolsOptions_SchoolsQuery,
  UseSchoolsOptions_SchoolsQueryVariables
>;
export const CreateStripeCustomerCheckoutSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStripeCustomerCheckoutSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateStripeCustomerCheckoutSessionInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createStripeCustomerCheckoutSession',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStripeCustomerCheckoutSessionMutation,
  CreateStripeCustomerCheckoutSessionMutationVariables
>;
export const CreateStripeCustomerPortalSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStripeCustomerPortalSession' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStripeCustomerPortalSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStripeCustomerPortalSessionMutation,
  CreateStripeCustomerPortalSessionMutationVariables
>;
export const AgreeToTermsOfServiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'agreeToTermsOfService' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AgreeToTermsOfServiceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agreeToTermsOfService' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'termsOfServiceVersion' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AgreeToTermsOfServiceMutation,
  AgreeToTermsOfServiceMutationVariables
>;
export const UseAccountCreationForm_CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useAccountCreationForm_createUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'useAccountCreationForm_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useAccountCreationForm_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseAccountCreationForm_CreateUserMutation,
  UseAccountCreationForm_CreateUserMutationVariables
>;
export const ProfilePictureUploadUrlAndFileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'profilePictureUploadUrlAndFileName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ProfilePictureUploadUrlAndFileNameInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePictureUploadUrlAndFileName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awsS3Key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePictureUploadUrlAndFileNameQuery,
  ProfilePictureUploadUrlAndFileNameQueryVariables
>;
export const UpdateMyProfilePictureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMyProfilePicture' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMyProfilePictureInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyProfilePicture' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMyProfilePictureMutation,
  UpdateMyProfilePictureMutationVariables
>;
export const CreateMyEmployerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMyEmployer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMyEmployerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMyEmployer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMyEmployerMutation,
  CreateMyEmployerMutationVariables
>;
export const UseRecruiterProfilesPage_PaginatedRecruiterProfilesQueryDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'useRecruiterProfilesPage_paginatedRecruiterProfilesQuery',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecruiterProfilesFilters' },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'paginatedRecruiterProfiles' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'offsetBasedPaginationParams',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hasNextPage' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'totalCount' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'nodes' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value: 'UseRecruiterProfilesTableColumns_Profile',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'UseRecruiterProfilesTableColumns_Profile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Profile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'curriculumVitaeUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'linkedInProfileUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'openToWorkUntil' } },
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'lastJobApplicationRecommendationsComputation',
              },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    UseRecruiterProfilesPage_PaginatedRecruiterProfilesQueryQuery,
    UseRecruiterProfilesPage_PaginatedRecruiterProfilesQueryQueryVariables
  >;
export const RecruitmentTaskRecommendationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruitmentTaskRecommendation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTaskRecommendation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isTerminated' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferHeaderContainer_JobOffer',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestSuccessFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basisPoints' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isTerminated' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'replacedJobApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruitmentTaskRecommendationQuery,
  RecruitmentTaskRecommendationQueryVariables
>;
export const RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendationsDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendations',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'RecruitmentTaskRecommendationsFilters',
                },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'recruiterRecruitmentTaskRecommendations',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferPackageTableCell_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTaskRecommendation' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'priorityIndicator' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'logoUrl' },
                        },
                      ],
                    },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'latestSuccessFee' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'basisPoints' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'replacedJobApplication' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobOfferPackageTableCell_JobOffer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendationsQuery,
    RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendationsQueryVariables
  >;
export const TermsOfServiceAgreementBox_TermsOfServiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'TermsOfServiceAgreementBox_termsOfService',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TermsOfServiceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsOfService' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TermsOfServiceAgreementBox_TermsOfServiceQuery,
  TermsOfServiceAgreementBox_TermsOfServiceQueryVariables
>;
export const DeprecatedJobCategories_RecruiterRecruitmentTasksFilterModalDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'deprecatedJobCategories_RecruiterRecruitmentTasksFilterModal',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeprecatedJobCategoriesFilters' },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'deprecatedJobCategories' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DeprecatedJobCategories_RecruiterRecruitmentTasksFilterModalQuery,
    DeprecatedJobCategories_RecruiterRecruitmentTasksFilterModalQueryVariables
  >;
export const RecruiterRecruitmentTasksTable_RecruiterRecruitmentTasksDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value: 'recruiterRecruitmentTasksTable_recruiterRecruitmentTasks',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
              },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecruiterRecruitmentTasksFilters' },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterRecruitmentTasks' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'offsetBasedPaginationParams',
                    },
                  },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'totalCount' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hasNextPage' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'nodes' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value:
                              'RecruiterRecruitmentTasksTable_RecruitmentTask',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferPackageTableCell_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'minimumRemuneration' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumRemuneration' },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'maximumVariableRemuneration' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'JobOffer' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
            { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'RecruiterRecruitmentTasksTable_RecruitmentTask',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'RecruitmentTask' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'jobOffer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'priorityIndicator' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'isTerminated' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'latestSuccessFee' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'basisPoints' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'creator' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'FragmentSpread',
                          name: { kind: 'Name', value: 'ChatContact_User' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'employer' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'logoUrl' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'replacedJobApplication' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'JobOfferPackageTableCell_JobOffer',
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    RecruiterRecruitmentTasksTable_RecruiterRecruitmentTasksQuery,
    RecruiterRecruitmentTasksTable_RecruiterRecruitmentTasksQueryVariables
  >;
export const ActivateMyJobOfferPage_EmployerJobOfferLeadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'ActivateMyJobOfferPage_employerJobOfferLead',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferLeadId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employerJobOfferLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferLeadId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferLeadId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ActivateMyJobOfferPage_JobOfferLead',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivateMyJobOfferPage_JobOfferLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOfferLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoardUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateMyJobOfferPage_EmployerJobOfferLeadQuery,
  ActivateMyJobOfferPage_EmployerJobOfferLeadQueryVariables
>;
export const EmployerJobOfferDashboard_JobOfferQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employerJobOfferDashboard_jobOfferQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferDashboard_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferManagementPopover_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferTerminationModal_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flatFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferManagementPopover_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferTimeline_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'totalNumberOfAcceptedForFirstInterviewJobApplications',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfHiredJobApplications' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNewJobApplications' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckIfJobOfferBriefIsRecorded_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'CheckIfJobOfferBriefIsRecorded_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferRecruitersTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferRecruitmentTasksList_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferPreviewTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferPreview_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferSelectedProfilesTabContent_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferGoToProfileSearchButton_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferTabs_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferJobApplicationsTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferRecruitersTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferPreviewTabContent_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferSelectedProfilesTabContent_JobOffer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferPageProvider_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfJobApplications' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferDashboard_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferHeader_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferTimeline_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmployerJobOfferTabs_JobOffer' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferPageProvider_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferDashboard_JobOfferQueryQuery,
  EmployerJobOfferDashboard_JobOfferQueryQueryVariables
>;
export const UseBookVideoBriefMeeting_BookVideoBriefMeetingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useBookVideoBriefMeeting_bookVideoBriefMeetingMutation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BookVideoBriefMeetingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookVideoBriefMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseBookVideoBriefMeeting_VideoBriefMeeting',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseBookVideoBriefMeeting_VideoBriefMeeting',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoBriefMeeting' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoBriefMeeting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBookVideoBriefMeeting_BookVideoBriefMeetingMutationMutation,
  UseBookVideoBriefMeeting_BookVideoBriefMeetingMutationMutationVariables
>;
export const EmployerJobOfferJobApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employerJobOfferJobApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobApplicationsFilters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobApplicationsFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobApplicationsFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferJobApplicationsList_JobApplication',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileNameCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationLastUpdateAlert_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'GetJobApplicationLastUpdateAlert_JobApplicationStatus',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationProfileCareerCell_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseAskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'StartApplicantRecruitmentProcessModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithoutTemplateModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AskForFirstInterviewWithTemplateModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobApplicationRejectionForm_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRejectionModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseJobApplicationRejectionForm_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'AfterHiringJobOfferTerminationModal_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseAfterHiringJobOfferTerminationModal_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringConfirmationModal_JobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'AfterHiringJobOfferTerminationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'StartApplicantRecruitmentProcessModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationRejectionModal_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'HiringConfirmationModal_JobApplication',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterCell_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruiterDetailsModal_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'JobApplicationRecruiterCell_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsTable_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileNameCell_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationProfileCareerCell_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobApplicationRecruiterCell_User',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobApplicationRecruiterDetailsModal_User',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruiterCell_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsCards_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'EmployerJobOfferJobApplicationEditableStatusTag_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobApplicationLastUpdateAlert_JobApplication',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedInProfileUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerJobOfferJobApplicationsList_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferJobApplicationsTable_JobApplication',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'EmployerJobOfferJobApplicationsCards_JobApplication',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferJobApplicationsQuery,
  EmployerJobOfferJobApplicationsQueryVariables
>;
export const UseEmployerJobOfferRecruitmentTasksListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useEmployerJobOfferRecruitmentTasksList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferRecruitmentTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerJobOfferRecruitmentTasksTable_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerJobOfferRecruitmentTasksTable_JobApplication',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseEmployerJobOfferRecruitmentTasksTable_User',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseEmployerJobOfferRecruitmentTasksTable_JobApplication',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerJobOfferRecruitmentTasksListQuery,
  UseEmployerJobOfferRecruitmentTasksListQueryVariables
>;
export const EmployerJobOfferLikedAnonymizedProfilesTable_AnonymizedProfileDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'employerJobOfferLikedAnonymizedProfilesTable_anonymizedProfile',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'input' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GetProfileInput' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'anonymizedProfile' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'input' },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'SelectedProfileDrawer_AnonymizedProfile',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SelectedProfileDrawerHeader_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ChatContact_User' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'User' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
            },
            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerRecruiterSection_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'creator' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'phoneNumber' },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'roles' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  {
                    kind: 'FragmentSpread',
                    name: { kind: 'Name', value: 'ChatContact_User' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerInfoSection_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'salaryClaimAmount' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'salaryClaimCurrencyCode' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'approximateFirstJobStartDate' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'preferredRemoteWorkPolicy' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerSkillsSection_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'validSkills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ProfileExperience_AnonymizedExperience' },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileExperiences_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'ProfileExperience_AnonymizedExperience',
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerExperiencesSection_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ProfileExperiences_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'ProfileDrawerEducationsSection_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'degreeTitle' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'school' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                        {
                          kind: 'Field',
                          name: { kind: 'Name', value: 'name' },
                        },
                      ],
                    },
                  },
                  { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'SelectedProfileDrawer_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'SelectedProfileDrawerHeader_AnonymizedProfile',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'ProfileDrawerRecruiterSection_AnonymizedProfile',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'ProfileDrawerInfoSection_AnonymizedProfile',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'ProfileDrawerSkillsSection_AnonymizedProfile',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'ProfileDrawerExperiencesSection_AnonymizedProfile',
              },
            },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value: 'ProfileDrawerEducationsSection_AnonymizedProfile',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerJobOfferLikedAnonymizedProfilesTable_AnonymizedProfileQuery,
    EmployerJobOfferLikedAnonymizedProfilesTable_AnonymizedProfileQueryVariables
  >;
export const EmployerJobOffersPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employerJobOffersPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'User' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActivityMetric_User' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOffersPageQuery,
  EmployerJobOffersPageQueryVariables
>;
export const EmployerJobOfferLeads_EmployerPaginatedJobOfferLeadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'employerJobOfferLeads_employerPaginatedJobOfferLeads',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmployerJobOfferLeadsFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employerPaginatedJobOfferLeads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'EmployerJobOfferLeads_JobOfferLead',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferLeads_JobOfferLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOfferLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobBoardUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferLeads_EmployerPaginatedJobOfferLeadsQuery,
  EmployerJobOfferLeads_EmployerPaginatedJobOfferLeadsQueryVariables
>;
export const EmployerJobOffersTable_EmployerPaginatedJobOffersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'employerJobOffersTable_employerPaginatedJobOffers',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EmployerJobOffersFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employerPaginatedJobOffers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'EmployerJobOffersTableColumns_JobOffer',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOffersTableColumns_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNewJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOffersTable_EmployerPaginatedJobOffersQuery,
  EmployerJobOffersTable_EmployerPaginatedJobOffersQueryVariables
>;
export const EmployerProfileSearchPageUseAnonymizedProfileList_PaginatedAnonymizedProfilesDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageUseAnonymizedProfileList_paginatedAnonymizedProfiles',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AnonymizedProfilesFilters' },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'paginatedAnonymizedProfiles' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'offsetBasedPaginationParams',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'totalCount' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hasNextPage' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'nodes' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value:
                              'EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Skill' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'skills' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_Skill',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'EmployerProfileSearchPageAnonymizedProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerProfileSearchPageUseAnonymizedProfileList_PaginatedAnonymizedProfilesQuery,
    EmployerProfileSearchPageUseAnonymizedProfileList_PaginatedAnonymizedProfilesQueryVariables
  >;
export const EmployerProfileSearchPage_EmployerJobOffersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'EmployerProfileSearchPage_employerJobOffers',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employerJobOffers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferSelectionForm_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferSelectionForm_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseJobOfferSelectionForm_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseJobOfferSelectionForm_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newJobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerProfileSearchPage_EmployerJobOffersQuery,
  EmployerProfileSearchPage_EmployerJobOffersQueryVariables
>;
export const UseEmployerSettingsEmployerForm_UpdateEmployerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useEmployerSettingsEmployerForm_updateEmployer',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEmployerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEmployer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseEmployerSettingsEmployerForm_Employer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseEmployerSettingsEmployerForm_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerSettingsEmployerForm_UpdateEmployerMutation,
  UseEmployerSettingsEmployerForm_UpdateEmployerMutationVariables
>;
export const UseEmployerSettingsHiringManagerForm_UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useEmployerSettingsHiringManagerForm_updateUser',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UseEmployerSettingsHiringManagerForm_User',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerSettingsHiringManagerForm_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerSettingsHiringManagerForm_UpdateUserMutation,
  UseEmployerSettingsHiringManagerForm_UpdateUserMutationVariables
>;
export const EmployerSettingsPage_MyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employerSettingsPage_myUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'User' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'EmployerSettingsHiringManagerTab_User',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'EmployerSettingsEmployerTab_Employer',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'UseEmployerSettingsHiringManagerForm_User',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UseEmployerSettingsEmployerForm_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerSettingsHiringManagerTab_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseEmployerSettingsHiringManagerForm_User',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerSettingsEmployerTab_Employer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UseEmployerSettingsEmployerForm_Employer',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerSettingsPage_MyUserQuery,
  EmployerSettingsPage_MyUserQueryVariables
>;
export const UseEmployerInvitationForm_CreateInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useEmployerInvitationForm_createInvitation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInvitationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerInvitationForm_CreateInvitationMutation,
  UseEmployerInvitationForm_CreateInvitationMutationVariables
>;
export const UseEmployerInvitationsTable_InvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useEmployerInvitationsTable_invitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InvitationsFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerInvitationsTable_Invitation',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerInvitationsTable_Invitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Invitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerInvitationsTable_InvitationsQuery,
  UseEmployerInvitationsTable_InvitationsQueryVariables
>;
export const UseEmployerTeamTable_MyEmployerMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'useEmployerTeamTable_myEmployerMembers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myEmployerMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerTeamMember_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerTeamMember_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseEmployerTeamTable_MyEmployerMembersQuery,
  UseEmployerTeamTable_MyEmployerMembersQueryVariables
>;
export const UseAcceptInvitation_AcceptInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'useAcceptInvitation_acceptInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AcceptInvitationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'useAcceptInvitation_User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'useAcceptInvitation_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseAcceptInvitation_AcceptInvitationMutation,
  UseAcceptInvitation_AcceptInvitationMutationVariables
>;
export const UseMarkInvitationAsOpened_MarkInvitationAsOpenedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'useMarkInvitationAsOpened_markInvitationAsOpened',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkInvitationAsOpenedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markInvitationAsOpened' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'useMarkInvitationAsOpened_PublicInvitation',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'useMarkInvitationAsOpened_PublicInvitation',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseMarkInvitationAsOpened_MarkInvitationAsOpenedMutation,
  UseMarkInvitationAsOpened_MarkInvitationAsOpenedMutationVariables
>;
export const UsePublicInvitation_PublicInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'usePublicInvitation_publicInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PublicInvitationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'usePublicInvitation_PublicInvitation',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'usePublicInvitation_PublicInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsePublicInvitation_PublicInvitationQuery,
  UsePublicInvitation_PublicInvitationQueryVariables
>;
export const EmployerProfileSearchPageAnonymizedProfileList_PaginatedAnonymizedProfilesDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: {
          kind: 'Name',
          value:
            'EmployerProfileSearchPageAnonymizedProfileList_paginatedAnonymizedProfiles',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'filters' },
            },
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AnonymizedProfilesFilters' },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
            },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OffsetBasedPaginationParams' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'paginatedAnonymizedProfiles' },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'filters' },
                  value: {
                    kind: 'Variable',
                    name: { kind: 'Name', value: 'filters' },
                  },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'offsetBasedPaginationParams' },
                  value: {
                    kind: 'Variable',
                    name: {
                      kind: 'Name',
                      value: 'offsetBasedPaginationParams',
                    },
                  },
                },
              ],
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'totalCount' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'hasNextPage' },
                  },
                  {
                    kind: 'Field',
                    name: { kind: 'Name', value: 'nodes' },
                    selectionSet: {
                      kind: 'SelectionSet',
                      selections: [
                        {
                          kind: 'FragmentSpread',
                          name: {
                            kind: 'Name',
                            value:
                              'OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedExperience' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'employer' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'Education' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'school' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'profilePictureUrl' },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'urbanArea' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'experiences' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'educations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value:
                        'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'AnonymizedProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            {
              kind: 'FragmentSpread',
              name: {
                kind: 'Name',
                value:
                  'OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    EmployerProfileSearchPageAnonymizedProfileList_PaginatedAnonymizedProfilesQuery,
    EmployerProfileSearchPageAnonymizedProfileList_PaginatedAnonymizedProfilesQueryVariables
  >;
export const CreateMySearchFirmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMySearchFirm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSearchFirmInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSearchFirm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMySearchFirmMutation,
  CreateMySearchFirmMutationVariables
>;
export const RecruiterRecruitmentTaskDetailsByJobOfferIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'recruiterRecruitmentTaskDetailsByJobOfferId',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'recruiterRecruitmentTaskByJobOfferId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobOfferHeader_JobOffer',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'JobOfferHeaderContainer_JobOffer',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterRecruitmentTaskTabs_RecruitmentTask',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskPageProvider_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskPageProvider_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterRecruitmentTaskPageProvider_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyMotivationalSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motivationalMessageToRecruiters' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyReplacementSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedInProfileUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curriculumVitaeUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodySummarySection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyVideoBriefSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'nonPoachingAgreementsDescription',
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyMotivationalSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyReplacementSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodySummarySection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'JobOfferBodyVideoBriefSection_JobOffer',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferBodyPerksSection_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_User' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityMetric_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContact_InternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ChatContact_InternalManager' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContact_User' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityMetric_User' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferContact_InternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskDetailsTabContent_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isTerminated' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestSuccessFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basisPoints' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferBody_JobOffer' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferContacts_JobOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskJobApplicationsTabContent_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedExperience' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterRecruitmentTaskRelevantProfileCard_Skill',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_Education',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Education' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanArea' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'experiences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'educations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruiterRecruitmentTaskRelevantProfileCard_Education',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HiringManagerLikesAnonymizedProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonymizedProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruitmentTaskRelevantProfileCardList_AnonymizedProfile',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_Employer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'likedAnonymizedProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruitmentTaskRelevantProfileCardList_Employer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruitmentTaskRelevantProfileCardList_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruitmentTaskRelevantProfileCardList_JobOffer',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskRelevantProfileCardList_RecruitmentTask',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeader_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferStatusTag_JobOffer' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferHeaderContainer_JobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replacedJobApplication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RecruiterRecruitmentTaskTabs_RecruitmentTask',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskPageProvider_RecruitmentTask',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskDetailsTabContent_RecruitmentTask',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'RecruitmentTaskJobApplicationsTabContent_RecruitmentTask',
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value:
                'RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterRecruitmentTaskDetailsByJobOfferIdQuery,
  RecruiterRecruitmentTaskDetailsByJobOfferIdQueryVariables
>;
export const RecruiterSettingsUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterSettingsUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSettingsGeneralTab_User',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSettingsSpecializationsTab_User',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsGeneralTab_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthorizedToUseMarketplace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsSpecializationsTab_User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterNewJobCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterActivitySectors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSettingsUserQuery,
  RecruiterSettingsUserQueryVariables
>;
